import React from 'react';
import { Pressable, StyleSheet, View, TouchableHighlight } from 'react-native';
import { SettingsItemDescription, SettingsItemTitle, } from 'design-system/SettingsItem';
import IconCheck from 'design-system/icons/IconCheck';
import { rem } from 'design-system/values';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
const string = {
    copied: 'Link copied',
};
const EditSpaceItem = ({ title, subTitle, leftIcon: LeftIcon, rightIcon: RightIcon, onPress, onPressRight, copied, }) => {
    const colors = useColors();
    const content = (<View style={styles.content}>
      {LeftIcon}
      <View style={styles.texts}>
        <SettingsItemTitle>{title}</SettingsItemTitle>
        <SettingsItemDescription numberOfLines={1} ellipsizeMode="tail">
          {subTitle}
        </SettingsItemDescription>
      </View>
      {RightIcon && !copied ? (<Pressable disabled={!onPressRight} style={styles.iconRight} onPress={onPressRight}>
          {RightIcon}
        </Pressable>) : null}
      {copied && (<View style={styles.copied}>
          <IconCheck color={colors.status.positive}/>
          <Text TextThin-14 color={colors.status.positive} numberOfLines={1}>
            {string.copied}
          </Text>
        </View>)}
    </View>);
    return (<View>
      {onPress ? (<TouchableHighlight onPress={onPress} underlayColor={colors.background.underlay}>
          {content}
        </TouchableHighlight>) : (content)}
    </View>);
};
const styles = StyleSheet.create({
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: rem(16),
        paddingRight: rem(10),
        paddingVertical: rem(16),
    },
    iconRight: {
        width: rem(44),
        height: rem(44),
        justifyContent: 'center',
        alignItems: 'center',
    },
    texts: {
        flexDirection: 'column',
        flex: 1,
        marginLeft: rem(16),
    },
    copied: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
export default EditSpaceItem;
