import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
export const AnalyticsCardHeader = ({ title, icon, amount, subTitle, }) => (<>
    <Text Text-16 style={styles.title}>
      {title}
    </Text>
    <View style={styles.subtitleContainer}>
      {icon}
      <View style={styles.amountContainer}>
        {amount}
        <Text TextThin-14 Gray style={styles.subTitleText}>
          {subTitle}
        </Text>
      </View>
    </View>
  </>);
const styles = StyleSheet.create({
    title: {
        marginBottom: rem(4),
    },
    subtitleContainer: {
        flexDirection: 'row',
        marginBottom: rem(12),
        alignItems: 'center',
    },
    amountContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginStart: rem(4),
    },
    subTitleText: {
        flexShrink: 1,
        textAlign: 'left',
        marginStart: rem(4),
    },
});
