import React, { useLayoutEffect } from 'react';
import useColors from 'hooks/useColors';
import StatusBar from 'design-system/StatusBar';
import { SpaceNamePage } from './containers';
const EditSpaceName = ({ route, navigation, }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Space name',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const space = route.params?.space;
    return (<>
      <StatusBar barStyle="dark-content" animated/>
      <SpaceNamePage space={space} onSubmitted={() => navigation.pop()} buttonTitle="Save"/>
    </>);
};
export default EditSpaceName;
