import React, { memo, useMemo, useState, useEffect } from 'react';
import { FlatList } from 'react-native';
import useColors from 'hooks/useColors';
import { overviewColorMap } from 'features/accounts/hooks/useBalances';
import { useAppSelector } from 'store/hooks';
import { selectAllCurrentAccounts, selectAllInvestmentAccounts, selectAllSavingAccounts, selectIsGBUser, } from 'reducers/selectors';
import { loanTypeReasonValue } from 'features/borrow/monevo/models';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { useAddAccountModal } from 'features/accounts/hooks/useAddAccountModal';
import i18n from '../../../../i18n';
import AccountSection from './AccountSection';
import AccountsSectionCollapsible from './AccountSectionCollapsible';
import AccountOverviewEmpty from './AccountOverviewEmpty';
import AccountOverviewFooter from './AccountOverviewFooter';
const keyExtractor = (item) => item.props.title;
const renderItem = ({ item: { FC, props }, }) => <FC {...props}/>;
const strings = {
    hidden: 'Hidden',
    closed: 'Closed',
};
const AccountsOverviewSection = ({ title, accounts, onRowPress, initialTitle, onOpenLogins, onOpenUpgrade, onRowLongPress, }) => {
    const navigation = useMainStackNavigation();
    const isGBUser = useAppSelector(selectIsGBUser);
    const addAccount = useAddAccountModal('Everyday');
    const [sectionShown, setSectionShown] = useState(title === initialTitle);
    useEffect(() => {
        if (!sectionShown) {
            const timeout = setTimeout(() => {
                setSectionShown(true);
            }, 400);
            return () => {
                clearTimeout(timeout);
            };
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const accountsSelector = useMemo(() => {
        switch (title) {
            case 'Everyday':
                return selectAllCurrentAccounts;
            case 'Savings':
                return selectAllSavingAccounts;
            case 'Investments':
                return selectAllInvestmentAccounts;
            default:
                return selectAllCurrentAccounts;
        }
    }, [title]);
    // @ts-ignore
    const sectionAccounts = useAppSelector(accountsSelector);
    const rowPress = React.useCallback((data, touchViewRef) => {
        onRowPress(data, touchViewRef);
    }, [onRowPress]);
    const colors = useColors();
    const sectionData = useMemo(() => {
        const data = [];
        if (!sectionShown)
            return data;
        switch (title) {
            case 'Everyday': {
                if (sectionAccounts.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Checking],
                            data: sectionAccounts,
                            title: i18n.t('current'),
                            onRowPress: rowPress,
                            onRowLongPress,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CREDITCARD.array.length || isGBUser) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            firstCTA: '+ Add a new card',
                            secondCTA: 'find the best',
                            onFirstCTAPress: addAccount,
                            onSecondCTAPress: () => {
                                navigation.navigate('UserInfo', {
                                    flow: 'monevo',
                                    type: 'CREDIT_CARD',
                                    loan_purpose: loanTypeReasonValue.CREDIT_CARD,
                                });
                            },
                            allowEmpty: true,
                            forceShowBottomContent: !accounts.CREDITCARD.array.length,
                            reverse: true,
                            balanceColor: colors.text[overviewColorMap.Debt],
                            data: accounts.CREDITCARD,
                            title: 'Credit cards',
                            onRowPress: rowPress,
                            onRowLongPress,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CHECKING_HIDDEN.array.length ||
                    accounts.CREDITCARD_HIDDEN.array.length) {
                    const hiddenAccs = accounts.CHECKING_HIDDEN.array.concat(accounts.CREDITCARD_HIDDEN.array);
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: `${strings.hidden} (${hiddenAccs.length})`,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: hiddenAccs,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CHECKING_CLOSED.array.length ||
                    accounts.CREDITCARD_CLOSED.array.length) {
                    const closedAccs = accounts.CHECKING_CLOSED.array.concat(accounts.CREDITCARD_CLOSED.array);
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: `${strings.closed} (${closedAccs.length})`,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: closedAccs,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                break;
            }
            // Cash is a combination of savings and checking (used if the user has investments)
            case 'Cash': {
                if (accounts.CHECKING.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Checking],
                            data: accounts.CHECKING,
                            title: i18n.t('current'),
                            onRowPress: rowPress,
                            onRowLongPress,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.SAVINGS.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Savings],
                            data: accounts.SAVINGS,
                            title: 'Savings',
                            onRowPress: rowPress,
                            onRowLongPress,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CHECKING_HIDDEN.array.length) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: strings.hidden,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.CHECKING_HIDDEN.array.concat(accounts.SAVINGS_HIDDEN.array),
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CHECKING_CLOSED.array.length) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: strings.closed,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.CHECKING_CLOSED.array.concat(accounts.SAVINGS_CLOSED.array),
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                break;
            }
            case 'Checking':
            case 'Current': {
                if (accounts.CHECKING.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Checking],
                            data: accounts.CHECKING,
                            title: i18n.t('current'),
                            onRowPress: rowPress,
                            onRowLongPress,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CHECKING_HIDDEN.array.length) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: strings.hidden,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.CHECKING_HIDDEN.array,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CHECKING_CLOSED.array.length) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: strings.closed,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.CHECKING_CLOSED.array,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                break;
            }
            case 'Debt': {
                if (accounts.LOAN.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Debt],
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.LOAN,
                            title: 'Loans',
                            reverse: true,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CREDITCARD.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Debt],
                            data: accounts.CREDITCARD,
                            title: 'Credit cards',
                            reverse: true,
                            onRowPress: rowPress,
                            onRowLongPress,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.LOAN_HIDDEN.array.length ||
                    accounts.CREDITCARD_HIDDEN.array.length) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: strings.hidden,
                            reverse: true,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.LOAN_HIDDEN.array.concat(accounts.CREDITCARD_HIDDEN.array),
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.LOAN_CLOSED.array.length ||
                    accounts.CREDITCARD_CLOSED.array.length) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: strings.closed,
                            reverse: true,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.LOAN_CLOSED.array.concat(accounts.CREDITCARD_CLOSED.array),
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                break;
            }
            case 'Savings': {
                if (sectionAccounts.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Savings],
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: sectionAccounts,
                            title: 'Savings',
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                const savingsHiddenArrLen = accounts.SAVINGS_HIDDEN.array.length;
                if (savingsHiddenArrLen) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: `${strings.hidden} (${savingsHiddenArrLen})`,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.SAVINGS_HIDDEN.array,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                const savingsClosedArrLen = accounts.SAVINGS_CLOSED.array.length;
                if (savingsClosedArrLen) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: `${strings.closed} (${savingsClosedArrLen})`,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.SAVINGS_CLOSED.array,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                break;
            }
            case 'Investments': {
                if (sectionAccounts.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Investments],
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: sectionAccounts,
                            title: 'Investments',
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                if (accounts.CRYPTO.array.length) {
                    data.push({
                        FC: AccountSection,
                        props: {
                            balanceColor: colors.text[overviewColorMap.Investments],
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.CRYPTO,
                            title: 'Crypto',
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                const investmentHiddenAccsLen = accounts.INVESTMENT_HIDDEN.array.length;
                if (investmentHiddenAccsLen) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: `${strings.hidden} (${investmentHiddenAccsLen})`,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.INVESTMENT_HIDDEN.array,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                const investmentClosedAccsLen = accounts.INVESTMENT_CLOSED.array.length;
                if (investmentClosedAccsLen) {
                    data.push({
                        FC: AccountsSectionCollapsible,
                        props: {
                            title: `${strings.closed} (${investmentClosedAccsLen})`,
                            onRowPress: rowPress,
                            onRowLongPress,
                            data: accounts.INVESTMENT_CLOSED.array,
                            onOpenLogins,
                            onOpenUpgrade,
                        },
                    });
                }
                break;
            }
            default:
                break;
        }
        return data;
    }, [
        accounts.CHECKING,
        accounts.CHECKING_CLOSED.array,
        accounts.CHECKING_HIDDEN.array,
        accounts.CREDITCARD,
        accounts.CREDITCARD_CLOSED.array,
        accounts.CREDITCARD_HIDDEN.array,
        accounts.CRYPTO,
        accounts.INVESTMENT_CLOSED.array,
        accounts.INVESTMENT_HIDDEN.array,
        accounts.LOAN,
        accounts.LOAN_CLOSED.array,
        accounts.LOAN_HIDDEN.array,
        accounts.SAVINGS,
        accounts.SAVINGS_CLOSED.array,
        accounts.SAVINGS_HIDDEN.array,
        addAccount,
        colors.text,
        isGBUser,
        onOpenLogins,
        onOpenUpgrade,
        onRowLongPress,
        rowPress,
        sectionAccounts,
        sectionShown,
        title,
    ]);
    return (<FlatList data={sectionData} scrollEnabled={false} initialNumToRender={3} renderItem={renderItem} keyExtractor={keyExtractor} ListFooterComponent={<AccountOverviewFooter title={title} dataLength={sectionData.length}/>} ListEmptyComponent={<AccountOverviewEmpty title={title}/>}/>);
};
export default memo(AccountsOverviewSection);
