import AsyncStorage from '@react-native-async-storage/async-storage';
import JailMonkey from 'utils/packages/jailMonkey';
import { all, call, delay, fork, put, putResolve, select, takeLatest, } from 'typed-redux-saga/macro';
import { fetchAnalyticsBudgetingData, getAdditionalUserInfo, getAdsAction, getAutomationRules, getBankConnections, getFeatureFlags, getFeed, getModalNotifications, getTransactions, getUser, getWebSignInQRCodeStatus, } from 'actions';
import { selectConnectionsWithReconsentRequired, selectFeatureFlag, selectUserAddtionalInfo, } from 'reducers/selectors';
import { getFxFees, getRequiredNationalIdentifiers } from 'actions/invest';
import { FETCH_APP_DATA, FETCH_DASHBOARD_MODAL_STACK, fetchAppDataComplete, } from 'actions/refresh';
import { setAdAction, setShouldSeeDeviceRootedWarningModal, } from 'actions/sessionSettings';
import { REGISTER_USER_SUCCESS, SET_CONNECTIONS_STATUS, UPDATE_KEYCHAIN_USER, } from 'actions/types';
import { getBudgets } from 'features/budgeting/actions';
import { updateTradingAccount } from 'features/invest/api/account';
import { selectHasCountryOfTaxResidence, selectHasFxFeesFeature, } from 'features/invest/reducers/selectors';
import { getActiveReferralProgram, getCreditReferrals, } from 'features/inviteFriend/actions';
import { selectShouldShowNetScore } from 'features/net-promoter-score/selectors';
import { getPotsInformation } from 'features/pots/actions';
import { getStripeCustomer } from 'features/premium/actions/stripe';
import { selectShouldShowLoginsUpgrade, selectShouldShowStripe, selectStripePaymentMethodMissing, } from 'features/premium/selectors';
import { checkPromotions, checkReverseTrial } from 'features/promotions/sagas';
import { getQuests } from 'features/quests/actions';
import { getRentReporting } from 'features/rent-reporting/actions';
import { selectHasCurrentRentReporting } from 'features/rent-reporting/selectors';
import { DEVICE_ROOTED_KEY } from 'features/security/components/RootedDeviceWarningModal';
import { fetchCommittedForCurrentPaydayRange, getSubscriptions, } from 'features/subscriptions/actions';
import { FETCH_SPACES } from 'features/spaces/constants';
import { navigationRef } from 'utils/navigationv6';
import { migratePassCodeSaga } from './migratePassCodeSaga';
import { updateKeychainUserSaga } from './updateKeychainUserSaga';
export const checkJailBroken = async () => {
    try {
        if (JailMonkey.isJailBroken()) {
            const val = await AsyncStorage.getItem(DEVICE_ROOTED_KEY);
            if (val !== 'true') {
                return true;
            }
        }
    }
    catch {
        //
    }
    return false;
};
export function* setupDashboardStackWorker() {
    const walkthroughStatus = yield* select((store) => store.feed.walkthrough?.status);
    if (walkthroughStatus === 'start') {
        // On first load the user shouldn't actually have any other modals, so we can show this without delay
        navigationRef.navigate({
            name: 'WalkthroughModal',
            params: undefined,
        });
    }
    const signInSpace = yield* select((store) => store.signInNavigation?.space);
    if (signInSpace) {
        navigationRef.navigate('JoinSpace', {
            ...signInSpace,
            selectOnJoin: true,
        });
    }
    const shouldShowStripe = yield* select(selectShouldShowStripe);
    const emmaProStatus = yield* select((store) => store.user.user.emmaProStatus);
    // We only show the 'PremiumSubscriptionPaymentFailed' based on this logic passing so doesn't need to be sync if false
    const makeStripeCustomerCallSync = emmaProStatus.autoRenewing && emmaProStatus.store === 'stripe';
    const showRentQuestion = yield* select((store) => selectFeatureFlag(store, 'in_app_rent_question').value);
    const isGBUser = yield* select(selectHasFxFeesFeature);
    const userAdditionalInfoBeforeRefresh = yield* select(selectUserAddtionalInfo);
    const makeRentReportingCall = isGBUser;
    // If the user has not yet answered the rent question we must do the call sync so we can show the screen
    // as part of the dashboard stack
    const makeRentReportingCallSync = makeRentReportingCall &&
        (userAdditionalInfoBeforeRefresh?.isRenting === null ||
            userAdditionalInfoBeforeRefresh?.isRenting === undefined);
    const [promotionsResult, isJailBroken, adsResult, notificationsResult, reverseTrialResult,] = yield* all([
        call(checkPromotions),
        call(checkJailBroken),
        putResolve(getAdsAction()),
        putResolve(getModalNotifications()),
        call(checkReverseTrial),
        shouldShowStripe
            ? (makeStripeCustomerCallSync ? putResolve : put)(getStripeCustomer())
            : undefined,
        (makeRentReportingCallSync ? putResolve : put)(getAdditionalUserInfo()),
        makeRentReportingCall // if makeRentReportingCall is true we have to getRentReporting in a sync way
            ? (makeRentReportingCallSync ? putResolve : put)(getRentReporting({ withRentTransactions: false, isActive: true }))
            : undefined,
        isGBUser ? putResolve(getCreditReferrals()) : undefined,
    ]);
    const shouldShowNetScore = yield* select(selectShouldShowNetScore); // Controls the NPS modal
    // todo how can we get full type safety here?
    // const ad = (adsResult as unknown as GetAdsSuccessAction)?.payload?.popUp;
    const modalNotifications = notificationsResult?.payload?.notifications;
    const routes = [];
    const reconsentRequired = yield* select(selectConnectionsWithReconsentRequired);
    const upgradeRequired = yield* select(selectShouldShowLoginsUpgrade);
    const userAdditionalInfo = yield* select(selectUserAddtionalInfo);
    const feed = yield* select((store) => store.feed.feed);
    const creditReferrals = yield* select((store) => store.referrals.creditReferrals);
    creditReferrals?.referrals.forEach((ref) => {
        if (ref.status === 'COMPLETED' && !ref.seenAt) {
            routes.push({
                name: 'CreditReferralDetails',
                params: {
                    id: ref.id,
                },
            });
        }
    });
    const yearlyReportData = feed.cards.yearlyReportCoverInfo;
    if (yearlyReportData) {
        routes.push({
            name: 'YearlyReportIntro',
            params: {
                data: { ...yearlyReportData, type: 'YEARLY' },
            },
        });
    }
    const hasCurrentRentReporting = yield* select(selectHasCurrentRentReporting);
    if (showRentQuestion &&
        !hasCurrentRentReporting &&
        userAdditionalInfo &&
        (userAdditionalInfo?.isRenting === null ||
            userAdditionalInfo?.isRenting === undefined)) {
        routes.push({
            name: 'RentQuestion',
            params: undefined,
        });
    }
    if (reconsentRequired) {
        routes.push({
            name: 'LoginsReconsent',
            params: {
                isModal: true,
            },
        });
    }
    if (shouldShowNetScore) {
        routes.push({
            name: 'NPS',
            params: undefined,
        });
    }
    if (modalNotifications?.length) {
        routes.push({
            name: 'ModalNotificationScreen',
            params: {
                id: modalNotifications[0].id,
            },
        });
    }
    if (upgradeRequired) {
        routes.push({ name: 'LoginsUpgradeIntro', params: undefined });
    }
    if (reverseTrialResult === 'start') {
        routes.push({ name: 'ReverseTrialIntroScreen', params: undefined });
    }
    else if (reverseTrialResult === 'ended') {
        routes.push({ name: 'ReverseTrialEndedScreen', params: undefined });
    }
    if (typeof promotionsResult === 'number') {
        routes.push({
            name: 'PromoDiscountScreen',
            params: { promotionId: promotionsResult, source: 'endpoint' },
        });
    }
    const pastDue = yield* select((store) => store.user.user.emmaProStatus.pastDue);
    const missingPaymentMethod = yield* select(selectStripePaymentMethodMissing);
    const canUseStripe = yield* select(selectShouldShowStripe);
    if (canUseStripe && (pastDue || missingPaymentMethod)) {
        routes.push({
            name: 'PremiumSubscriptionPaymentFailed',
            params: {},
        });
    }
    routes.forEach((routeObj) => {
        navigationRef.navigate(routeObj);
    });
    yield* delay(500);
    /**
     * Wait for navigation to end before triggering these modals
     * so that they done render on top of the above screens
     */
    if (isJailBroken) {
        yield* put(setShouldSeeDeviceRootedWarningModal(true));
    }
    const ad = adsResult?.payload?.popUp;
    if (ad?.id !== undefined) {
        yield* put(setAdAction(ad));
    }
    yield* put(fetchAppDataComplete());
}
function* fetchAppData() {
    // This needs to go without delay
    // TODO why no delay on this? surely this is less important that user/fl
    yield* put(getTransactions(null, null, null, undefined, undefined, null, 1, null, null, false));
    const feed = yield* select((store) => store.feed.feed);
    const promisesArray = [
        putResolve(getUser()),
        putResolve(getBankConnections()),
        putResolve(getActiveReferralProgram()),
        putResolve(getFeatureFlags()),
        ...(!feed.fetchedInOnboarding ? [putResolve(getFeed())] : []),
        delay(500),
    ];
    // We load in 'essential' data first
    yield* all(promisesArray);
    yield* call(setupDashboardStackWorker);
    yield* delay(500);
    const user = yield* select((store) => store.user.user);
    log(`[fetchAppData] User ID is ${user.id}`, false, 'cyan');
    yield* put({ type: FETCH_SPACES });
    if (user.guessedHomeCountry === 'GB') {
        yield* put(getPotsInformation());
    }
    yield* fork(migratePassCodeSaga, user);
    yield* put({ type: UPDATE_KEYCHAIN_USER });
    yield* put(fetchAnalyticsBudgetingData());
    yield* put(fetchCommittedForCurrentPaydayRange());
    yield* put(getSubscriptions());
    yield* put(getAutomationRules());
    yield* put(getQuests());
    const webSignInConfirmation = yield* select((store) => store.signInWeb.webSignInConfirmation);
    if (webSignInConfirmation?.clientId) {
        yield* put(getWebSignInQRCodeStatus({
            clientId: webSignInConfirmation?.clientId,
        }));
    }
    const isGBUser = yield* select(selectHasFxFeesFeature);
    if (isGBUser) {
        yield* put(getFxFees());
        const countryOfTaxResidence = yield* select(selectHasCountryOfTaxResidence);
        if (countryOfTaxResidence) {
            yield* put(getRequiredNationalIdentifiers());
        }
        updateTradingAccount(true, undefined, true);
    }
}
function* syncCompleteSaga(action) {
    if (action.payload) {
        return;
    }
    // Analytics and feed sagas will refresh themselves
    yield* put(getBudgets());
    yield* put(fetchCommittedForCurrentPaydayRange());
    yield* put(getSubscriptions());
}
function* refreshSaga() {
    yield* takeLatest(FETCH_APP_DATA, fetchAppData);
    yield* takeLatest(FETCH_DASHBOARD_MODAL_STACK, setupDashboardStackWorker);
    yield* takeLatest(SET_CONNECTIONS_STATUS, syncCompleteSaga);
    yield* takeLatest((action) => [REGISTER_USER_SUCCESS, UPDATE_KEYCHAIN_USER].includes(action.type), updateKeychainUserSaga);
}
export default refreshSaga;
