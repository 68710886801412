import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import React, { useRef } from 'react';
import { TextInput, View } from 'react-native';
import createStyleSheets from 'utils/createStyleSheets';
import { rem } from '../../../design-system';
const SpaceNameBody = ({ defaultValue, onChange, onSubmit, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const textInputRef = useRef(null);
    useAutoFocusInput(textInputRef);
    return (<View style={styles.inputContainer}>
      <TextInput ref={textInputRef} style={styles.input} keyboardType="default" defaultValue={defaultValue} placeholder="Enter name" placeholderTextColor={colors.text.placeholder} onChangeText={onChange} returnKeyType="done" onSubmitEditing={(it) => onSubmit(it.nativeEvent.text)} underlineColorAndroid="transparent" autoCorrect={false} allowFontScaling={false} selectionColor={colors.text.selection}/>
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    inputContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: rem(16),
        fontSize: rem(32),
    },
    input: {
        color: colors.text.primary,
        fontSize: rem(32),
        minWidth: rem(44),
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Montserrat-Light',
    },
}));
export default SpaceNameBody;
