import { CommonActions, StackActions, createNavigationContainerRef, } from '@react-navigation/native';
export const goBackWithKey = (navigation, keyBack) => {
    if (keyBack) {
        const { routes } = navigation.getState();
        if (routes?.length) {
            const routeIndex = routes.findIndex((route) => route.key === keyBack);
            if (routeIndex < 0) {
                return false;
            }
            const previousRoute = routes[routeIndex - 1];
            if (previousRoute === undefined) {
                // @ts-ignore
                navigation.navigate(routes[routeIndex].name, routes[routeIndex].params);
                return true;
            }
            // @ts-ignore
            navigation.navigate(previousRoute.name, previousRoute.params);
            return true;
        }
    }
    return false;
};
export const navigationRef = createNavigationContainerRef();
export function navigate(screenName, params) {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(StackActions.push(screenName, params));
    }
}
export function popToTop(onSuccess) {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(StackActions.popToTop());
        onSuccess();
    }
}
export const goBack = () => {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(CommonActions.goBack());
    }
};
