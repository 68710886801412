import React, { useMemo } from 'react';
import { Alert } from 'react-native';
import emoji from 'node-emoji';
import { Indicator } from 'design-system/Indicator';
import { Avatar } from 'design-system/avatar';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { selectUserId } from 'reducers/selectors';
import EditSpaceItem from '../components/EditSpaceItem';
import { useDeleteSpaceMember } from '../hooks';
import { Delete } from '../img';
const SpaceMemberItem = ({ spaceId, ownerId, member, disabled, hasAccounts, onRemoved, }) => {
    const { id, handle, firstName, lastName, imageUrl } = member;
    const myId = useAppSelector(selectUserId);
    const myName = useAppSelector((store) => store.user.user.firstName);
    const [removable, name, desc, letter] = useMemo(() => {
        if (id === myId)
            return [
                ownerId !== myId,
                'Me',
                ownerId === myId ? 'Owner' : 'Member',
                myName?.substring(0, 1)?.toUpperCase() ?? '?',
            ];
        return [
            ownerId === myId,
            firstName ?? lastName ?? handle,
            `@${handle}`,
            firstName?.substring(0, 1)?.toUpperCase() ?? '?',
        ];
    }, [id, myId, ownerId, myName, firstName, lastName, handle]);
    const [remove, removing] = useDeleteSpaceMember(spaceId, () => {
        onRemoved?.();
    });
    const onRemove = () => {
        if (hasAccounts) {
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), myId === member.id
                ? `You still have accounts in this space. Move your accounts to another space before removing yourself.`
                : `${member.firstName} still has accounts in this space. They'll need to move them to another space before you can remove them.`);
        }
        else if (!removing)
            remove(id);
    };
    const colors = useColors();
    const leftIcon = (<Avatar name={letter} size={44} color={colors.elements.brand} imageUrl={imageUrl}/>);
    return removable ? (<EditSpaceItem title={name} subTitle={desc} leftIcon={leftIcon} rightIcon={removing ? <Indicator dark/> : <Delete />} onPressRight={removing || disabled ? undefined : onRemove}/>) : (<EditSpaceItem title={name} subTitle={desc} leftIcon={leftIcon}/>);
};
export default SpaceMemberItem;
