import { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { differenceInCalendarDays, isBefore as isBeforeFn, format, startOfWeek, endOfWeek, isAfter, parseISO, } from 'date-fns';
const compareByDays = (dd1, dd2) => differenceInCalendarDays(parseISO(dd1), parseISO(dd2));
const isBefore = (dd1, dd2) => isBeforeFn(parseISO(dd1), parseISO(dd2));
export const useDateRange = (initialDates = [], 
// 0 = Sunday, 1 = Monday
firstDay = 1) => {
    const [range, setRange] = useState(initialDates);
    const onAddDayToRange = useCallback((date) => {
        let newRange;
        if (isEmpty(range) || range.length === 2 || isBefore(date, range[0])) {
            newRange = [date];
        }
        else {
            newRange = [...range, date].sort(compareByDays);
        }
        setRange(newRange);
        return newRange;
    }, [range]);
    const selectEntireWeek = useCallback((date) => {
        const dateObj = parseISO(date);
        const startDay = format(startOfWeek(dateObj, { weekStartsOn: firstDay }), 'yyyy-MM-dd');
        const endDay = format(endOfWeek(dateObj, { weekStartsOn: firstDay }), 'yyyy-MM-dd');
        setRange([startDay, endDay]);
        return [startDay, endDay];
    }, [firstDay]);
    const resetRange = useCallback(() => {
        setRange([]);
    }, []);
    const checkInRange = useCallback((date) => {
        if (!date || !range || range.length === 0) {
            return false;
        }
        if (range.length === 1) {
            return range[0] === date;
        }
        return !isBefore(date, range[0]) && !isAfter(date, range[1]);
    }, [range]);
    const checkIsDayLastOrFirst = useCallback((date) => date === range[0] || date === range[1], [range]);
    const checkIsStartDay = useCallback((date) => date === range[0], [range]);
    const checkIsEndDay = useCallback((date) => date === range[1] || (range.length === 1 && date === range[0]), [range]);
    return {
        onAddDayToRange,
        range,
        checkInRange,
        checkIsDayLastOrFirst,
        checkIsStartDay,
        checkIsEndDay,
        resetRange,
        selectEntireWeek,
    };
};
