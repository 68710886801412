import { createSelector } from 'reselect';
import { differenceInMonths, differenceInDays, parseISO } from 'date-fns';
import { selectRating } from 'reducers/selectors';
export const selectShouldShowNetScore = createSelector([
    (store) => store.user.user.latestFeedbackDate,
    (store) => store.user.user.latestFeedbackRating,
    (store) => store.user.user.firstConnectionAt,
    selectRating,
], (latestFeedbackDate, latestFeedbackRating, firstConnectionAt, sessionCount) => !!firstConnectionAt &&
    (!latestFeedbackDate ||
        differenceInMonths(new Date(), parseISO(latestFeedbackDate)) >= 6 ||
        (latestFeedbackRating === null &&
            differenceInMonths(new Date(), parseISO(latestFeedbackDate)) >= 3)) &&
    sessionCount > 10 &&
    differenceInDays(new Date(), parseISO(firstConnectionAt)) >= 7);
