import { groupBy, isEqual, sumBy } from 'lodash';
import lookup from 'country-code-lookup';
import { createSelector } from 'reselect';
import { endOfMonth, format, getISOWeek, getYear, startOfMonth, } from 'date-fns';
import getAccounts from 'utils/api/getAccounts';
import getListAccounts from 'utils/api/getListAccounts';
import getListActiveAccounts from 'utils/api/getListActiveAccounts';
import { extractManualAccounts } from 'utils/api/getManualAccounts';
import { selectLatestUpcomingPayment } from 'features/pots/reducers/selectors';
import { isUnlocked } from 'utils';
import { selectIsDefaultSpace, selectSelectedSpace, selectSelectedSpaceData, selectSelectedSpaceInfo, } from 'features/spaces/reducers/selectors';
import { useAppSelector } from 'store/hooks';
import { getBalance } from 'utils/api/getBalance';
import getCommittedTransactions from 'utils/api/getCommittedTransactions';
import sortAccounts from 'utils/api/sortAccounts';
import checkFeatureFlag from 'utils/featureFlags';
import { sortByName } from 'utils/sort';
import { createNonMemoizedSelector } from 'store/selectors';
import { validAccountNumber, validSortCode } from 'utils/validation';
import { getActivitiesSectionedFlashlist, } from 'utils/formatting/activities';
import { emptyArray, isWeb } from '../constants';
export const selectBalances = (state) => state.accounts.balances;
export const selectTags = (state) => state.transactions.tags;
export const selectDefaultCurrency = (store) => store.user.user.currency || 'GBP';
export const selectDefaultZeroAmount = createSelector(selectDefaultCurrency, (defaultCurrency) => ({
    amount: 0,
    currency: defaultCurrency,
}));
export const selectBeneficiaries = (state) => state.beneficiaries.beneficiaries;
export const selectFilteredBeneficiaries = createSelector([selectBeneficiaries, (_, props) => props], (beneficiaries, searchFilter) => {
    if (searchFilter === '') {
        return beneficiaries;
    }
    const regExp = new RegExp(searchFilter, 'i');
    return beneficiaries.filter((payee) => payee.name.match(regExp));
}, {
    devModeChecks: {
        identityFunctionCheck: 'never',
    },
});
export const selectUser = (state) => state.user.user;
export const selectUserId = (state) => state.user.user.id;
export const selectUserAddtionalInfo = (state) => state.user.userAdditionalInfo;
export const selectIsGBUser = (state) => state.user.user.guessedHomeCountry === 'GB';
export const selectUserPhotoUrl = (state) => state.user.user?.userImage?.url;
export const selectStatusMessage = (state) => state.user.statusMessage;
export const selectIsUserFetching = (state) => state.user.isFetching;
export const selectHasStartedKYC = (store) => Boolean(store.user.user.kycStartedAt);
export const selectCurrentGroup = (state) => state.groups.currentGroup;
export const selectIsFetchingCurrentGroup = (state) => state.groups.isFetchingGroup;
export const selectTransactionGroup = (state, id) => state.groups.transactionGroupCache[id];
export const useTransactionGroupCache = (id) => useAppSelector((state) => selectTransactionGroup(state, id));
export const selectPrimaryAddress = createSelector((state) => state.addressReducer.userAddresses, (userAddresses) => userAddresses.find((item) => item.isPrimary));
/* Bill sharing */
export const selectBillSharingInfoCacheForTransaction = (state, id) => state.sharedBills.cache[id];
/* Feed */
export const selectFeed = (state) => state.feed.feed;
/* Utils */
export const selectFeatureFlags = (state) => state.utils.featureFlags;
export const selectScrambled = (state) => state.utils.scrambled;
export const selectModalNotifications = (state) => state.utils.modalNotifications;
export const selectConnections = createSelector([(store) => store.user.connections], (connections) => (connections || []).map((connection) => ({
    ...connection,
    accounts: connection.accounts.map((account) => {
        if (account.bankConnectionId === connection.id) {
            return {
                ...account,
                isConnectionClosed: connection.isClosed,
                provider: connection.bankInfo.provider,
                iconUrl: account.iconUrl || connection.bankInfo.iconUrl,
                iconBase64: connection.bankInfo.iconBase64,
                statusMessage: connection.statusMessage,
                needsReauth: connection.needsReauth,
                needsReconsent: connection.needsReconsent,
                deactivatedOverQuota: connection.deactivatedOverQuota,
                lastSuccessfulSync: connection.lastSuccessfulSync,
                supportsVrp: connection.bankInfo.supportsVrp,
                bankName: connection.bankInfo.name,
            };
        }
        return account;
    }),
})));
export const selectActiveConnections = createSelector([selectConnections], (connections) => connections.filter((conn) => !conn.deactivatedOverQuota));
export const selectActiveConnectionsWithoutManual = createSelector([selectConnections], (connections) => connections.filter((conn) => conn.bankInfo.provider !== 'MANUAL' && !conn.deactivatedOverQuota));
const emptyBalance = {
    value: 0,
    currency: 'GBP', // ISO code of the currency
};
const STATUSES_TO_HIDE_EMMA_WALLET = ['CLOSED', 'FROZEN'];
export const selectEmmaWalletAsAccount = createSelector([
    selectIsGBUser,
    (store) => store.pots.account,
    (store) => store.pots.walletBalance,
], (isGbUser, potsAccount, availableBalance) => isGbUser &&
    (!potsAccount?.status ||
        !STATUSES_TO_HIDE_EMMA_WALLET.includes(potsAccount.status))
    ? {
        id: 'EMMA_WALLET',
        isEmmaWallet: true,
        name: 'Emma wallet',
        accountStatus: potsAccount?.status || 'NOT_STARTED',
        balance: potsAccount?.status === 'ACTIVE' && availableBalance
            ? availableBalance
            : emptyBalance,
    }
    : undefined);
export const selectValidGBAccounts = createSelector([
    selectActiveConnectionsWithoutManual,
    (_, props) => props,
], (connections, props) => {
    const { filterBusiness, allowNonPISP, validateAccountNumberAndSortCode } = props || {};
    const accounts = [];
    for (let index = 0; index < connections.length; index++) {
        if (allowNonPISP || connections[index].bankInfo?.pispBankId) {
            for (let x = 0; x < connections[index].accounts.length; x++) {
                const { sortCode, accountNumber, isClosed, isBusiness } = connections[index].accounts[x];
                if (sortCode &&
                    sortCode.length > 0 &&
                    accountNumber &&
                    accountNumber.length > 0 &&
                    // If we're sending to it MUST have a valid account number and sort code
                    (!validateAccountNumberAndSortCode ||
                        (validAccountNumber(accountNumber) && validSortCode(sortCode))) &&
                    !isClosed &&
                    (filterBusiness ? !isBusiness : true)) {
                    const account = {
                        ...connections[index].accounts[x],
                        sortCode,
                        accountNumber,
                        bankId: connections[index].bankId,
                        bankName: connections[index].bankInfo.name,
                    };
                    accounts.push(account);
                }
            }
        }
    }
    return accounts;
});
const EMPTY_ACCOUNTS = [];
// Selects any accounts that don't belong 'to me' in the space
const selectOtherSpaceAccounts = createSelector([selectSelectedSpaceInfo, selectUser], (spaceInfo, user) => {
    if (spaceInfo === undefined)
        return EMPTY_ACCOUNTS;
    return (spaceInfo?.accounts?.filter((acc) => user.id !== acc.userId) ||
        EMPTY_ACCOUNTS);
});
const selectMyAccountsInSpace = createSelector([selectSelectedSpaceInfo, selectUser], (spaceInfo, user) => {
    if (spaceInfo === undefined)
        return EMPTY_ACCOUNTS;
    return (spaceInfo?.accounts?.filter((acc) => user.id === acc.userId) ||
        EMPTY_ACCOUNTS);
});
/* User */
export const selectPasscode = (state) => state.user.passCode;
export const selectLastSession = (state) => state.user.lastSession;
/**
 * Prefer the use of use useRequiredTierCallback for callbacks
 */
export const selectIsUnlockedForSpace = createSelector([selectSelectedSpaceData, selectUser, (state, tier) => tier], (spaceData, user, requiredTier) => requiredTier === undefined ||
    isUnlocked(user, requiredTier) ||
    spaceData?.isPremium === true);
export const selectAllMyAccountsFromConnections = createSelector([selectConnections, selectMyAccountsInSpace], (connections, spaceMyAccounts) => connections
    .map((connection) => connection.accounts.concat(
// If the user has these accounts in a premium space, then we can show them as unlocked premium accounts
connection.ultimatePreviewAccounts?.map((acc) => spaceMyAccounts.find((accInSpace) => accInSpace.id === acc.id)
    ? acc
    : {
        ...acc,
        availableBalance: 0,
        postedBalance: 0,
        ultimatePreviewAccount: true,
    }) || [], connection.proPreviewAccounts?.map((acc) => spaceMyAccounts.find((accInSpace) => accInSpace.id === acc.id)
    ? acc
    : {
        ...acc,
        availableBalance: 0,
        postedBalance: 0,
        proPreviewAccount: true,
    }) || []))
    .flat(1));
export const selectListAccounts = createSelector([
    selectAllMyAccountsFromConnections,
    selectSelectedSpace,
    selectOtherSpaceAccounts,
], (allAccountsFromConnections, selectedSpaceId, otherSpaceAccounts) => getListAccounts([
    { accounts: allAccountsFromConnections },
    { accounts: otherSpaceAccounts },
], selectedSpaceId).filter((acc) => !acc.deactivatedOverQuota &&
    !acc.proPreviewAccount &&
    !acc.ultimatePreviewAccount));
export const selectAccounts = createSelector([
    selectAllMyAccountsFromConnections,
    selectSelectedSpace,
    selectOtherSpaceAccounts,
], (allAccountsFromConnections, selectedSpaceId, otherSpaceAccounts) => getAccounts(getListAccounts([
    { accounts: allAccountsFromConnections },
    { accounts: otherSpaceAccounts },
], selectedSpaceId)));
// The new app separates debt from checking,
// we can get rid of this and modify the api.js function when we ditch old design
export const selectAccountsSeparatedDebt = createSelector([selectAccounts], (accounts) => ({
    ...accounts,
    CHECKING_CLOSED: {
        ...accounts.CHECKING_CLOSED,
        array: accounts.CHECKING_CLOSED.array.filter((b) => b.type !== 'CREDITCARD' && b.type !== 'LOAN'),
        positiveAccounts: accounts.CHECKING_CLOSED.positiveAccounts.filter((b) => b.type !== 'CREDITCARD' && b.type !== 'LOAN'),
        negativeAccounts: accounts.CHECKING_CLOSED.negativeAccounts.filter((b) => b.type !== 'CREDITCARD' && b.type !== 'LOAN'),
    },
    CHECKING_HIDDEN: {
        ...accounts.CHECKING_HIDDEN,
        array: accounts.CHECKING_HIDDEN.array.filter((b) => b.type !== 'CREDITCARD' && b.type !== 'LOAN'),
        positiveAccounts: accounts.CHECKING_CLOSED.positiveAccounts.filter((b) => b.type !== 'CREDITCARD' && b.type !== 'LOAN'),
        negativeAccounts: accounts.CHECKING_CLOSED.negativeAccounts.filter((b) => b.type !== 'CREDITCARD' && b.type !== 'LOAN'),
    },
}));
export const selectIsFitForDebtConsolidation = createSelector([selectAccounts], (accounts) => {
    if (accounts.CREDITCARD.array?.length) {
        let creditCardsWithBalance = 0;
        for (let i = 0; i < accounts.CREDITCARD.array.length; i++) {
            if ((accounts.CREDITCARD.array[i].creditCardBalance || 0) < 0 &&
                !accounts.CREDITCARD.array[i].isClosed) {
                creditCardsWithBalance += 1;
            }
            if (creditCardsWithBalance >= 2) {
                break;
            }
        }
        return creditCardsWithBalance >= 2;
    }
    return false;
});
const getCurrency = (data) => {
    const firstItem = data[0];
    if (firstItem) {
        if ('currency' in firstItem) {
            return firstItem.currency;
        }
        if (firstItem.balance?.currency) {
            return firstItem.balance.currency;
        }
    }
    return undefined;
};
export const selectAllSavingAccounts = createSelector((store) => store.pots.pots, selectAccounts, selectIsDefaultSpace, selectDefaultCurrency, (pots, accounts, isDefaultSpace, defaultCurrency) => {
    const array = [
        ...(accounts?.SAVINGS?.array || []),
        ...(isDefaultSpace ? pots : []),
    ];
    const positiveAccounts = [
        ...(accounts?.SAVINGS?.positiveAccounts || []),
        ...(isDefaultSpace ? pots : []),
    ];
    const total = accounts.SAVINGS.total +
        (isDefaultSpace
            ? pots.reduce((prev, curr) => prev + curr.balance.value, 0)
            : 0);
    const positiveTotal = accounts.SAVINGS.positiveTotal +
        (isDefaultSpace
            ? pots.reduce((prev, curr) => prev + curr.balance.value, 0)
            : 0);
    return {
        total,
        positiveTotal,
        array,
        positiveAccounts,
        hasPots: !!pots.length,
        currency: getCurrency(array) || defaultCurrency,
    };
});
export const selectAllInvestmentAccounts = createSelector((store) => store.invest.positionsCache, (store) => store.invest.account, selectAccounts, selectIsDefaultSpace, selectDefaultCurrency, (positionsCache, investAccount, accounts, isDefaultSpace, defaultCurrency) => {
    if (investAccount?.status === 'ACTIVE' && isDefaultSpace) {
        const symbols = Object.keys(positionsCache);
        const positions = [];
        symbols.forEach((symbol) => {
            const position = positionsCache[symbol];
            // the following condition will always return true since we are looping over keys of positionsCache
            if (position) {
                positions.push(position);
            }
        });
        const totalPositions = (() => ({
            value: positions.reduce((prev, curr) => {
                if (curr) {
                    return prev + curr.amount.value;
                }
                return prev;
            }, 0),
            currency: 'GBP',
        }))();
        const array = (() => {
            if (positions.length) {
                return [
                    ...accounts.INVESTMENT.array,
                    {
                        id: 'EMMA_INVEST',
                        name: 'Emma Invest',
                        balance: totalPositions,
                        isEmmaInvestmentAccount: true,
                    },
                ];
            }
            return accounts.INVESTMENT.array;
        })();
        const { total } = accounts.INVESTMENT;
        return {
            array,
            total: total + totalPositions.value,
            currency: getCurrency(array) || defaultCurrency,
        };
    }
    return {
        ...accounts.INVESTMENT,
        currency: getCurrency(accounts.INVESTMENT.array) || defaultCurrency,
    };
});
export const selectAllInvestmentWithCryptoAccounts = createSelector((store) => store.invest.positionsCache, (store) => store.invest.account, selectAccounts, selectIsDefaultSpace, selectDefaultCurrency, (positionsCache, investAccount, accounts, isDefaultSpace, defaultCurrency) => {
    if (investAccount?.status === 'ACTIVE' && isDefaultSpace) {
        const symbols = Object.keys(positionsCache);
        const positions = [];
        symbols.forEach((symbol) => {
            const position = positionsCache[symbol];
            // the following condition will always return true since we are looping over keys of positionsCache
            if (position) {
                positions.push(position);
            }
        });
        const totalPositions = (() => ({
            value: positions.reduce((prev, curr) => {
                if (curr) {
                    return prev + curr.amount.value;
                }
                return prev;
            }, 0),
            currency: 'GBP',
        }))();
        const array = (() => {
            if (positions.length) {
                return [
                    ...accounts.ALL_INVESTMENT.array,
                    {
                        id: 'EMMA_INVEST',
                        name: 'Emma Invest',
                        balance: totalPositions,
                        isEmmaInvestmentAccount: true,
                    },
                ];
            }
            return accounts.ALL_INVESTMENT.array;
        })();
        const positiveAccounts = (() => {
            if (positions.length) {
                return [
                    ...accounts.ALL_INVESTMENT.positiveAccounts,
                    {
                        id: 'EMMA_INVEST',
                        name: 'Emma Invest',
                        balance: totalPositions,
                        isEmmaInvestmentAccount: true,
                    },
                ];
            }
            return accounts.ALL_INVESTMENT.positiveAccounts;
        })();
        const { total, positiveTotal } = accounts.ALL_INVESTMENT;
        return {
            array,
            positiveAccounts,
            positiveTotal: positiveTotal + totalPositions.value,
            total: total + totalPositions.value,
            currency: getCurrency(array) || defaultCurrency,
        };
    }
    return {
        ...accounts.ALL_INVESTMENT,
        currency: getCurrency(accounts.ALL_INVESTMENT.array) || defaultCurrency,
    };
});
export const selectTotalDebtAccounts = createSelector(selectAccounts, (accounts) => sortAccounts([
    ...accounts['CHECKING'].negativeAccounts,
    ...accounts['CREDITCARD'].negativeAccounts,
    ...accounts['SAVINGS'].negativeAccounts,
    ...accounts['ALL_INVESTMENT'].negativeAccounts,
    ...accounts['PENSION'].negativeAccounts,
    ...accounts['LOAN'].negativeAccounts,
    ...accounts['REAL_ESTATE'].negativeAccounts,
    ...accounts['VEHICLE'].negativeAccounts,
    ...accounts['OTHER'].negativeAccounts,
], true));
export const selectTotalDebtBalance = createSelector(selectAccounts, selectDefaultCurrency, (accounts, defaultCurrency) => ({
    amount: accounts['CREDITCARD'].negativeTotal +
        accounts['LOAN'].negativeTotal +
        accounts['CHECKING'].negativeTotal +
        accounts['SAVINGS'].negativeTotal +
        accounts['ALL_INVESTMENT'].negativeTotal +
        accounts['PENSION'].negativeTotal +
        accounts['REAL_ESTATE'].negativeTotal +
        accounts['VEHICLE'].negativeTotal +
        accounts['OTHER'].negativeTotal,
    currency: getCurrency(accounts['CREDITCARD'].array) || defaultCurrency,
}));
// both personal and business, not emma wallet, not manual
// need to include all accounts from all spaces
// right now for notifications only
export const selectAllCurrentAndCreditCardAccounts = createSelector([selectConnections, selectDefaultCurrency], (connections, defaultCurrency) => {
    const array = connections
        .flatMap((connection) => connection.accounts)
        .filter((account) => !account.deactivatedOverQuota &&
        (account.type === 'CHECKING' || account.type === 'CREDITCARD') &&
        account.provider !== 'MANUAL');
    return {
        array,
        currency: getCurrency(array) || defaultCurrency,
    };
});
export const selectAllCurrentAccounts = createSelector(selectAccountsSeparatedDebt, selectIsDefaultSpace, selectEmmaWalletAsAccount, selectDefaultCurrency, (accounts, isDefaultSpace, emmaWalletAsAccount, defaultCurrency) => {
    if (!isDefaultSpace || !emmaWalletAsAccount) {
        return {
            ...accounts.CHECKING,
            currency: getCurrency(accounts.CHECKING.array) || defaultCurrency,
        };
    }
    const array = [...(accounts?.CHECKING?.array || []), emmaWalletAsAccount];
    const positiveAccounts = [
        ...(accounts?.CHECKING?.positiveAccounts || []),
        emmaWalletAsAccount,
    ];
    const total = accounts.CHECKING.total +
        (emmaWalletAsAccount.accountStatus === 'ACTIVE'
            ? emmaWalletAsAccount.balance?.value || 0
            : 0);
    const positiveTotal = accounts.CHECKING.positiveTotal +
        (emmaWalletAsAccount.accountStatus === 'ACTIVE'
            ? emmaWalletAsAccount.balance?.value || 0
            : 0);
    return {
        array,
        positiveAccounts,
        total,
        positiveTotal,
        currency: getCurrency(array) || defaultCurrency,
    };
});
export const selectTotalAssetBalance = createSelector(selectAccounts, selectAllSavingAccounts, selectAllCurrentAccounts, selectAllInvestmentWithCryptoAccounts, (accounts, savingsAccounts, checkingAccounts, investmentAccounts) => ({
    amount: checkingAccounts.positiveTotal +
        accounts['PENSION'].positiveTotal +
        accounts['VEHICLE'].positiveTotal +
        accounts['OTHER'].positiveTotal +
        savingsAccounts.positiveTotal +
        investmentAccounts.positiveTotal +
        accounts['REAL_ESTATE'].positiveTotal +
        accounts['LOAN'].positiveTotal +
        accounts['CREDITCARD'].positiveTotal,
    currency: savingsAccounts.currency,
}));
export const selectTotalAssetsAccounts = createSelector(selectAccounts, selectAllCurrentAccounts, selectAllInvestmentWithCryptoAccounts, selectAllSavingAccounts, (accounts, currentAccounts, investmentAccounts, savingsAccounts) => sortAccounts([
    ...currentAccounts.positiveAccounts,
    ...savingsAccounts.positiveAccounts,
    ...investmentAccounts.positiveAccounts,
    ...accounts['PENSION'].positiveAccounts,
    ...accounts['REAL_ESTATE'].positiveAccounts,
    ...accounts['VEHICLE'].positiveAccounts,
    ...accounts['OTHER'].positiveAccounts,
    ...accounts['LOAN'].positiveAccounts,
    ...accounts['CREDITCARD'].positiveAccounts,
]));
export const selectConnectionsWithReconsentRequired = createSelector([selectConnections], (connections) => (connections?.filter((connection) => connection.needsReconsent) || [])
    .length);
export const selectConnectionsWithConsentExpiresAt = createSelector([selectConnections], (connections) => connections?.filter((connection) => connection.consentExpiresAt !== undefined &&
    connection.consentExpiresAt !== null) || emptyArray);
export const selectIsSyncingManual = (store) => store.user.isSyncingManual;
export const selectListAccountsFromConnections = createSelector([selectAllMyAccountsFromConnections], (allAccounts) => getListAccounts([{ accounts: allAccounts }]));
const getActionRequired = ({ isClosed, isDeleted, needsReauth, needsReconsent, directDebitCancelled, deactivatedOverQuota, }) => {
    if (isDeleted) {
        return 'DELETED';
    }
    if (directDebitCancelled) {
        return 'DIRECT_DEBIT_CANCELLED';
    }
    if (deactivatedOverQuota) {
        return 'DEACTIVATED_OVER_QUOTA';
    }
    if (needsReauth) {
        return 'NEEDS_REAUTH';
    }
    if (needsReconsent) {
        return 'NEEDS_RECONSENT';
    }
    if (isClosed) {
        return 'CLOSED';
    }
    return undefined;
};
export const selectAutosaveStatusObj = createSelector((state) => state.pots.savingsPlan, selectListAccountsFromConnections, (savingsPlan, accounts) => {
    if (savingsPlan) {
        const savingsPlanAccount = accounts.find((account) => account.id === savingsPlan.bankInfo?.accountId);
        if (savingsPlanAccount) {
            return {
                account: savingsPlanAccount,
                isPaused: savingsPlan.isPaused,
                isPending: savingsPlan.mandateStatus === 'PENDING',
                actionRequired: getActionRequired({
                    isDeleted: savingsPlan.bankInfo.isDeleted,
                    needsReauth: savingsPlanAccount.needsReauth,
                    needsReconsent: savingsPlanAccount.needsReconsent,
                    deactivatedOverQuota: savingsPlanAccount.deactivatedOverQuota,
                    directDebitCancelled: savingsPlan.mandateStatus === 'INACTIVE',
                    isClosed: savingsPlanAccount.isRemoved ||
                        savingsPlanAccount.isConnectionClosed,
                }),
            };
        }
        return {
            account: undefined,
            isPaused: savingsPlan.isPaused,
            isPending: savingsPlan.mandateStatus === 'PENDING',
            actionRequired: getActionRequired({
                isDeleted: savingsPlan.bankInfo.isDeleted,
                directDebitCancelled: savingsPlan.mandateStatus === 'INACTIVE',
            }),
        };
    }
    return undefined;
}, {
    memoizeOptions: {
        resultEqualityCheck: (a, b) => isEqual(a, b),
    },
});
export const selectAccountById = createSelector([selectListAccounts, (_, id) => id], (accounts, id) => id !== undefined ? accounts.find((account) => account.id === id) : undefined);
export const selectAccountByAccountNumber = createSelector([
    selectListAccountsFromConnections,
    (_, accountNumber) => accountNumber,
], (accounts, accountNumber) => accounts.find((account) => account.accountNumber === accountNumber), {
    memoizeOptions: {
        resultEqualityCheck: (a, b) => isEqual(a, b),
    },
});
export const selectActiveAccounts = createSelector([selectListAccounts, selectSelectedSpace], (accounts, selectedSpaceId) => getListActiveAccounts(accounts, selectedSpaceId));
export const selectActiveManualAccounts = createSelector([selectConnections, selectSelectedSpace, selectOtherSpaceAccounts], (connections, selectedSpaceId, otherSpaceAccounts) => extractManualAccounts([...connections, { accounts: otherSpaceAccounts }], false, selectedSpaceId));
export const selectClosedManualAccounts = createSelector([selectConnections, selectSelectedSpace, selectOtherSpaceAccounts], (connections, selectedSpaceId, otherSpaceAccounts) => extractManualAccounts([...connections, { accounts: otherSpaceAccounts }], true, selectedSpaceId));
export const selectOrderedCategories = createSelector([(store) => store.user.categories], (categories) => sortByName(categories));
export const selectOrderedCustomCategories = createSelector([(store) => store.user.customCategories], (categories) => sortByName(categories));
export const selectAllCategories = createSelector([
    (store) => store.user.categories,
    (store) => store.user.customCategories,
], (categories, customCategories) => {
    if (categories === null || customCategories === null) {
        if (categories === null && customCategories === null) {
            return [];
        }
        if (categories === null) {
            return customCategories;
        }
        if (customCategories === null) {
            return categories;
        }
    }
    return sortByName([...categories, ...customCategories]);
});
export const selectMerchants = createSelector([(store) => store.transactions.merchants], (merchants) => Object.entries(merchants).map(([key, value]) => ({
    ...value,
    id: Number(key),
})));
export const selectCommittedTransactions = createSelector((store) => store.subscriptions.committed?.subscriptions ||
    emptyArray, (committed) => getCommittedTransactions(committed));
export const selectCurrentPaydayRange = createSelector([selectUser, selectSelectedSpaceData], (user, spaceData) => {
    if (spaceData && !spaceData.isDefault) {
        return spaceData.currentPaydayRange || undefined;
    }
    if (user?.currentPaydayRange) {
        return user.currentPaydayRange;
    }
    return undefined;
});
export const selectHasSetPaydayRange = createSelector([selectCurrentPaydayRange], (currentPaydayRange) => currentPaydayRange !== undefined);
// A user may not have set a custom pay period BUT has set a total budget
export const selectHasSetBudget = createSelector([selectFeed], (feed) => Boolean(feed.cards.thisMonth?.budget));
export const selectCurrentPaydayRangeWithFallback = createSelector([selectCurrentPaydayRange], (currentPaydayRange) => {
    if (currentPaydayRange) {
        return currentPaydayRange;
    }
    return {
        from: startOfMonth(new Date()).toISOString(),
        to: endOfMonth(new Date()).toISOString(),
    };
}, {
    devModeChecks: {
        identityFunctionCheck: 'never',
    },
});
export const selectIsLoggedIn = (store) => store.user.isLoggedIn || isWeb;
export const selectPasscodeVisible = (store) => store.user.isPasscodeOn;
export const selectBiometricsEnabled = (store) => store.user.isTouchActive;
export const selectIsResettingPasscode = (store) => store.user.isResettingPasscode;
export const selectResetPasscodeNextStep = (store) => store.user.resetPasscodeNextStep;
export const selectColorSchemePreference = (store) => store.utils.colorScheme;
export const selectCurrentScreen = (store) => store.utils.currentScreen;
export const selectPreviousScreen = (store) => store.utils.previousScreen;
export const selectEnergyResults = (store) => store.switching.energyResults;
/** Quiz */
export const selectQuiz = (store) => store.quiz.quiz;
export const selectDidOnboardNetworth = (store) => store.deviceSettings.didOnboardNetworth;
export const selectRating = (store) => store.user.rating;
export const selectLastReviewSessionCount = (store) => store.deviceSettings?.lastReviewSessionCount;
/**
 * Balance history selectors start
 */
const buildAverageArray = (arrayItems) => {
    if (!arrayItems) {
        return [];
    }
    const averageItems = Object.keys(arrayItems).map((key) => {
        const item = arrayItems[key];
        const displayStart = format(new Date(item[item.length - 1].timestamp), // timestamp is in format 2010/1/1, not ISO
        'do MMM yyyy');
        const displayEnd = format(new Date(item[0].timestamp), 'do MMM yyyy');
        let averageBalance = sumBy(item, 'balance') / item.length;
        averageBalance = parseFloat(averageBalance.toFixed(2));
        return {
            timestamp: item[0].timestamp,
            balance: averageBalance,
            currency: item[0].currency,
            label: `${displayStart} - ${displayEnd}`, // eg: '01 Jan 2020 -  7 Jan 2020'
        };
    });
    return averageItems.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
};
export const formatToPeriods = (balanceHistory) => {
    const all = (() => {
        const balanceHistoryDup = [...balanceHistory].reverse();
        const firstIndexOfNonZeroBalance = balanceHistoryDup.findIndex((balanceHistory) => balanceHistory.balance !== 0);
        if (firstIndexOfNonZeroBalance >= 1460) {
            return undefined;
        }
        const groupedDaysByWeek = groupBy(balanceHistory, (item) => {
            const date = new Date(item.timestamp);
            return `${getISOWeek(date)}${getYear(date)}`;
        });
        const averageWeeks = buildAverageArray(groupedDaysByWeek);
        return averageWeeks;
    })();
    const updatedBalanceHistories = balanceHistory
        .slice(0, 365)
        .reverse()
        .map((balanceHistory) => ({
        ...balanceHistory,
        label: format(new Date(balanceHistory.timestamp), 'do MMM yyyy'),
    }));
    const year = updatedBalanceHistories;
    const sixmonths = year.slice(365 - 180);
    const threemonths = sixmonths.slice(180 - 90);
    const month = threemonths.slice(90 - 30);
    const week = month.slice(30 - 7);
    const lastUpdated = week.slice(7 - 1);
    return {
        day: [],
        lastUpdated,
        week,
        month,
        threemonths,
        sixmonths,
        year,
        all: all ?? year,
    };
};
const formattedEmptyPeriods = formatToPeriods([]);
export const selectSavingsBalance = createSelector([
    (_state, returnSavingsData) => returnSavingsData,
    (state) => state.balanceHistory.pots,
    (state) => state.balanceHistory.savings,
    (state) => state.pots.account?.status === 'ACTIVE',
    selectIsDefaultSpace,
], (returnSavingsData, potsBalanceHistory, savingsBalanceHistory, isPotsAccountActive, isInDefaultSpace) => {
    if (!returnSavingsData || !savingsBalanceHistory.length) {
        return formattedEmptyPeriods;
    }
    let balanceHistory = [];
    if (isInDefaultSpace &&
        isPotsAccountActive &&
        potsBalanceHistory.length &&
        potsBalanceHistory.length === savingsBalanceHistory.length) {
        if (potsBalanceHistory[0].timestamp.substring(0, 10) !==
            savingsBalanceHistory[0].timestamp.substring(0, 10)) {
            return formattedEmptyPeriods;
        }
        for (let i = 0; i < potsBalanceHistory.length; i++) {
            balanceHistory.push({
                currency: 'GBP',
                timestamp: potsBalanceHistory[i].timestamp,
                balance: potsBalanceHistory[i].balance + savingsBalanceHistory[i].balance,
            });
        }
    }
    else if (!isPotsAccountActive || !isInDefaultSpace) {
        balanceHistory = savingsBalanceHistory;
    }
    return formatToPeriods(balanceHistory);
});
export const selectDebtBalanceHistory = createSelector((state) => state.balanceHistory.debt, (debtBalanceHistory) => formatToPeriods(debtBalanceHistory || []));
export const selectNetWorthBalance = createSelector([
    (state) => state.balanceHistory.pots,
    (state) => state.balanceHistory.networth,
    (state) => state.balanceHistory.portfolio,
    (state) => state.balanceHistory.emmaWallet,
    (state) => state.pots.account?.status === 'ACTIVE',
    (state) => state.invest.account?.status === 'ACTIVE',
    selectIsDefaultSpace,
], (potsBalanceHistory, networthBalanceHistory, portfolioBalanceHistory, emmaWalletBalanceHistory, isPotsAccountActive, isInvestmentAccountActive, isInDefaultSpace) => {
    if (!networthBalanceHistory.length) {
        return formattedEmptyPeriods;
    }
    let balanceHistory = [];
    if (!isInDefaultSpace) {
        balanceHistory = networthBalanceHistory;
    }
    else if (!isPotsAccountActive && !isInvestmentAccountActive) {
        /**
         * Case where pots and invest both are not active,
         * we sent the networth history data only.
         */
        balanceHistory = networthBalanceHistory;
    }
    else if (isPotsAccountActive && isInvestmentAccountActive) {
        /**
         * Case where pots and invest both are active,
         * we sent the networth history data plus pots and portfolio data
         * of the same timestamp.
         */
        if (potsBalanceHistory.length === networthBalanceHistory.length &&
            portfolioBalanceHistory.length === networthBalanceHistory.length &&
            portfolioBalanceHistory.length === emmaWalletBalanceHistory?.length) {
            if (!(potsBalanceHistory[0].timestamp.substring(0, 10) ===
                networthBalanceHistory[0].timestamp.substring(0, 10) &&
                portfolioBalanceHistory[0].timestamp.substring(0, 10) ===
                    networthBalanceHistory[0].timestamp.substring(0, 10) &&
                networthBalanceHistory[0].timestamp.substring(0, 10) ===
                    emmaWalletBalanceHistory[0].timestamp.substring(0, 10))) {
                return formattedEmptyPeriods;
            }
            for (let i = 0; i < networthBalanceHistory.length; i++) {
                balanceHistory.push({
                    currency: 'GBP',
                    timestamp: networthBalanceHistory[i].timestamp,
                    balance: potsBalanceHistory[i].balance +
                        networthBalanceHistory[i].balance +
                        portfolioBalanceHistory[i].balance +
                        emmaWalletBalanceHistory[i].balance,
                });
            }
        }
    }
    else if (isPotsAccountActive &&
        potsBalanceHistory.length === networthBalanceHistory.length &&
        potsBalanceHistory.length === emmaWalletBalanceHistory?.length) {
        if (!(potsBalanceHistory[0].timestamp.substring(0, 10) ===
            networthBalanceHistory[0].timestamp.substring(0, 10) &&
            networthBalanceHistory[0].timestamp.substring(0, 10) ===
                emmaWalletBalanceHistory[0].timestamp.substring(0, 10))) {
            return formattedEmptyPeriods;
        }
        /**
         * Case where pots account is active,
         * we sent the sum of networth balance history and pots balance history data.
         */
        for (let i = 0; i < networthBalanceHistory.length; i++) {
            balanceHistory.push({
                currency: 'GBP',
                timestamp: networthBalanceHistory[i].timestamp,
                balance: potsBalanceHistory[i].balance +
                    networthBalanceHistory[i].balance +
                    emmaWalletBalanceHistory[i].balance,
            });
        }
    }
    else if (isInvestmentAccountActive &&
        portfolioBalanceHistory.length === networthBalanceHistory.length &&
        portfolioBalanceHistory.length === emmaWalletBalanceHistory?.length) {
        if (!(portfolioBalanceHistory[0].timestamp.substring(0, 10) ===
            networthBalanceHistory[0].timestamp.substring(0, 10) &&
            networthBalanceHistory[0].timestamp.substring(0, 10) ===
                emmaWalletBalanceHistory[0].timestamp.substring(0, 10))) {
            return formattedEmptyPeriods;
        }
        /**
         * Case where invest account is active,
         * we sent the sum of networth balance history and portfolio balance history data.
         */
        for (let i = 0; i < networthBalanceHistory.length; i++) {
            balanceHistory.push({
                currency: 'GBP',
                timestamp: networthBalanceHistory[i].timestamp,
                balance: networthBalanceHistory[i].balance +
                    portfolioBalanceHistory[i].balance +
                    emmaWalletBalanceHistory[i].balance,
            });
        }
    }
    return formatToPeriods(balanceHistory);
});
export const selectAssetsBalance = createSelector([
    (state) => state.balanceHistory.pots,
    (state) => state.balanceHistory.assets,
    (state) => state.balanceHistory.portfolio,
    (state) => state.balanceHistory.emmaWallet,
    (state) => state.pots.account?.status === 'ACTIVE',
    (state) => state.invest.account?.status === 'ACTIVE',
    selectIsDefaultSpace,
], (potsBalanceHistory, networthBalanceHistory, portfolioBalanceHistory, emmaWalletBalanceHistory, isPotsAccountActive, isInvestmentAccountActive, isInDefaultSpace) => {
    if (!networthBalanceHistory?.length) {
        return formattedEmptyPeriods;
    }
    let balanceHistory = [];
    if (!isInDefaultSpace) {
        balanceHistory = networthBalanceHistory;
    }
    else if (!isPotsAccountActive && !isInvestmentAccountActive) {
        /**
         * Case where pots and invest both are not active,
         * we sent the networth history data only.
         */
        balanceHistory = networthBalanceHistory;
    }
    else if (isPotsAccountActive && isInvestmentAccountActive) {
        /**
         * Case where pots and invest both are active,
         * we sent the networth history data plus pots and portfolio data
         * of the same timestamp.
         */
        if (potsBalanceHistory.length === networthBalanceHistory.length &&
            portfolioBalanceHistory.length === networthBalanceHistory.length &&
            portfolioBalanceHistory.length === emmaWalletBalanceHistory?.length) {
            if (!(potsBalanceHistory[0].timestamp.substring(0, 10) ===
                networthBalanceHistory[0].timestamp.substring(0, 10) &&
                portfolioBalanceHistory[0].timestamp.substring(0, 10) ===
                    networthBalanceHistory[0].timestamp.substring(0, 10) &&
                networthBalanceHistory[0].timestamp.substring(0, 10) ===
                    emmaWalletBalanceHistory[0].timestamp.substring(0, 10))) {
                return formattedEmptyPeriods;
            }
            for (let i = 0; i < networthBalanceHistory.length; i++) {
                balanceHistory.push({
                    currency: 'GBP',
                    timestamp: networthBalanceHistory[i].timestamp,
                    balance: potsBalanceHistory[i].balance +
                        networthBalanceHistory[i].balance +
                        portfolioBalanceHistory[i].balance +
                        emmaWalletBalanceHistory[i].balance,
                });
            }
        }
    }
    else if (isPotsAccountActive &&
        potsBalanceHistory.length === networthBalanceHistory.length &&
        potsBalanceHistory.length === emmaWalletBalanceHistory?.length) {
        if (!(potsBalanceHistory[0].timestamp.substring(0, 10) ===
            networthBalanceHistory[0].timestamp.substring(0, 10) &&
            networthBalanceHistory[0].timestamp.substring(0, 10) ===
                emmaWalletBalanceHistory[0].timestamp.substring(0, 10))) {
            return formattedEmptyPeriods;
        }
        /**
         * Case where pots account is active,
         * we sent the sum of networth balance history and pots balance history data.
         */
        for (let i = 0; i < networthBalanceHistory.length; i++) {
            balanceHistory.push({
                currency: 'GBP',
                timestamp: networthBalanceHistory[i].timestamp,
                balance: potsBalanceHistory[i].balance +
                    networthBalanceHistory[i].balance +
                    emmaWalletBalanceHistory[i].balance,
            });
        }
    }
    else if (isInvestmentAccountActive &&
        portfolioBalanceHistory.length === networthBalanceHistory.length &&
        portfolioBalanceHistory.length === emmaWalletBalanceHistory?.length) {
        if (!(portfolioBalanceHistory[0].timestamp.substring(0, 10) ===
            networthBalanceHistory[0].timestamp.substring(0, 10) &&
            networthBalanceHistory[0].timestamp.substring(0, 10) ===
                emmaWalletBalanceHistory[0].timestamp.substring(0, 10))) {
            return formattedEmptyPeriods;
        }
        /**
         * Case where invest account is active,
         * we sent the sum of networth balance history and portfolio balance history data.
         */
        for (let i = 0; i < networthBalanceHistory.length; i++) {
            balanceHistory.push({
                currency: 'GBP',
                timestamp: networthBalanceHistory[i].timestamp,
                balance: networthBalanceHistory[i].balance +
                    portfolioBalanceHistory[i].balance +
                    emmaWalletBalanceHistory[i].balance,
            });
        }
    }
    return formatToPeriods(balanceHistory);
});
export const selectInvestmentBalance = createSelector([
    (_state, returnData) => returnData,
    (state) => state.balanceHistory.portfolio,
    (state) => state.balanceHistory.investment,
    (state) => state.invest.account?.status === 'ACTIVE',
    selectIsDefaultSpace,
], (returnData, portfolioBalanceHistory, investmentBalanceHistory, isInvestmentAccountActive, isInDefaultSpace) => {
    if (!returnData || !investmentBalanceHistory.length) {
        return formattedEmptyPeriods;
    }
    let balanceHistory = [];
    if (isInDefaultSpace &&
        isInvestmentAccountActive &&
        portfolioBalanceHistory.length &&
        portfolioBalanceHistory.length === investmentBalanceHistory.length) {
        if (portfolioBalanceHistory[0].timestamp.substring(0, 10) !==
            investmentBalanceHistory[0].timestamp.substring(0, 10)) {
            return formattedEmptyPeriods;
        }
        for (let i = 0; i < investmentBalanceHistory.length; i++) {
            balanceHistory.push({
                currency: 'GBP',
                timestamp: investmentBalanceHistory[i].timestamp,
                balance: investmentBalanceHistory[i].balance +
                    portfolioBalanceHistory[i].balance,
            });
        }
    }
    else if (!isInvestmentAccountActive || !isInDefaultSpace) {
        balanceHistory = investmentBalanceHistory;
    }
    return formatToPeriods(balanceHistory);
});
export const selectCheckingBalance = createSelector([
    (_state, returnData) => returnData,
    (state) => state.balanceHistory.checking,
    (state) => state.balanceHistory.emmaWallet,
    (state) => state.pots.account?.status === 'ACTIVE',
    selectIsDefaultSpace,
], (returnData, checkingBalanceHistory, emmaWalletBalanceHistory, isPotsAccountActive, isInDefaultSpace) => {
    if (!returnData || !checkingBalanceHistory?.length) {
        return formattedEmptyPeriods;
    }
    let balanceHistory = [];
    if (isInDefaultSpace &&
        isPotsAccountActive &&
        emmaWalletBalanceHistory?.length &&
        checkingBalanceHistory.length === emmaWalletBalanceHistory.length) {
        if (emmaWalletBalanceHistory[0].timestamp.substring(0, 10) !==
            checkingBalanceHistory[0].timestamp.substring(0, 10)) {
            return formattedEmptyPeriods;
        }
        for (let i = 0; i < checkingBalanceHistory.length; i++) {
            balanceHistory.push({
                currency: checkingBalanceHistory[i].currency,
                timestamp: checkingBalanceHistory[i].timestamp,
                balance: checkingBalanceHistory[i].balance +
                    emmaWalletBalanceHistory[i].balance,
            });
        }
    }
    else if (!isInDefaultSpace || !isPotsAccountActive) {
        balanceHistory = checkingBalanceHistory;
    }
    return formatToPeriods(balanceHistory);
});
/**
 * This selector is used to pull in emma wallet balance with respect to spaces
 * It is used to calculate total amount in places like overview
 */
export const selectEmmaBalanceWithSpaceFilter = createSelector(selectIsDefaultSpace, (store) => store.pots.account, (store) => store.pots.walletBalance, selectDefaultCurrency, (isDefaultSpace, potsAccount, walletBalance, defaultCurrency) => {
    if (potsAccount?.status === 'ACTIVE' && isDefaultSpace && walletBalance) {
        return walletBalance;
    }
    return {
        value: 0,
        currency: defaultCurrency,
    };
});
export const selectEmmaWalletBalance = createSelector((store) => store.pots.account, (store) => store.pots.walletBalance, selectDefaultCurrency, (potsAccount, walletBalance, defaultCurrency) => {
    if (potsAccount?.status === 'ACTIVE' && walletBalance) {
        return walletBalance;
    }
    return {
        value: 0,
        currency: defaultCurrency,
    };
});
/**
 * Balance history selectors end
 */
export const selectFirstThreeEmmaWalletActivities = createSelector((state) => state.emmaWallet.activities, (activities) => activities?.slice(0, 3));
export const selectEmmaWalletActivitiesInitialLoading = createSelector((state) => state.emmaWallet.requestingActivities, (state) => state.emmaWallet.paginationToken, (requesting, paginationToken) => !!(requesting && paginationToken === undefined));
export const selectBillingHistoryFirstThree = createSelector((state) => state.billingHistory.billingHistory, (history) => {
    const billingHistory = history?.slice(0, 3);
    return {
        billingHistory,
        mayHaveMore: !!(billingHistory?.length === 3),
        billingHistoryCount: billingHistory?.length || 0,
    };
});
export const selectBillingHistoryInitialLoading = createSelector((state) => state.billingHistory.requestingHistory, (state) => state.billingHistory.page, (requesting, page) => !!(requesting && page === 1));
export const selectEmmaWalletActivitySections = createSelector((state) => state.emmaWallet.activities, selectLatestUpcomingPayment, (activities, latestUpcomingPayment) => {
    const activitySections = activities?.length
        ? getActivitiesSectionedFlashlist(activities, 'timestamp')
        : [];
    const upcomingSections = latestUpcomingPayment
        ? [
            {
                id: 'Upcoming',
                sectionTitle: 'Upcoming',
            },
            latestUpcomingPayment,
        ]
        : [];
    return [...upcomingSections, ...activitySections];
});
export const selectSectionedAccounts = createSelector([
    (_state, title, isPieChartSelected) => {
        if (!isPieChartSelected) {
            return undefined;
        }
        return title;
    },
    selectTotalDebtAccounts,
    selectAllSavingAccounts,
    selectTotalAssetsAccounts,
    selectAllInvestmentWithCryptoAccounts,
], (title, debtAccounts, savingsAccounts, assetsAccounts, investmentAccounts) => {
    if (title === undefined) {
        return emptyArray;
    }
    switch (title) {
        case 'Savings': {
            const data = [];
            savingsAccounts.array.forEach((item) => {
                const [balance] = getBalance(item, false);
                if (!balance?.amount ||
                    ('proPreviewAccount' in item && item.proPreviewAccount) ||
                    ('ultimatePreviewAccount' in item && item.ultimatePreviewAccount) ||
                    ('deactivatedOverQuota' in item && item.deactivatedOverQuota)) {
                    return;
                }
                const { currency } = savingsAccounts;
                data.push({
                    hideIcon: true,
                    key: String(item.id),
                    value: balance.amount,
                    category: {
                        currency,
                        name: item.name,
                    },
                });
            });
            return data;
        }
        case 'Investments': {
            const data = [];
            investmentAccounts.array.forEach((item) => {
                const [balance] = getBalance(item, false);
                if (!balance?.amount ||
                    ('proPreviewAccount' in item && item.proPreviewAccount) ||
                    ('ultimatePreviewAccount' in item && item.ultimatePreviewAccount) ||
                    ('deactivatedOverQuota' in item && item.deactivatedOverQuota)) {
                    return;
                }
                const { currency } = investmentAccounts;
                data.push({
                    hideIcon: true,
                    key: String(item.id),
                    value: balance.amount,
                    category: {
                        currency,
                        name: item.name,
                    },
                });
            });
            return data;
        }
        case 'Net Worth - Debt': {
            const data = [];
            debtAccounts.forEach((item) => {
                const [balance] = getBalance(item, false);
                if (!balance?.amount ||
                    ('proPreviewAccount' in item && item.proPreviewAccount) ||
                    ('ultimatePreviewAccount' in item && item.ultimatePreviewAccount) ||
                    ('deactivatedOverQuota' in item && item.deactivatedOverQuota)) {
                    return;
                }
                const { currency } = investmentAccounts;
                data.push({
                    hideIcon: true,
                    key: String(item.id),
                    value: balance.amount,
                    category: {
                        currency,
                        name: item.name,
                    },
                });
            });
            return data;
        }
        case 'Net Worth - Asset': {
            const data = [];
            assetsAccounts.forEach((item) => {
                const [balance] = getBalance(item, false);
                if (!balance?.amount ||
                    ('proPreviewAccount' in item && item.proPreviewAccount) ||
                    ('ultimatePreviewAccount' in item && item.ultimatePreviewAccount) ||
                    ('deactivatedOverQuota' in item && item.deactivatedOverQuota)) {
                    return;
                }
                const { currency } = investmentAccounts;
                data.push({
                    hideIcon: true,
                    key: String(item.id),
                    value: balance.amount,
                    category: {
                        currency,
                        name: item.name,
                    },
                });
            });
            return data;
        }
        default:
            return emptyArray;
    }
});
export const selectFeatureFlag = createSelector([
    (store) => store.utils.featureFlags,
    (_, flag) => flag,
], (featureFlags, flag) => ({
    value: checkFeatureFlag(featureFlags, flag),
    extra: featureFlags[flag]?.extra,
}), {
    memoizeOptions: {
        resultEqualityCheck: (a, b) => a.value === b.value && a.extra === b.extra,
    },
});
export const selectIsChangeOfAddress = createSelector((state) => state.addressReducer.userAddresses, (addresses) => !!addresses.find((address) => address.kycStatus === 'SUCCESS'));
export const getDisplayAddressGB = (addresses) => {
    /**
     * This is the address with isPrimary
     */
    let primaryAddress;
    /**
     * This is the address with KYC status
     */
    let addressWithKYCStatus;
    for (let i = 0; i < addresses.length; i++) {
        if (primaryAddress && addressWithKYCStatus) {
            break;
        }
        const { kycStatus, isPrimary } = addresses[i];
        if (kycStatus && !addressWithKYCStatus) {
            addressWithKYCStatus = addresses[i];
        }
        if (isPrimary && !primaryAddress) {
            primaryAddress = addresses[i];
        }
    }
    if (addressWithKYCStatus) {
        return addressWithKYCStatus;
    }
    if (primaryAddress) {
        return primaryAddress;
    }
    return undefined;
};
export const selectPrimaryAddressForGB = createSelector((state) => state.addressReducer.userAddresses, (addresses) => getDisplayAddressGB(addresses));
export const selectSecondaryAddresses = createSelector((state) => state.addressReducer.userAddresses, selectPrimaryAddressForGB, (addresses, primaryAddress) => {
    if (primaryAddress) {
        return addresses.filter((address) => address.id !== primaryAddress.id);
    }
    return emptyArray;
});
export const selectAddressToDisplay = createSelector(selectIsGBUser, selectPrimaryAddress, selectPrimaryAddressForGB, (isGbUser, primaryAddress, primaryAddressGB) => {
    if (isGbUser) {
        return primaryAddressGB;
    }
    return primaryAddress;
});
const getUserNationalitiesStr = (nationalities) => {
    if (nationalities?.length) {
        return nationalities.reduce((acc, curr, index) => {
            const country = lookup.byIso(curr)?.country || curr;
            return index === 0 ? country : `${acc}, ${country}`;
        }, '');
    }
    return '';
};
export const selectUserNationalities = createSelector((store) => store.user.user, (user) => {
    const nationalitiesStr = getUserNationalitiesStr(user.nationalities);
    const citizenshipStr = !nationalitiesStr && user.citizenship
        ? getUserNationalitiesStr([user.citizenship])
        : '';
    return nationalitiesStr || citizenshipStr;
});
export const selectHasUSCitizenship = createSelector((store) => store.user.user, (user) => {
    if (user.nationalities?.length) {
        return user.nationalities.includes('USA');
    }
    return false;
});
export const selectAccountsForAutosave = createSelector(selectConnections, (store) => store.pots.savingsPlan?.bankInfo?.accountId, (allConnections, savingsPlanAccountId) => {
    const connections = allConnections.filter((conn) => conn.bankInfo.provider !== 'MANUAL' && !conn.isClosed);
    const accounts = [];
    let bankAccount;
    for (let index = 0; index < connections.length; index++) {
        for (let x = 0; x < connections[index].accounts.length; x++) {
            const { id, type, sortCode, isRemoved, accountNumber, deactivatedOverQuota, } = connections[index].accounts[x];
            /**
             * We have to show the account used in savings plan even it is deactivated over quota
             */
            if (!isRemoved && sortCode && savingsPlanAccountId === id) {
                bankAccount = { ...connections[index].accounts[x], sortCode };
            }
            else if (!deactivatedOverQuota &&
                type === 'CHECKING' &&
                sortCode &&
                sortCode.length > 0 &&
                accountNumber &&
                accountNumber.length > 0 &&
                validAccountNumber(accountNumber) &&
                validSortCode(sortCode) &&
                !isRemoved) {
                const account = {
                    ...connections[index].accounts[x],
                    sortCode,
                };
                accounts.push(account);
            }
        }
    }
    return bankAccount ? [bankAccount, ...accounts] : accounts;
});
export const selectSavingsPlanConnectionId = createSelector(selectConnections, (store) => store.pots.savingsPlan?.bankId, (store) => store.pots.savingsPlan?.bankInfo?.accountId, (connections, bankId, savingsPlanAccountId) => {
    let savingsPlanConnectionId;
    if (connections?.length && bankId && savingsPlanAccountId) {
        for (let i = 0; i < connections.length; i++) {
            const connectionI = connections[i];
            if (connectionI.bankId === bankId) {
                const bankAcc = connectionI.accounts.find((account) => account.id === savingsPlanAccountId);
                if (bankAcc) {
                    savingsPlanConnectionId = connectionI.id;
                    break;
                }
            }
        }
    }
    return savingsPlanConnectionId;
});
const findErrorToDisplay = (errorsData) => errorsData && errorsData.find((error) => error && error.errorDisplayModal);
const findSpecificError = (errorType, errorsData) => errorsData &&
    errorsData.find((error) => error && error.errorType === errorType);
export const selectErrorToDisplay = createSelector((store) => store.error.errorsData, (errorsData) => {
    if (errorsData?.length) {
        const noNetworkError = findSpecificError('FetchError', errorsData);
        if (noNetworkError) {
            log(noNetworkError);
        }
        else {
            // Handles showModal flags for each errors
            const errorToDisplay = findErrorToDisplay(errorsData);
            return errorToDisplay;
        }
    }
    return undefined;
});
export const selectAnalyticsPosition = createNonMemoizedSelector([
    (store) => store.expenses.analyticsPosition,
    (store) => store.expenses.budgetingPosition,
    (_, type) => type,
], (analyticsPosition, budgetingPosition, type) => {
    if (type === 'analytics') {
        return analyticsPosition;
    }
    return budgetingPosition;
});
