import React, { useCallback } from 'react';
import { Image, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import normalize from 'common/Normalize';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import getSymbolFromCurrency from 'currency-symbol-map';
import { selectFeed } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import OneMillionFace from '../components/OneMillionFace';
import OneMillionZaps from '../components/OneMillionZaps';
import CloseIcon from '../icons/CloseIcon';
const OneMillionNetWorth = ({ navigation }) => {
    const colors = useColors();
    const feed = useAppSelector(selectFeed);
    const yearsLeft = feed.cards.oneMillionNetWorth?.yearsLeft;
    const balance = feed.cards.overview?.netWorth;
    const onPressClose = useCallback(() => {
        navigation.pop();
    }, []);
    if (!yearsLeft)
        return null;
    const currencyValue = balance && balance.currency ? getSymbolFromCurrency(balance.currency) || '' : '';
    const message = yearsLeft < 0 ? `Over 999 years` : `${yearsLeft} years`;
    return (<LinearGradient start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }} colors={colors.gradients.brandGradient.colors} style={styles.content}>
      <StatusBar barStyle="light-content" animated/>
      <View style={styles.starsContainer}>
        <Image source={require('../img/stars.png')} style={styles.stars}/>
      </View>
      <OneMillionZaps style={styles.leftZaps} rotate="15deg" flipHorizontal/>
      <OneMillionZaps style={styles.rightZaps} rotate="15deg" delay={500}/>
      <View style={styles.facesContainer}>
        <OneMillionFace style={styles.bigFace} delay={350}/>
        <OneMillionFace style={styles.smallFace}/>
      </View>
      <View style={styles.textContainer}>
        <Text Text-32 White style={styles.textTitle}>
          {message}
        </Text>
        <Text Text-20 centered White style={styles.textMessage}>{`This is the time it will take you\nto get to ${currencyValue}1m net worth!`}</Text>
      </View>
      <TouchableOpacity onPress={onPressClose} style={styles.closeButton} testID="closeButton">
        <CloseIcon style={{ width: 20, height: 20 }}/>
      </TouchableOpacity>
    </LinearGradient>);
};
export default OneMillionNetWorth;
const styles = StyleSheet.create({
    content: {
        flex: 1,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    starsContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    stars: {
        width: 333,
        height: 594,
        marginTop: '20%',
    },
    leftZaps: {
        position: 'absolute',
        top: '10%',
        left: '5%',
        height: 84,
        width: 112,
    },
    rightZaps: {
        position: 'absolute',
        top: '10%',
        right: '5%',
        width: 144,
        height: 108,
    },
    facesContainer: {
        alignItems: 'flex-end',
        flexDirection: 'row',
    },
    bigFace: {
        width: 150,
        height: 150,
        marginBottom: 20,
        transform: [{ rotate: `-15deg` }],
    },
    smallFace: {
        width: 70,
        height: 70,
        marginLeft: -30,
        transform: [{ rotate: `15deg` }],
    },
    textContainer: {
        alignItems: 'center',
    },
    textTitle: {
        marginTop: normalize(32),
        fontFamily: 'Montserrat',
        fontWeight: '700',
        fontSize: 40,
        lineHeight: 48,
    },
    textMessage: {
        marginTop: normalize(32),
        fontFamily: 'Montserrat',
        fontWeight: '600',
    },
    closeButton: {
        height: 58,
        width: 58,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 29,
        position: 'absolute',
        bottom: 64,
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
});
