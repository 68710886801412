import React, { useState } from 'react';
import { View } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableBounce from 'utils/packages/TouchableBounce';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import IconCheck from './icons/IconCheck';
import IconCopy from './icons/IconCopy';
import { IconLink } from './icons/IconLink';
import { IconShareSquare } from './icons/IconShareSquare';
import Text from './Text';
import { rem } from './values';
import { isWeb } from '../constants';
const string = {
    copied: 'Link copied',
};
const ShareButton = ({ title, onPress, style }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const [copied, setCopied] = useState(false);
    const handlePressOnWeb = () => {
        Clipboard.setString(title);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000); // Reset after 3 seconds
    };
    if (isWeb && copied) {
        return (<View style={[styles.baseButton, styles.copiedButton, style]}>
        <IconCheck color={colors.elements.brand}/>
        <Text TextThin-14 Primary numberOfLines={1}>
          {string.copied}
        </Text>
      </View>);
    }
    return (<TouchableBounce onPress={isWeb ? handlePressOnWeb : onPress} style={[styles.baseButton, styles.linkButton, style]}>
      <IconLink colorKey="brand" style={styles.linkIcon}/>
      <Text TextThin-14 Primary style={styles.linkButtonText} numberOfLines={1}>
        {title}
      </Text>
      {isWeb && (<IconCopy fill={colors.elements.brand} style={styles.sendIcon}/>)}
      {!isWeb && <IconShareSquare colorKey="brand" style={styles.sendIcon}/>}
    </TouchableBounce>);
};
const styleSet = createStyleSheets((colors) => ({
    baseButton: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: rem(12),
        paddingHorizontal: rem(15),
        paddingVertical: rem(12),
        backgroundColor: colors.background.brandLight,
        marginBottom: rem(8),
    },
    linkButton: {
        flexShrink: 1,
    },
    copiedButton: {
        justifyContent: 'center',
    },
    linkButtonText: {
        flex: 1,
        flexShrink: 1,
    },
    linkIcon: {
        marginRight: rem(8),
        transform: [{ rotateZ: '90deg' }],
    },
    sendIcon: {
        marginLeft: rem(16),
    },
}));
export default ShareButton;
