import Config from 'utils/packages/configs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CALL_API, TOKEN_LIFETIME_SAFETY_MARGIN_SEC, getAccessToken, } from 'middleware/api';
import { Platform } from 'react-native';
import RNAdvertisingId from 'react-native-advertising-id';
import { formatISO, addSeconds } from 'date-fns';
import form from 'utils/api/form';
import { getAttributionDataFromAsyncStorage } from 'utils/appsflyer';
import { getClientId, setClientId, setTokens } from 'utils/jwtHelpers';
import appsFlyer from 'utils/packages/rnAppsflyer';
import * as types from './types';
import { sendAppsFlyerData } from './utils';
// eslint-disable-next-line import/no-extraneous-dependencies
const uuidV4 = require('uuid/v4');
export function getAttributionData() {
    return async (dispatch) => {
        appsFlyer.getAppsFlyerUID(async (err, appsFlyerId) => {
            if (!err) {
                const body = {
                    platform: Platform.OS,
                    appsFlyerId,
                };
                try {
                    const responseAdvertising = await RNAdvertisingId.getAdvertisingId();
                    if (responseAdvertising && responseAdvertising.advertisingId) {
                        if (Platform.OS === 'ios')
                            body.idfa = responseAdvertising.advertisingId;
                        else
                            body.gaid = responseAdvertising.advertisingId;
                    }
                    body.platform = Platform.OS;
                    body.data = (await getAttributionDataFromAsyncStorage()) || undefined;
                    dispatch(sendAppsFlyerData(body));
                }
                catch (e) {
                    // No op
                }
            }
        });
    };
}
export const getReferalCode = async () => {
    let referral_code = null;
    try {
        referral_code = await AsyncStorage.getItem('referral_code');
        if (referral_code) {
            return {
                code: referral_code,
            };
        }
    }
    catch (error) {
        // No op
    }
    return undefined;
};
export const storeAccessAndRefreshToken = async (token) => {
    const expireDateString = formatISO(addSeconds(new Date(), (token.expires_in ?? 25) - TOKEN_LIFETIME_SAFETY_MARGIN_SEC));
    try {
        if (token.refresh_token && token.access_token)
            await setTokens(token.access_token, token.refresh_token, expireDateString);
        else {
            if (token.refresh_token)
                await setTokens(undefined, token.refresh_token, expireDateString);
            if (token.access_token)
                await setTokens(token.access_token, undefined, expireDateString);
        }
    }
    catch (error) {
        // Error saving data
    }
    await getAccessToken.next({
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
        timestamp: expireDateString,
    });
};
export const signInV2 = (request, muteAlert = false) => ({
    [CALL_API]: {
        endpoint: '/sign-in',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        extra: request,
        types: [
            types.SIGN_IN_REQUEST,
            types.SIGN_IN_SUCCESS,
            types.SIGN_IN_FAILURE,
        ],
        noAccessToken: true,
        muteAlert,
    },
});
export const getToken = (request) => ({
    [CALL_API]: {
        endpoint: '/oauth/token',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            grant_type: 'multi_step',
            scope: 'offline_access',
            client_id: request.clientId,
            ...request,
        }),
        extra: request,
        types: [
            types.SIGN_IN_REQUEST,
            types.SIGN_IN_SUCCESS,
            types.SIGN_IN_FAILURE,
        ],
        noAccessToken: true,
        muteAlert: true,
    },
});
export const getTokenForAuthProvider = (request) => ({
    [CALL_API]: {
        muteAlert: true,
        endpoint: '/oauth/token',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            scope: 'offline_access',
            ...request,
        }),
        extra: request,
        types: [
            types.GET_APPLE_FACEBOOK_TOKEN_REQUEST,
            types.GET_APPLE_FACEBOOK_TOKEN_SUCCESS,
            types.GET_APPLE_FACEBOOK_TOKEN_FAILURE,
        ],
        noAccessToken: true,
    },
});
export const verifyCode = (request) => ({
    [CALL_API]: {
        endpoint: '/oauth/token',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            grant_type: 'phone_number_verify_code',
            scope: 'offline_access',
            ...request,
        }),
        extra: request,
        types: [
            types.VERIFY_CODE_REQUEST,
            types.VERIFY_CODE_SUCCESS,
            types.VERIFY_CODE_FAILURE,
        ],
        noAccessToken: true,
    },
});
// todo also refactor this to use fetch hook
export const verifyPhoneNumber = (phone_number, country_code, mode) => 
// eslint-disable-next-line no-unused-vars
async () => {
    let client_id = null;
    try {
        client_id = await getClientId();
    }
    catch (error) {
        // No op
    }
    if (!client_id) {
        client_id = uuidV4();
        setClientId(client_id);
    }
    const params = {
        grant_type: 'phone_number_request_code',
        client_id,
        scope: 'offline_access',
        phone_number,
        country_code,
        via: mode,
    };
    const body = form(params);
    return fetch(`${Config.API_URL}/oauth/token`, {
        method: 'POST',
        body,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
        .then((response) => response.json())
        .then(async (responseJson) => responseJson)
        .catch((error) => error);
};
