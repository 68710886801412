import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
const Share = ({ size = 24, style, color }) => {
    const colors = useColors();
    const fill = color ?? colors.elements.secondary;
    return (<Svg width={rem(size)} height={rem(size)} style={style} viewBox="0 0 24 24">
      <Path d="M20.924 4.117C20.8741 3.99665 20.801 3.88725 20.709 3.795L20.705 3.791C20.5179 3.6044 20.2643 3.49973 20 3.5H14C13.7348 3.5 13.4804 3.60536 13.2929 3.79289C13.1054 3.98043 13 4.23478 13 4.5C13 4.76522 13.1054 5.01957 13.2929 5.20711C13.4804 5.39464 13.7348 5.5 14 5.5H17.586L10.293 12.793C10.1975 12.8852 10.1213 12.9956 10.0689 13.1176C10.0165 13.2396 9.9889 13.3708 9.98775 13.5036C9.9866 13.6364 10.0119 13.7681 10.0622 13.891C10.1125 14.0139 10.1867 14.1255 10.2806 14.2194C10.3745 14.3133 10.4861 14.3875 10.609 14.4378C10.7319 14.4881 10.8636 14.5134 10.9964 14.5123C11.1292 14.5111 11.2604 14.4835 11.3824 14.4311C11.5044 14.3787 11.6148 14.3025 11.707 14.207L19 6.914V10.5C19 10.7652 19.1054 11.0196 19.2929 11.2071C19.4804 11.3946 19.7348 11.5 20 11.5C20.2652 11.5 20.5196 11.3946 20.7071 11.2071C20.8946 11.0196 21 10.7652 21 10.5V4.5V4.497C21 4.367 20.974 4.239 20.924 4.117ZM3 8.5C3 7.17392 3.52678 5.90215 4.46447 4.96447C5.40215 4.02678 6.67392 3.5 8 3.5H9C9.26522 3.5 9.51957 3.60536 9.70711 3.79289C9.89464 3.98043 10 4.23478 10 4.5C10 4.76522 9.89464 5.01957 9.70711 5.20711C9.51957 5.39464 9.26522 5.5 9 5.5H8C7.20435 5.5 6.44129 5.81607 5.87868 6.37868C5.31607 6.94129 5 7.70435 5 8.5V16.5C5 17.2957 5.31607 18.0587 5.87868 18.6213C6.44129 19.1839 7.20435 19.5 8 19.5H16C16.7956 19.5 17.5587 19.1839 18.1213 18.6213C18.6839 18.0587 19 17.2957 19 16.5V15.5C19 15.2348 19.1054 14.9804 19.2929 14.7929C19.4804 14.6054 19.7348 14.5 20 14.5C20.2652 14.5 20.5196 14.6054 20.7071 14.7929C20.8946 14.9804 21 15.2348 21 15.5V16.5C21 17.8261 20.4732 19.0979 19.5355 20.0355C18.5979 20.9732 17.3261 21.5 16 21.5H8C6.67392 21.5 5.40215 20.9732 4.46447 20.0355C3.52678 19.0979 3 17.8261 3 16.5V8.5Z" fill={fill}/>
    </Svg>);
};
export default Share;
