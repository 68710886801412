import React, { useRef, useState, useEffect, useCallback, useLayoutEffect, useMemo, } from 'react';
import { View } from 'react-native';
import Modal from 'design-system/Modal';
import { rem } from 'design-system/values';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import { CardView } from 'design-system/CardView';
import Button, { HEIGHT } from 'design-system/Button';
import PopUpMenuModal from 'design-system/PopUpMenuModal.web';
import ScrollView from 'design-system/ScrollView';
import { HelpNavigationButton } from 'design-system/TextButton';
import Links from 'utils/links';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import Alert from 'utils/packages/Alert';
import { getCategories } from 'actions/user';
import { useAppDispatch } from 'store/hooks';
import { getAccountGoals } from 'actions/connections';
import { muteAll, useFetchOld } from 'hooks/useFetch';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { splitTransactions } from 'actions/transactions';
import { formatAmount, splitAmount } from 'utils/formatting';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useKeyboardAdjustPan } from 'hooks/useKeyboardAdjustPan';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { isWeb } from '../../../constants';
import { useTransaction } from '../hooks/useTransaction';
import RowTransaction from '../components/RowTransaction';
import SplitList from '../components/split/SplitList';
import CategoryMenu from '../components/split/CategoryMenuContent';
const SplitTransactionScreen = ({ navigation, transaction, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const btnContainer = useMarginBottom('paddingBottom', undefined, styles.buttonsContainer);
    useKeyboardAdjustPan();
    const scrollRef = useRef(null);
    const [state, setState] = useState(() => {
        const { category, nativeAmount, currency } = transaction;
        const transactionCurrency = nativeAmount ? nativeAmount.currency : currency;
        const amount = nativeAmount ? nativeAmount.amount : transaction.amount;
        const splitted = splitAmount(Math.abs(amount), 2);
        return {
            originalAmount: amount,
            data: [
                {
                    id: 0,
                    amount: splitted[0],
                    category,
                    currency: transactionCurrency,
                },
                {
                    id: 1,
                    amount: splitted[1],
                    category,
                    currency: transactionCurrency,
                },
            ],
        };
    });
    const closeMenu = useCallback(() => {
        if (isWeb) {
            PopUpMenuModal.hide();
        }
        else {
            Modal.hide();
        }
    }, []);
    const onSelectCategory = useCallback((category, indexValue) => {
        setState((state) => ({
            ...state,
            data: state.data.map((item, index) => {
                if (index === indexValue) {
                    return { ...item, category };
                }
                return item;
            }),
        }));
    }, []);
    const openMenu = useCallback((id, selected, measurements) => {
        if (isWeb) {
            PopUpMenuModal.show(<CategoryMenu openItem={{
                    id,
                    selected,
                }} onSelect={onSelectCategory} onClose={closeMenu}/>, {
                fx: measurements.fx,
                fy: measurements.fy + rem(40),
                containerStyle: styles.categoryMenuPopup,
            });
        }
        else {
            Modal.show(<CategoryMenu openItem={{
                    id,
                    selected,
                }} onSelect={onSelectCategory} onClose={closeMenu}/>, {
                title: `Transaction ${id + 1}`,
                scrollable: {
                    maxHeight: rem(320),
                },
                propagateSwipe: true,
            });
        }
    }, [closeMenu, onSelectCategory, styles.categoryMenuPopup]);
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const saveSplitCb = useCallback(() => {
        const { id } = transaction;
        const array = state.data;
        const sign = state.originalAmount > 0 ? 1 : -1;
        const hasZero = array.find((item) => Number.isNaN(item.amount) || item.amount === 0);
        if (hasZero) {
            Alert.alert('Slow down 🤪', `A ${formatAmount(hasZero.amount, hasZero.currency)} split is not allowed in the app.`);
            return;
        }
        const body = {
            splitTransactions: array.map((item) => ({
                amount: item.amount * sign,
                categoryId: item.category.id,
            })),
        };
        fetch(splitTransactions(id, body)).then((response) => {
            if (response) {
                dispatch(getAccountGoals());
            }
        });
        navigation.navigate('SuccessSplit', {
            transactionsCount: array.length,
        });
    }, [transaction, state.data, state.originalAmount]);
    const saveSplit = useRequiredBenefitCallbackWithSpaces(saveSplitCb, 'split');
    const onHelpPress = useCallback(() => {
        Links.openKnownLink('splitTxnHelp');
    }, []);
    useLayoutEffect(() => {
        const nameToDisplay = transaction
            ? transaction?.customName || transaction?.counterpartName
            : 'Rename transaction';
        navigation.setOptions({
            headerRight: () => <HelpNavigationButton onPress={onHelpPress} black/>,
            title: nameToDisplay,
            headerTitle: 'Split Transaction',
            headerTintColor: colors.text.primary,
        });
    }, [
        colors.text.primary,
        onHelpPress,
        saveSplit,
        styles.headerRightBtn,
        transaction,
    ]);
    useEffect(() => {
        dispatch(getCategories());
    }, []);
    const showRemove = state.data.length > 2;
    const listStyle = useMarginBottom('paddingBottom', HEIGHT + rem(16));
    const removeSplit = useCallback(() => {
        setState((state) => {
            const newArray = [...state.data];
            const penultElementAmount = Math.round(newArray[newArray.length - 2].amount * 100);
            const lastElementAmount = Math.round(newArray[newArray.length - 1].amount * 100);
            newArray[newArray.length - 2].amount =
                (penultElementAmount + lastElementAmount) / 100;
            return { ...state, data: newArray.slice(0, -1) };
        });
    }, []);
    const addSplit = useCallback(() => {
        setState((state) => {
            const newArray = [...state.data];
            const lastElementAmount = newArray[newArray.length - 1].amount;
            if (lastElementAmount === 0.01)
                return state;
            const [first, second] = splitAmount(lastElementAmount, 2);
            newArray[newArray.length - 1].amount = first;
            return {
                ...state,
                data: newArray.concat([
                    {
                        id: newArray.length,
                        amount: second,
                        currency: transaction.nativeAmount
                            ? transaction.nativeAmount.currency
                            : transaction.currency,
                        category: newArray[newArray.length - 1].category,
                    },
                ]),
            };
        });
        scrollRef.current?.scrollToEnd();
    }, [transaction.currency, transaction.nativeAmount]);
    const splitButtons = useMemo(() => (<View style={styles.splitButtons}>
        {showRemove && (<Button flex square brandReversed title="Remove split" onPress={removeSplit}/>)}
        <Button flex square brandReversed onPress={addSplit} title="Add another split"/>
      </View>), [addSplit, removeSplit, showRemove, styles.splitButtons]);
    const { paddingHorizontal } = useAppFrameDimensions(isWeb);
    return (<AppView style={styles.content} withFrame={false}>
      <StatusBar animated barStyle="dark-content"/>
      <ScrollView secondary ref={scrollRef} isInTab={isWeb} useKeyboardAware scrollEventThrottle={16} keyboardDismissMode="interactive">
        <CardView style={styles.card} webPadding>
          <RowTransaction categoryPosition="left" item={transaction} disabled backgroundDark/>
        </CardView>
        <SplitList state={state} setState={setState} style={listStyle} onPressShowMenu={openMenu} splitButtons={splitButtons}/>
      </ScrollView>
      <View style={btnContainer}>
        <View style={[
            styles.buttonsInnerContainer,
            { marginHorizontal: paddingHorizontal },
        ]}>
          <Button flex brand frosted onPress={saveSplit} title="Save"/>
        </View>
      </View>
    </AppView>);
};
const SplitTransactionScreenWrapper = (props) => {
    const { id } = props.route.params;
    const { transaction, LoadingOr404 } = useTransaction(id);
    useEffect(() => {
        if (!transaction) {
            props.navigation.setOptions({
                headerTitle: '',
                title: 'Split Transaction',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!transaction) {
        return LoadingOr404;
    }
    return <SplitTransactionScreen {...props} transaction={transaction}/>;
};
const styleSet = createStyleSheets((colors) => ({
    content: {
        flex: 1,
    },
    card: {
        marginTop: 0,
    },
    splitButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: rem(8),
        marginHorizontal: rem(16),
        marginBottom: rem(16),
        marginTop: rem(8),
    },
    buttonsContainer: {
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        justifyContent: 'center',
        paddingHorizontal: rem(16),
    },
    buttonsInnerContainer: {
        flexDirection: 'row',
    },
    headerRightBtn: {
        ...(isWeb && {
            marginRight: rem(16),
            paddingVertical: rem(8),
            paddingHorizontal: rem(16),
        }),
    },
    categoryMenuPopup: {
        marginTop: 8,
        backgroundColor: colors.background.light,
        ...colors.shadows.webFloatingCard,
    },
}));
export default SplitTransactionScreenWrapper;
