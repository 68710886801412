export const filterPreferences = (preferences, notificationKeys) => notificationKeys.reduce((prev, key) => {
    const value = preferences[key];
    if (value !== undefined) {
        return {
            ...prev,
            [key]: value,
        };
    }
    return prev;
}, {});
