import { differenceInMilliseconds } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
export const orderCommitted = (subscriptions) => subscriptions.slice().sort((a, b) => {
    if (a && !a.prediction)
        return 1;
    if (b && !b.prediction)
        return -1;
    const pa = a.prediction?.date;
    const pb = b.prediction?.date;
    if (!pa)
        return 1;
    if (!pb)
        return -1;
    return differenceInMilliseconds(toZonedTime(pa, 'UTC'), toZonedTime(pb, 'UTC'));
});
const getToBePaid = (data) => {
    if (!(data && data.length))
        return [];
    let arrayToBePaid = [];
    for (let index = 0; index < data.length; index++) {
        for (let x = 0; x < data[index].predictions.length; x++) {
            arrayToBePaid.push({
                ...data[index],
                prediction: data[index].predictions[x],
            });
        }
    }
    arrayToBePaid = orderCommitted(arrayToBePaid);
    return arrayToBePaid;
};
export default getToBePaid;
