import { createApiModule } from 'hooks/fetch';
const MODULE_NAME = 'Spaces';
const createApiModuleActions = createApiModule(MODULE_NAME);
// Reducers
const SPACES = 'spaces';
const SPACES_INFO = 'spaces';
const reducers = {
    SPACES,
    SPACES_INFO,
};
// Api actions
export const GET_SPACES_REQUEST = 'GET_SPACES_REQUEST';
export const GET_SPACES_SUCCESS = 'GET_SPACES_SUCCESS';
export const GET_SPACES_FAILURE = 'GET_SPACES_FAILURE';
export const GET_SPACE_INFO_REQUEST = 'GET_SPACE_INFO_REQUEST';
export const GET_SPACE_INFO_SUCCESS = 'GET_SPACE_INFO_SUCCESS';
export const GET_SPACE_INFO_FAILURE = 'GET_SPACE_INFO_FAILURE';
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS';
// When the user selects a space
export const SELECTED_SPACE = 'SELECTED_SPACE';
// When the space actually changes (they might select the space they are already in)
export const SWITCHED_SPACE = 'SWITCHED_SPACE';
export const FETCH_SPACES = 'FETCH_SPACES';
export const DISMISSED_SPACE_NOT_PREMIUM_ERROR = 'DISMISSED_SPACE_NOT_PREMIUM_ERROR';
export const SPACE_SWITCHED_MODAL_VISIBLE_FOR_TIME = 3000;
const getSpaces = [GET_SPACES_REQUEST, GET_SPACES_SUCCESS, GET_SPACES_FAILURE];
const getSpaceInfo = [
    GET_SPACE_INFO_REQUEST,
    GET_SPACE_INFO_SUCCESS,
    GET_SPACE_INFO_FAILURE,
];
const createSpace = createApiModuleActions('CREATE_SPACE');
const setSpaceName = createApiModuleActions('SET_SPACE_NAME');
const deleteSpace = createApiModuleActions('DELETE_SPACE');
const joinSpace = createApiModuleActions('JOIN_SPACE');
const setAccountSpace = createApiModuleActions('SET_ACCOUNT_SPACE');
const deleteSpaceMember = createApiModuleActions('DELETE_SPACE_MEMBER');
const apiEvents = {
    getSpaces,
    getSpaceInfo,
    createSpace,
    setSpaceName,
    deleteSpace,
    joinSpace,
    setAccountSpace,
    deleteSpaceMember,
};
export { apiEvents, reducers };
export default { apiEvents, reducers };
