import { subMonths, isBefore } from 'date-fns';
import { resetDay } from './date/resetDay';
import { formatDateForSectionHeaderWithDate } from './formatting';
export const removeDuplicateIds = (data) => {
    const map = {};
    data.forEach((el) => {
        map[el.id] = el;
    });
    return Object.values(map);
};
const groupKeyForTransaction = (transaction, dateKey) => {
    if (transaction.isPending) {
        return 'Pending transactions';
    }
    if (dateKey) {
        return resetDay(transaction[dateKey]);
    }
    return resetDay(transaction.customDate ||
        transaction.bookingDate);
};
export const compareISODates = (date1, date2) => new Date(date2).getTime() - new Date(date1).getTime();
/**
 * Group same-day transactions into bins
 * Pending transactions are put into a separate bin which is placed on top
 *
 * @param data Transactions
 * @param key Date key of Transaction used for grouping
 */
export const getTransactions = (data, key) => {
    const pendingTransactions = [];
    const regularTransactions = new Map();
    removeDuplicateIds(data).forEach((transaction) => {
        const groupKey = groupKeyForTransaction(transaction, key);
        if (groupKey === 'Pending transactions') {
            pendingTransactions.push(transaction);
        }
        else {
            const entry = regularTransactions.get(groupKey);
            if (!entry) {
                regularTransactions.set(groupKey, [transaction]);
            }
            else {
                entry.push(transaction);
            }
        }
    });
    const regularTransactionGroups = [];
    regularTransactions.forEach((value, key) => {
        regularTransactionGroups.push({ key, data: value });
    });
    regularTransactionGroups.sort((tr1, tr2) => compareISODates(tr1.key, tr2.key));
    const pendingGroups = pendingTransactions.length
        ? [{ key: 'Pending transactions', data: pendingTransactions }]
        : [];
    return pendingGroups.concat(regularTransactionGroups);
};
export const getTransactionsFlat = (data, key) => {
    const array = getTransactions(data, key);
    return array.reduce((prev, curr) => [
        ...prev,
        { type: 'header', title: curr.key },
        ...curr.data.map((item, index) => ({
            type: 'transaction',
            data: item,
            sectionLength: curr.data.length,
            sectionIndex: index,
        })),
    ], []);
};
export const addLastInSectionInfo = (flashListData) => {
    const lastItem = flashListData.pop();
    if (lastItem) {
        if (!('type' in lastItem) ||
            ('type' in lastItem && lastItem.type !== 'SECTION_HEADER')) {
            flashListData.push({
                ...lastItem,
                isLastInSection: true,
            });
        }
        else {
            flashListData.push(lastItem);
        }
    }
};
export const getTransactionsFlatlistData = (data, shouldLimitTo2Months, shouldRemoveDuplicateIds, shouldSortData) => {
    if (!data?.length) {
        return [];
    }
    const dataSet = shouldRemoveDuplicateIds ? removeDuplicateIds(data) : data;
    if (shouldSortData) {
        dataSet.sort((tr1, tr2) => compareISODates(tr1.customDate || tr1.bookingDate, tr2.customDate || tr2.bookingDate));
    }
    const flashListData = [];
    let hasPendingTxn = false;
    const pendingTransactions = [];
    let pendingTotal = 0;
    let pendingCurrency;
    let activeDate;
    const limitDate = subMonths(new Date(), 2);
    let sectionTotal = 0;
    let lastSectionHeaderIndex = null;
    for (let i = 0; i < dataSet.length; i++) {
        const transaction = dataSet[i];
        if ('isPending' in transaction && transaction.isPending) {
            pendingTransactions.push(hasPendingTxn
                ? transaction
                : {
                    ...transaction,
                    isFirstInSection: true,
                });
            pendingTotal += transaction.amount;
            pendingCurrency = transaction.currency;
            hasPendingTxn = true;
        }
        else {
            const dateToUseRaw = transaction.customDate || transaction.bookingDate;
            const dateToUse = formatDateForSectionHeaderWithDate(dateToUseRaw);
            let didAddNewSection = false;
            if (!activeDate || dateToUse !== activeDate) {
                if (lastSectionHeaderIndex !== null) {
                    const data = flashListData[lastSectionHeaderIndex];
                    if (data && 'totalAmount' in data) {
                        data.totalAmount = sectionTotal;
                    }
                }
                activeDate = dateToUse;
                addLastInSectionInfo(flashListData);
                flashListData.push({
                    type: 'SECTION_HEADER',
                    id: dateToUse,
                    sectionTitle: dateToUse,
                    totalAmount: 0,
                    currency: transaction.currency,
                });
                lastSectionHeaderIndex = flashListData.length - 1;
                didAddNewSection = true;
                sectionTotal = 0;
            }
            if (transaction.category?.id !== 'internal') {
                sectionTotal += transaction.amount;
            }
            const showUpgradeButton = shouldLimitTo2Months && !isBefore(limitDate, dateToUseRaw);
            if (!showUpgradeButton && !didAddNewSection) {
                flashListData.push(transaction);
            }
            else {
                flashListData.push({
                    ...transaction,
                    showUpgradeButton,
                    isFirstInSection: didAddNewSection,
                });
                if (showUpgradeButton) {
                    break;
                }
            }
        }
    }
    if (lastSectionHeaderIndex !== null) {
        const data = flashListData[lastSectionHeaderIndex];
        if (data && 'totalAmount' in data) {
            data.totalAmount = sectionTotal;
        }
    }
    addLastInSectionInfo(flashListData);
    if (hasPendingTxn) {
        addLastInSectionInfo(pendingTransactions);
        return [
            {
                id: 'Pending',
                type: 'SECTION_HEADER',
                sectionTitle: 'Pending transactions',
                totalAmount: pendingTotal,
                currency: pendingCurrency,
            },
            ...pendingTransactions,
            ...flashListData,
        ];
    }
    return flashListData;
};
export const getTransactionsIdObj = (data) => {
    const idObj = {};
    data.forEach((eachData) => {
        idObj[String(eachData)] = true;
    });
    return idObj;
};
