import React from 'react';
import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import { SettingsItem, SettingsItemRightContent, } from 'design-system/SettingsItem';
import Text from 'design-system/Text';
import IconLayers from 'design-system/icons/IconLayers';
import { SettingsItemIcon } from 'design-system/SettingsItemIcon';
import RadioButton from 'design-system/RadioButton';
import { IconEdit } from 'design-system/icons/IconEdit';
import useColors from 'hooks/useColors';
import { formatPlural } from 'utils';
const SpaceItem = ({ space, isSelected, onEdit, onAccounts, selectedSpaceId, onSelect, }) => {
    const colors = useColors();
    const press = () => {
        if (space.isDefault && onAccounts) {
            if (isSelected) {
                onAccounts();
                return;
            }
        }
        if (!onEdit) {
            if (selectedSpaceId === space.id) {
                onSelect(undefined);
                return;
            }
            onSelect(space.id);
        }
        else {
            onEdit();
        }
    };
    const renderIcon = () => {
        if (onEdit) {
            return <IconEdit color={colors.buttons.brand}/>;
        }
        if (selectedSpaceId === space.id && !isSelected) {
            return <RadioButton isChecked type="single"/>;
        }
        return null;
    };
    return ((!space.isDefault || !onEdit) && (<SettingsItem onPress={press} renderIcon={() => (<SettingsItemIcon style={styles.icon}>
            <IconLayers color={isSelected
                ? colors.gradients.ultimate.colors[0]
                : colors.text.secondary}/>
          </SettingsItemIcon>)} title={space.isDefault ? 'Primary Space' : space.name} description={`${space.accountsCount
            ? `${space.accountsCount} ${formatPlural(`account`, 'accounts', space.accountsCount)}`
            : ''}${space.usersCount && space.usersCount > 1
            ? `${space.accountsCount ? ' • ' : ''}${space.usersCount} members`
            : ''}`} topDescription={isSelected ? (<Text Text-12 Primary>
              CURRENT SPACE
            </Text>) : undefined}>
        <SettingsItemRightContent>{renderIcon()}</SettingsItemRightContent>
      </SettingsItem>));
};
const styles = StyleSheet.create({
    icon: {
        marginEnd: rem(16),
    },
});
export default SpaceItem;
