import getSymbolFromCurrency from 'currency-symbol-map';
import { LOGGED_OUT } from 'actions/types';
import { Tier, TierPeriod } from '../entities';
import { GET_STRIPE_CREDITS_SUCCESS, REDEEM_STRIPE_GIFT_SUCCESS, SET_UP_STRIPE_INTENT_CONFIRM_SUCCESS, } from '../actions/types';
const initialState = {
    priceMap: undefined,
    isPlatformPaySupported: false,
    customer: undefined,
    credit: undefined,
};
export const stripe = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_IS_PLATFORM_PAY_SUPPORTED': {
            return {
                ...state,
                isPlatformPaySupported: action.isPlatformPaySupported,
            };
        }
        case 'GET_STRIPE_PRICES_SUCCESS': {
            const { currency } = action.payload;
            const currencySymbol = getSymbolFromCurrency(currency) || currency;
            const priceMap = {
                [TierPeriod.yearly]: {
                    [Tier.plus]: {
                        currency,
                        tier: Tier.plus,
                        period: TierPeriod.yearly,
                        id: action.payload.plus.yearly.id,
                        price: `${action.payload.plus.yearly.price}`,
                        localizedPrice: `${currencySymbol}${action.payload.plus.yearly.price}`,
                        platform: 'stripe',
                    },
                    [Tier.pro]: {
                        currency,
                        tier: Tier.pro,
                        period: TierPeriod.yearly,
                        id: action.payload.pro.yearly.id,
                        price: `${action.payload.pro.yearly.price}`,
                        localizedPrice: `${currencySymbol}${action.payload.pro.yearly.price}`,
                        platform: 'stripe',
                    },
                    [Tier.ultimate]: {
                        currency,
                        tier: Tier.ultimate,
                        period: TierPeriod.yearly,
                        id: action.payload.ultimate.yearly.id,
                        price: `${action.payload.ultimate.yearly.price}`,
                        localizedPrice: `${currencySymbol}${action.payload.ultimate.yearly.price}`,
                        platform: 'stripe',
                    },
                },
                [TierPeriod.monthly]: {
                    [Tier.plus]: {
                        currency,
                        tier: Tier.plus,
                        period: TierPeriod.monthly,
                        id: action.payload.plus.monthly.id,
                        price: `${action.payload.plus.monthly.price}`,
                        localizedPrice: `${currencySymbol}${action.payload.plus.monthly.price}`,
                        platform: 'stripe',
                    },
                    [Tier.pro]: {
                        currency,
                        tier: Tier.pro,
                        period: TierPeriod.monthly,
                        id: action.payload.pro.monthly.id,
                        price: `${action.payload.pro.monthly.price}`,
                        localizedPrice: `${currencySymbol}${action.payload.pro.monthly.price}`,
                        platform: 'stripe',
                    },
                    [Tier.ultimate]: {
                        currency,
                        tier: Tier.ultimate,
                        period: TierPeriod.monthly,
                        id: action.payload.ultimate.monthly.id,
                        price: `${action.payload.ultimate.monthly.price}`,
                        localizedPrice: `${currencySymbol}${action.payload.ultimate.monthly.price}`,
                        platform: 'stripe',
                    },
                },
            };
            return {
                ...state,
                priceMap,
            };
        }
        case SET_UP_STRIPE_INTENT_CONFIRM_SUCCESS:
        case 'GET_STRIPE_CUSTOMER_SUCCESS': {
            return {
                ...state,
                customer: action.payload.customer
                    ? {
                        cardLast4: action.payload.customer?.cardLast4,
                        id: action.payload.customer?.id,
                    }
                    : undefined,
            };
        }
        case GET_STRIPE_CREDITS_SUCCESS: {
            return {
                ...state,
                credit: action.payload,
            };
        }
        case REDEEM_STRIPE_GIFT_SUCCESS: {
            return {
                ...state,
                credit: action.payload,
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
