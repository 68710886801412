import React from 'react';
import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import RowAccount from 'features/accounts/components/RowAccount';
import { isWeb } from '../../../constants';
const AccountItem = ({ account, onPress }) => account.id ? (<RowAccount onCard style={styles.row} data={account} isSelectingAccounts onPress={onPress}/>) : null;
const styles = StyleSheet.create({
    row: {
        paddingHorizontal: rem(16),
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
});
export default AccountItem;
