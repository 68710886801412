import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import TimePeriod from './TimePeriod';
import AnimatedMarker from './AnimatedMarker';
const TimeFrameController = ({ periods, strokeColor, selectedPeriod, paddingHorizontal, setSelectedPeriod, darkActiveIndicator, }) => (<View style={styles.container}>
    <AnimatedMarker periods={periods} backgroundColor={strokeColor} selectedPeriod={selectedPeriod} paddingHorizontal={paddingHorizontal}/>
    {periods.map((period) => (<View style={styles.flex} key={period}>
        <TimePeriod period={period} selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} darkActiveIndicator={darkActiveIndicator}/>
      </View>))}
  </View>);
export default memo(TimeFrameController);
const styles = StyleSheet.create({
    flex: {
        flex: 1,
        alignItems: 'center',
    },
    container: {
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
});
