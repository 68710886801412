import { NativeEventEmitter, NativeModules, Platform } from 'react-native';
import * as RNIap from 'react-native-iap';
import Sentry from 'utils/sentry';
import { utils as firebaseUtils } from 'utils/packages/rnFirebase';
import Modal from 'design-system/Modal';
import { itemSkus, Tier, tierGreaterThan, } from './entities';
export const getItemSKUForProductId = (productId) => itemSkus.find((sku) => sku.id === productId);
/**
 * @see https://developer.android.com/google/play/billing/subscriptions#proration
 * @see https://developer.android.com/google/play/billing/subscriptions#proration-recommendations
 */
export const getAndroidProrationMode = (subscription, emmaProStatus) => {
    if (!emmaProStatus?.tier || emmaProStatus.tier === Tier.free) {
        return undefined;
    }
    const newTier = subscription.tier;
    const oldTier = emmaProStatus.tier;
    /**
     * Transitioning to a higher tier, like pro -> ultimate:
     *
     * The subscription is upgraded immediately.
     * Any time remaining is adjusted based on the price difference, and credited toward the new subscription by pushing forward the next billing date. This is the default behavior.
     */
    if (tierGreaterThan(newTier, oldTier)) {
        return RNIap.ProrationModesAndroid.IMMEDIATE_WITH_TIME_PRORATION;
    }
    // handle upgrade tier BUT billing period shorter USE WITH_TIME_PROBATION
    if (subscription.productId === emmaProStatus.productId) {
        // We're using a cancellation offer to try retain the user, we cannot use defferred
        return RNIap.ProrationModesAndroid.IMMEDIATE_WITHOUT_PRORATION;
    }
    /**
     * Other cases, like:
     * - Transitioning to a lower tier, like ultimate -> pro
     * - Same tier, transitioning to a longer period, like monthly -> yearly
     *
     * The subscription is upgraded or downgraded only when the subscription renews.
     */
    return RNIap.ProrationModesAndroid.DEFERRED;
};
let iapInitialized = false;
export const safeInitIAP = async () => {
    try {
        if (!iapInitialized) {
            iapInitialized = await RNIap.initConnection();
            if (iapInitialized && Platform.OS === 'android') {
                await RNIap.flushFailedPurchasesCachedAsPendingAndroid();
            }
        }
    }
    catch (err) {
        Sentry.logError({
            filename: 'features/premium/iap.ts',
            message: 'Failed to initialize RNIap',
            err,
        });
    }
    return iapInitialized;
};
export const finalizeIAP = async () => {
    try {
        if (iapInitialized) {
            await RNIap.endConnection();
            iapInitialized = false;
        }
    }
    catch {
        // No op
    }
};
export const getIsEligibleForIntroOffer = async () => {
    try {
        const purchaseHistory = await RNIap.getPurchaseHistory({
            alsoPublishToEventListener: false,
            automaticallyFinishRestoredTransactions: false,
        });
        return purchaseHistory.length === 0;
    }
    catch (e) {
        return undefined;
    }
};
export const getIapSubscriptions = async () => {
    const libSubscriptions = await getIapLibSubscriptions(itemSkus.map((e) => e.id));
    return itemSkus
        .map((it) => {
        const sku = libSubscriptions.find((o) => o.productId === it.id);
        return sku &&
            (sku.platform === RNIap.SubscriptionPlatform.ios ||
                sku.platform === RNIap.SubscriptionPlatform.android)
            ? {
                ...it,
                ...sku,
            }
            : undefined;
    })
        .reduce((prev, curr) => (curr ? [...prev, curr] : prev), []);
};
export const getBaseSubscriptionOfferAndroid = (iapSubscription) => {
    const allOffers = iapSubscription.platform === RNIap.SubscriptionPlatform.android &&
        iapSubscription.subscriptionOfferDetails
        ? iapSubscription.subscriptionOfferDetails
        : [];
    const baseTrialOffer = allOffers.find((offer) => offer.offerTags &&
        offer.offerTags.length === 2 &&
        offer.offerTags.includes('freetrial') &&
        offer.offerTags.includes('base'));
    if (baseTrialOffer) {
        return baseTrialOffer;
    }
    const basePriceOffer = allOffers.find((offer) => offer.offerTags &&
        // Child offers inherit the base offer too, so the length MUST be 1
        offer.offerTags.length === 1 &&
        offer.offerTags.includes('base'));
    if (basePriceOffer) {
        return basePriceOffer;
    }
    return allOffers[0];
};
export const getBaseSubscriptionPriceAndroid = (baseOffer) => {
    if (!baseOffer) {
        return undefined;
    }
    const firstNonZeroPricingPhase = baseOffer.pricingPhases.pricingPhaseList.find((phase) => Number(phase.priceAmountMicros) > 0);
    if (firstNonZeroPricingPhase?.priceAmountMicros != null) {
        return {
            // Converting micro-units to currency units
            price: (Number(firstNonZeroPricingPhase.priceAmountMicros) / 1000000).toFixed(2),
            currency: firstNonZeroPricingPhase.priceCurrencyCode,
            localizedPrice: firstNonZeroPricingPhase.formattedPrice,
        };
    }
    return undefined;
};
// To test offer tags we need a subscription with multiple offers:
// const TEST_ID = 'com.emmaapp.onemonth2';
export const getIapLibSubscriptions = async (ids) => {
    if (await safeInitIAP()) {
        try {
            const subscriptions = await RNIap.getSubscriptions({
                skus: ids,
                // .concat(
                //   Platform.select({
                //     android: [TEST_ID],
                //     default: [],
                //   })
                // )
            });
            return subscriptions;
        }
        catch (error) {
            // No op
        }
    }
    return [];
};
export const getAvailablePurchases = async () => {
    try {
        if (await safeInitIAP()) {
            return (await RNIap.getAvailablePurchases()).sort((a, b) => b.transactionDate - a.transactionDate);
        }
    }
    catch {
        // No op
    }
    return [];
};
// This method returns 'future' purchases, that is purchases that have been deferred
// For example user upgrades to yearly when on monthly, the yearly purchase won't happen until the end of the month
export const getLastPurchaseFromPurchaseHistory = async () => {
    try {
        const history = await RNIap.getPurchaseHistory();
        if (history.length > 0) {
            history.sort((a, b) => b.transactionDate - a.transactionDate);
            return getItemSKUForProductId(history[0].productId);
        }
        return undefined;
    }
    catch (e) {
        return undefined;
    }
};
export const getLastPurchase = async () => {
    const purchases = await getAvailablePurchases();
    if (purchases.length > 0) {
        const subscriptions = await getIapLibSubscriptions([
            purchases[0].productId,
        ]);
        if (subscriptions.length > 0) {
            return { purchase: purchases[0], subscription: subscriptions[0] };
        }
    }
    return {};
};
export const finishSubscriptionPurchase = async (purchase) => {
    try {
        if (await safeInitIAP()) {
            return await RNIap.finishTransaction({ purchase, isConsumable: false });
        }
    }
    catch {
        // No op
    }
    return false;
};
export const requestSubscription = async (subscription, emmaProStatus, discount) => {
    try {
        if (await safeInitIAP()) {
            await RNIap.getSubscriptions({ skus: [subscription.id] });
            if (discount && discount.type === RNIap.SubscriptionPlatform.ios) {
                await RNIap.requestSubscription({
                    sku: subscription.id,
                    withOffer: discount.discount,
                });
                return;
            }
            await RNIap.requestSubscription({
                sku: subscription.id,
                purchaseTokenAndroid: Platform.OS === 'android' &&
                    emmaProStatus?.store === 'google' &&
                    emmaProStatus.productId
                    ? await getPurchaseTokenId(emmaProStatus.productId)
                    : undefined,
                prorationModeAndroid: getAndroidProrationMode(subscription, emmaProStatus?.store === 'google' ? emmaProStatus : undefined),
                subscriptionOffers: (() => {
                    if (Platform.OS !== 'android') {
                        return undefined;
                    }
                    if (discount) {
                        return [
                            {
                                sku: subscription.id,
                                offerToken: discount.offer.offerToken,
                            },
                        ];
                    }
                    const offer = getBaseSubscriptionOfferAndroid(subscription);
                    if (!offer) {
                        return undefined;
                    }
                    return [
                        {
                            sku: subscription.id,
                            offerToken: offer.offerToken,
                        },
                    ];
                })(),
            });
        }
    }
    catch {
        // No op
    }
};
export const checkPersistedPromotedProduct = async () => {
    try {
        const productId = await RNIap.getPromotedProductIOS();
        if (productId !== null) {
            // You may want to validate the product ID against your own SKUs
            await RNIap.buyPromotedProductIOS(); // This will trigger the App Store purchase process
        }
    }
    catch {
        // No op
    }
};
const getPurchaseTokenId = async (productId) => (await getAvailablePurchases()).find((it) => it.productId === productId)
    ?.purchaseToken;
const { RNIapIos } = NativeModules;
export const IAPEmitter = new NativeEventEmitter(RNIapIos);
export const getIapSubscriptionPrices = (iapSubscription) => {
    if (!iapSubscription)
        return undefined;
    if (iapSubscription.platform === RNIap.SubscriptionPlatform.ios) {
        return iapSubscription;
    }
    const baseSubscriptionPrice = getBaseSubscriptionPriceAndroid(getBaseSubscriptionOfferAndroid(iapSubscription));
    if (!baseSubscriptionPrice) {
        return undefined;
    }
    return {
        ...iapSubscription,
        ...baseSubscriptionPrice,
    };
};
export async function checkGooglePlayServices() {
    const utils = firebaseUtils();
    const { isAvailable, hasResolution, isUserResolvableError } = utils.playServicesAvailability;
    // all good and valid \o/
    if (isAvailable)
        return Promise.resolve();
    // if the user can resolve the issue i.e by updating play services
    // then call Google Play's own/default prompting functionality
    if (isUserResolvableError) {
        return utils.promptForPlayServices();
    }
    // call Google Play's own/default resolution functionality
    if (hasResolution) {
        return utils.resolutionForPlayServices();
    }
    // There's no way to resolve play services on this device
    // probably best to show a dialog
    Modal.showError('Unable to resolve Google Play Services');
    return Promise.reject();
}
