import { useCallback } from 'react';
import useFetch from 'hooks/useFetch';
import { subMinutes, isAfter, differenceInSeconds } from 'date-fns';
import { useAppSelector } from 'store/hooks';
import { selectIsResettingPasscode, selectResetPasscodeNextStep, } from 'reducers/selectors';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { resetPasscode } from 'actions';
import { Amplitude } from 'utils';
const useForgotPasscode = (popOnComplete) => {
    const navigation = useMainStackNavigation();
    const resetPasscodeNextStep = useAppSelector(selectResetPasscodeNextStep);
    const isResettingPasscode = useAppSelector(selectIsResettingPasscode);
    const [, , fetchingForgotStep, fetchForgotPasscodeStep] = useFetch({ debug: false });
    const onForgotPasscode = useCallback(async () => {
        if (fetchingForgotStep) {
            return false;
        }
        if (isResettingPasscode && resetPasscodeNextStep === 'email_otp') {
            const now = new Date();
            const aMinuteAgo = subMinutes(now, 1);
            if (isAfter(isResettingPasscode, aMinuteAgo)) {
                const startAtSeconds = 60 - differenceInSeconds(now, isResettingPasscode);
                navigation.navigate('EmailVerification', {
                    flow: 'resetPin',
                    popOnComplete,
                    startAtSeconds,
                });
                return true;
            }
        }
        Amplitude.logEvent('LockScreen.openForgotPasscode');
        const response = await fetchForgotPasscodeStep(resetPasscode());
        switch (response?.nextStep) {
            case 'email_otp':
                navigation.navigate('EmailVerification', {
                    flow: 'resetPin',
                    popOnComplete,
                });
                break;
            case 'set_new_pin':
                navigation.navigate('SetupPasscode', {
                    keyBack: null,
                    popOnComplete,
                });
                break;
            default:
                navigation.navigate('Support', {});
        }
        return true;
    }, [
        fetchForgotPasscodeStep,
        fetchingForgotStep,
        isResettingPasscode,
        resetPasscodeNextStep,
        popOnComplete,
    ]);
    return { onForgotPasscode, resetPasscodeNextStep, fetchingForgotStep };
};
export default useForgotPasscode;
