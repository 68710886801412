import React, { memo, useEffect, useMemo, useRef } from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withRepeat, withSequence, withTiming, } from 'react-native-reanimated';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
const MarketActivePointer = ({ color, width, height, finalPoint, shouldDelay, }) => {
    const styles = useStyles(styleSet);
    const opacityControl = useSharedValue(shouldDelay ? 0 : 1);
    const animatedStyles = useAnimatedStyle(() => ({
        opacity: opacityControl.value === 1 ? 1 : 0,
        transform: [
            {
                translateX: finalPoint.x * width,
            },
            {
                translateY: finalPoint.y * height + 10,
            },
        ],
    }), [finalPoint.x, finalPoint.y, height, width, opacityControl]);
    const pulsatingAnimatedStyles = useAnimatedStyle(() => ({
        transform: [
            {
                scale: withRepeat(withSequence(withTiming(0.5, { duration: 600 }), withTiming(1, { duration: 600 })), -1),
            },
        ],
    }), []);
    useEffect(() => {
        if (shouldDelay) {
            opacityControl.value = withTiming(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<Animated.View style={[styles.outerDotAbs, animatedStyles]}>
      <Animated.View style={[
            styles.pulsatingView,
            { backgroundColor: color },
            pulsatingAnimatedStyles,
        ]}/>
      <View style={[styles.outerDot, { backgroundColor: color }]}>
        <View style={styles.innerDot}/>
      </View>
    </Animated.View>);
};
const getDelayRef = (finalPoint) => {
    if (finalPoint === undefined) {
        return undefined;
    }
    return true;
};
const MarketActionPointerWrapper = ({ color, width, height, finalPoint, }) => {
    const colorRef = useRef(color);
    const finalPointRef = useRef(finalPoint);
    const delayRef = useRef(getDelayRef(finalPoint));
    const computedColor = useMemo(() => {
        if (color === undefined) {
            return colorRef.current;
        }
        colorRef.current = color;
        return color;
    }, [color]);
    const computedFinalPoint = useMemo(() => {
        if (finalPoint === undefined) {
            return finalPointRef.current;
        }
        finalPointRef.current = finalPoint;
        return finalPoint;
    }, [finalPoint]);
    const shouldDelay = useMemo(() => {
        const oldRef = delayRef.current;
        delayRef.current = getDelayRef(finalPoint);
        if (oldRef === undefined) {
            return false;
        }
        return true;
    }, [finalPoint]);
    if (computedColor && computedFinalPoint) {
        return (<MarketActivePointer width={width} height={height} color={computedColor} shouldDelay={shouldDelay} finalPoint={computedFinalPoint}/>);
    }
    return null;
};
export default memo(MarketActionPointerWrapper);
const styleSet = createStyleSheets((colors) => ({
    outerDotAbs: {
        top: -rem(10),
        left: -rem(10),
        width: rem(20),
        height: rem(20),
        position: 'absolute',
        borderRadius: rem(10),
    },
    outerDot: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: rem(6),
        position: 'absolute',
        alignItems: 'center',
        borderRadius: rem(8),
        justifyContent: 'center',
    },
    innerDot: {
        width: rem(4),
        height: rem(4),
        borderRadius: rem(4),
        backgroundColor: colors.background.light,
    },
    pulsatingView: {
        opacity: 0.15,
        width: rem(20),
        height: rem(20),
        borderRadius: rem(10),
    },
}));
