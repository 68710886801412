import React, { useMemo } from 'react';
import { formatAmount } from 'utils/formatting';
import { useAppSelector } from 'store/hooks';
import BlurAmountView from './BlurAmountView';
import Text from './Text';
const Amount = ({ value, showSign, scramblable = true, showFraction = true, removeZero = false, ...textProps }) => {
    const scrambled = useAppSelector((store) => store.utils.scrambled);
    const amount = 'amount' in value ? value.amount : value.value;
    const formatted = useMemo(() => formatAmount(amount, value.currency || undefined, showFraction ? 2 : undefined, undefined, removeZero), [amount, value.currency, showFraction, removeZero]);
    return scramblable && scrambled ? (<BlurAmountView enabled>
      <Text {...textProps}>
        {showSign && amount > 0 ? '+' : null}
        {formatted}
      </Text>
    </BlurAmountView>) : (<Text {...textProps}>
      {showSign && amount > 0 ? '+' : null}
      {formatted}
    </Text>);
};
export default Amount;
