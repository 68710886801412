export const priorityItemTitleMap = {
    transactions: 'Transactions',
    analytics: 'Analytics & budgeting',
    subscriptions: 'Recurring payments',
    fraudDetection: 'Fraud detection',
    completeSetup: 'Complete setup',
};
export const tabPriorityItemTitleMap = {
    borrow: 'Borrow',
    save: 'Save',
    groups: 'Groups',
    invest: 'Invest',
    pay: 'Pay',
    upgrade: 'Upgrade',
};
export const defaultFeedPriorityArr = [
    'transactions',
    'analytics',
    'subscriptions',
    'fraudDetection',
    'completeSetup',
];
export const defaultTabPriority = {
    save: 1,
    pay: 2,
    groups: 3,
    invest: 4,
    borrow: 5,
    upgrade: 5,
};
