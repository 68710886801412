import { defaultFeedPriorityArr } from './constants';
export const getFinalOrganizeObj = (priorityData, removedData) => {
    const finalObj = {};
    priorityData.forEach((data, i) => {
        finalObj[data] = i + 1;
    });
    removedData.forEach((data) => {
        finalObj[data] = null;
    });
    return finalObj;
};
export const organizeFeed = (feedPersonalization, hasCompletedSetup) => {
    const finalArr = [];
    const removedArr = [];
    defaultFeedPriorityArr.forEach((content) => {
        if (content === 'completeSetup' && hasCompletedSetup) {
            return;
        }
        if (feedPersonalization[content] !== null) {
            finalArr.push(content);
        }
        else {
            removedArr.push(content);
        }
    });
    finalArr.sort((a, b) => {
        const orderOfA = feedPersonalization[a];
        const orderOfB = feedPersonalization[b];
        if (orderOfA === null || orderOfA === undefined) {
            return 1;
        }
        if (orderOfB === null || orderOfB === undefined) {
            return -1;
        }
        return orderOfA - orderOfB;
    });
    return {
        priority: finalArr,
        removed: removedArr,
    };
};
export const organizeTabs = (visibleTabs) => {
    const finalArr = [];
    const removedArr = [];
    Object.entries(visibleTabs).forEach(([tab, value]) => {
        if (value !== null) {
            finalArr.push(tab);
        }
        else {
            removedArr.push(tab);
        }
    });
    finalArr.sort((a, b) => {
        const orderOfA = visibleTabs[a];
        const orderOfB = visibleTabs[b];
        if (orderOfA === null || orderOfA === undefined) {
            return 1;
        }
        if (orderOfB === null || orderOfB === undefined) {
            return -1;
        }
        return orderOfA - orderOfB;
    });
    return {
        priority: finalArr,
        removed: removedArr,
    };
};
