import { selectListAccounts } from 'reducers/selectors';
import { createSelector } from 'reselect';
import { subMonths, isBefore } from 'date-fns';
const selectSearchTransactions = (store) => store.transactions.searchTransactions;
const selectTransactionsCategories = (store) => store.transactions.categories;
const selectTransactionsMerchants = (store) => store.transactions.merchants;
export const selectSearchTransactionsFormatted = createSelector([
    selectSearchTransactions,
    selectTransactionsCategories,
    selectTransactionsMerchants,
    selectListAccounts,
    (state, shouldLimitTo2Months) => shouldLimitTo2Months,
], (transactions, categories, merchants, accounts, shouldLimitTo2Months) => {
    const limitDate = subMonths(new Date(), 2);
    const accountsRecord = accounts.reduce((prev, curr) => ({
        ...prev,
        [curr.id]: curr,
    }), {});
    const formatTransactions = transactions.map((item) => {
        const transactionItem = { ...item };
        if (categories[item.categoryId]) {
            transactionItem.category = categories[item.categoryId];
        }
        if (item.merchantId && merchants[item.merchantId]) {
            transactionItem.merchant = merchants[item.merchantId];
        }
        if (accountsRecord[transactionItem.accountId]) {
            transactionItem.provider =
                accountsRecord[transactionItem.accountId].provider === 'MANUAL'
                    ? 'MANUAL'
                    : undefined;
        }
        const dateToUse = transactionItem.customDate || transactionItem.bookingDate;
        const showUpgradeButton = shouldLimitTo2Months && !isBefore(limitDate, dateToUse);
        if (showUpgradeButton) {
            transactionItem.blurValue = true;
        }
        return transactionItem;
    });
    return formatTransactions;
});
