import React from 'react';
import { SafeAreaView } from 'react-native';
import { Button } from 'design-system/Button';
const CreateSpacePageButton = ({ buttonTitle = 'Next', buttonVisible, buttonPending = false, onClick, style, }) => {
    const press = () => onClick?.();
    return buttonVisible ? (<SafeAreaView>
      <Button brand floating style={[style]} title={buttonTitle} onPress={press} isFetching={buttonPending}/>
    </SafeAreaView>) : null;
};
export default CreateSpacePageButton;
