const Share = {
    open: ({ message }) => {
        if (typeof window !== 'undefined' &&
            typeof window.navigator !== 'undefined' &&
            window.navigator.share) {
            return window.navigator.share({ text: message });
        }
        return Promise.reject(new Error('Web Share API is not supported in this browser.'));
    },
};
export default Share;
