import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { rem } from 'design-system';
import Button from 'design-system/Button';
import Text from 'design-system/Text';
import { IconGlobe } from 'design-system/icons';
import usePlaidLink from 'hooks/usePlaidLink';
import { selectIsGBUser } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import Amplitude from 'utils/amplitude';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import Card from '../Card';
import Section from '../Section';
import { isWeb } from '../../../../constants';
const NoBankConnections = () => {
    const navigation = useMainStackNavigation();
    const isGBUser = useAppSelector(selectIsGBUser);
    const openPlaid = usePlaidLink();
    const addBank = () => {
        if (!isGBUser) {
            openPlaid();
        }
        else
            navigation.navigate('ChooseBank', { back: true });
        Amplitude.logEvent('Feed.AddABank');
    };
    return (<Section>
      <Card style={styles.card}>
        <View style={styles.emptyIcon}>
          <IconGlobe />
        </View>
        <Text Text-16 centered style={styles.emptyTitle}>
          No accounts yet
        </Text>
        {!isWeb && (<>
            <Text TextThin-14 Gray centered>
              Add your account now
            </Text>
            <Button gray extra small title="Add" preventDoubleTap onPress={addBank} style={styles.emptySetupButton}/>
          </>)}
      </Card>
    </Section>);
};
const styles = StyleSheet.create({
    card: {
        marginHorizontal: rem(16),
        padding: rem(16),
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyTitle: {
        marginBottom: rem(4),
    },
    emptySetupButton: {
        marginTop: rem(16),
        alignSelf: 'center',
        minWidth: rem(129),
    },
    emptyIcon: {
        marginBottom: rem(16),
    },
});
export default memo(NoBankConnections);
