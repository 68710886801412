import React from 'react';
import { View, StyleSheet, LayoutAnimation } from 'react-native';
import { rem } from 'design-system/values';
import { CollapsableContainer } from 'design-system/CollapsableContainer';
import ListEmptyView from 'design-system/ListEmptyView';
import AccountItem from './AccountItem';
const SelectedAccountsBody = ({ accounts, onSelected }) => {
    const selected = (index, subIndex) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        onSelected?.(index, subIndex);
    };
    if (accounts.length === 0) {
        return (<ListEmptyView style={[styles.container, styles.emptyContainer]} compact title={"You don't have any accounts."}/>);
    }
    return (<View style={styles.content}>
      {accounts.map((item, index) => (<CollapsableContainer key={item.space.id || 'default'} title={item.space.isDefault ? 'Primary Space' : item.space.name} style={styles.itemContainer} collapsable={index > 0} isCollapsedByDefault={false}>
          {item.accounts
                .filter((it) => it.id !== undefined)
                .map((it, subindex) => (<AccountItem key={it.id} account={it} onPress={() => selected?.(index, subindex)}/>))}
        </CollapsableContainer>))}
    </View>);
};
const styles = StyleSheet.create({
    container: {
        marginHorizontal: rem(16),
        flex: 1,
    },
    content: {
        marginHorizontal: rem(16),
        flex: 1,
        paddingBottom: rem(108),
    },
    itemContainer: {
        marginBottom: rem(16),
    },
    emptyContainer: {
        flex: 1,
        justifyContent: 'center',
        padding: rem(24),
        borderRadius: rem(16),
    },
});
export default SelectedAccountsBody;
