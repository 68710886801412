import React from 'react';
import { StyleSheet, View } from 'react-native';
import { PrimaryAccountsBody } from '../components';
import { usePrimarySpaceAccounts } from '../hooks';
const PrimaryAccountsPage = ({ space, scrollHandler }) => {
    const accounts = usePrimarySpaceAccounts(space);
    return (<View style={styles.content}>
      <PrimaryAccountsBody accounts={accounts} scrollHandler={scrollHandler}/>
    </View>);
};
const styles = StyleSheet.create({
    content: {
        flex: 1,
    },
});
export default PrimaryAccountsPage;
