import React, { useCallback, useMemo } from 'react';
import { View, StyleSheet, TouchableOpacity, } from 'react-native';
import { format, eachDayOfInterval, startOfWeek, endOfWeek, isSameMonth, parseISO, } from 'date-fns';
import Text from 'design-system/Text';
import { rem, hitSlopS } from 'design-system';
import Spacer from 'design-system/Spacer';
import IconArrow from '../icons/IconArrow';
import useIsCompactCalendar from './useIsCompactCalendar';
export const CalendarHeader = ({ month, maxDate, addMonth, headerStyle, blackMonthText, hideDays = false, }) => {
    const previousMonth = useCallback(() => {
        addMonth?.(-1);
    }, [addMonth]);
    const nextMonth = useCallback(() => {
        addMonth?.(1);
    }, [addMonth]);
    const monthToDisplay = useMemo(() => format(month, 'MMMM yyyy'), [month]);
    const compact = useIsCompactCalendar();
    const daysOfTheWeekNodes = useMemo(() => {
        const weekArray = eachDayOfInterval({
            start: startOfWeek(new Date(), { weekStartsOn: 0 }),
            end: endOfWeek(new Date(), { weekStartsOn: 0 }),
        }).map((date) => format(date, 'EEE'));
        return weekArray.map((day) => (<View key={day} style={styles.dayOfTheWeekContainer}>
        <Text Text-16 Gray>
          {day}
        </Text>
      </View>));
    }, []);
    const reachedMaxDate = useMemo(() => (maxDate ? isSameMonth(parseISO(maxDate), month) : false), [maxDate, month]);
    return (<>
      <View style={[styles.headerStyle, compact && styles.compact, headerStyle]}>
        <TouchableOpacity onPress={previousMonth} hitSlop={hitSlopS} accessibilityHint="Previous Month">
          <IconArrow direction="left"/>
        </TouchableOpacity>
        <Text Text-16 {...(blackMonthText ? {} : { Gray: true })}>
          {monthToDisplay}
        </Text>
        <TouchableOpacity onPress={nextMonth} hitSlop={hitSlopS} disabled={reachedMaxDate} accessibilityHint="Next Month">
          <IconArrow direction="right" style={[reachedMaxDate && styles.disabled]}/>
        </TouchableOpacity>
      </View>
      {!hideDays && (<>
          <Spacer h={12}/>
          <View style={styles.daysOfTheWeekContainer}>
            {daysOfTheWeekNodes}
          </View>
        </>)}
    </>);
};
const styles = StyleSheet.create({
    disabled: {
        opacity: 0,
    },
    headerStyle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: rem(5),
        paddingHorizontal: rem(8),
        marginBottom: rem(24),
    },
    compact: {
        marginBottom: rem(8),
    },
    daysOfTheWeekContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: rem(16),
        paddingHorizontal: rem(4),
    },
    dayOfTheWeekContainer: {
        width: rem(38),
        alignItems: 'center',
    },
});
