import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import { format, getYear, parseISO } from 'date-fns';
import Text from 'design-system/Text';
import Amount from 'design-system/Amount';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import IconCheck from 'design-system/icons/IconCheck';
import BlurAmountView from 'design-system/BlurAmountView';
import useColors from 'hooks/useColors';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { isWeb } from '../../../constants';
import TransactionLogo from './TransactionLogo';
import EditTransactionSelect from './multiple/EditTransactionSelect';
export const ESTIMATED_ITEM_SIZE = 77;
const RowTransaction = ({ item, switchingButon, disabled, selected, onPress, style, btnStyles, backgroundDark, isEditingTransactions, isTransactionFeed, showUpgradeButton, showDate, isRecurring, categoryPosition = 'right', blurValue, isMultipleSelect, }) => {
    const colors = useColors();
    const onRowPressed = useCallback(() => {
        onPress?.(item);
    }, [item, onPress]);
    const renderSwitching = () => {
        // isTransactionFeed probably not needed can just not pass switching button in the feed
        if ((disabled && !isTransactionFeed) || isWeb)
            return null;
        return switchingButon;
    };
    const { counterpartName, customName, amount, nativeAmount, notes, currency } = item;
    const feeAmount = 'feeAmount' in item ? item.feeAmount : undefined;
    let fee = null;
    if (feeAmount && feeAmount !== amount)
        fee = Math.abs(feeAmount);
    let firstBalance = amount;
    let firstCurrency = currency;
    let secondBalance = null;
    let secondCurreny = null;
    if (nativeAmount) {
        firstBalance = nativeAmount.amount;
        firstCurrency = nativeAmount.currency;
        secondBalance = amount;
        secondCurreny = currency;
    }
    const date = useMemo(() => {
        if (showDate) {
            const dateToDisplay = item.customDate || item.bookingDate;
            const showYear = getYear(parseISO(dateToDisplay)) !== getYear(new Date());
            return format(parseISO(dateToDisplay), showYear ? 'd MMM yyyy' : 'd MMM');
        }
        return undefined;
    }, [showDate, item]);
    const { isUnlockedForSpace, onPressUpgrade } = useShouldShowUpgrade({
        // Note we are not checking rename transaction OR offlineAccounts, optimisation as they are currently on the same tier
        benefitId: 'split',
    });
    const nameToDisplay = isUnlockedForSpace
        ? customName || counterpartName
        : counterpartName;
    const hasUpgradeButton = showUpgradeButton &&
        !isUnlockedForSpace &&
        (('splitFromId' in item && item.splitFromId != null) ||
            ('provider' in item && item.provider === 'MANUAL'));
    return (<TouchableHighlight onCard hoverOnLight style={btnStyles} activeOpacity={1} disabled={disabled} onPress={onRowPressed} underlayColor={colors.background.underlay}>
      <View style={[styles.rowAccount, styles.webPadding, style]}>
        {item.isPending || !isEditingTransactions ? null : (<EditTransactionSelect selected={selected} isMultipleSelect={isMultipleSelect}/>)}
        <View style={styles.rowInner}>
          <View style={styles.imageContainer}>
            <TransactionLogo R44 transaction={item} backgroundDark={backgroundDark}/>
          </View>
          <BlurAmountView enabled={blurValue} containerStyle={styles.textContainer}>
            <Text Text-16 numberOfLines={1} ellipsizeMode="tail">
              {nameToDisplay}
            </Text>
            {Boolean(notes) && (<Text TextThin-14 Gray numberOfLines={1} ellipsizeMode="tail">
                {notes}
              </Text>)}
            {Boolean(fee) && (<Text TextThin-14 Gray numberOfLines={1} ellipsizeMode="tail">
                Fees charged {getSymbolFromCurrency(firstCurrency)}
                {fee}
              </Text>)}
            {Boolean(date) && (<Text TextThin-14 Gray numberOfLines={1} ellipsizeMode="tail">
                {date}
              </Text>)}
            {Boolean(categoryPosition === 'left' && item.category?.displayName) && (<Text TextThin-14 Gray numberOfLines={1} ellipsizeMode="tail">
                {item.category.displayName}
              </Text>)}
            {renderSwitching()}
            {Boolean(isRecurring) && (<View style={styles.trackingContainer}>
                <Squircle width={rem(16)} height={rem(16)} color={colors.elements.positive}>
                  <Spacer h={2}/>
                  <IconCheck thick width={12} color={colors.elements.onLight}/>
                </Squircle>
                <Spacer w={8}/>
                <Text TextThin-14 Gray numberOfLines={1}>
                  Tracking
                </Text>
              </View>)}
          </BlurAmountView>
        </View>
        {hasUpgradeButton ? (<View style={styles.numberContainer}>
            <Button square brandReversed small title="Upgrade" onPress={onPressUpgrade}/>
          </View>) : (<BlurAmountView enabled={blurValue} containerStyle={styles.numberContainer}>
            <View style={styles.numberContainer}>
              {firstBalance > 0 ? (<Amount Blue Numbers-16 value={{ value: firstBalance, currency: firstCurrency }} showSign/>) : (<Amount OffBlack Numbers-16 value={{ value: firstBalance, currency: firstCurrency }} showSign/>)}
              {Boolean(nativeAmount) &&
                secondBalance !== null &&
                !isTransactionFeed && (<Amount Gray NumbersThin-14 value={{ value: secondBalance, currency: secondCurreny }} showSign/>)}
              {Boolean(categoryPosition === 'right' && item.category?.displayName) && (<Text TextThin-14 Gray numberOfLines={1} ellipsizeMode="tail" style={styles.categoryDisplayName}>
                  {item.category.displayName}
                </Text>)}
            </View>
          </BlurAmountView>)}
      </View>
    </TouchableHighlight>);
};
const styles = StyleSheet.create({
    imageContainer: {
        justifyContent: 'center',
    },
    rowAccount: {
        flexDirection: 'row',
        padding: rem(16),
        ...(isWeb && {
            paddingVertical: rem(16),
            paddingHorizontal: rem(8),
        }),
    },
    webPadding: {
        ...(isWeb && {
            paddingVertical: rem(16),
            paddingHorizontal: rem(8),
        }),
    },
    rowInner: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
    },
    textContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: rem(8),
        marginLeft: rem(8),
        paddingRight: rem(7),
        marginRight: rem(7),
        flex: 1,
    },
    numberContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    trackingContainer: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    categoryDisplayName: {
        maxWidth: rem(isWeb ? 150 : 100),
    },
});
export default RowTransaction;
