import React, { useMemo } from 'react';
import { Platform, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useHeaderHeight } from 'hooks';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../constants';
export const AppView = ({ testID, style, isInTab, type = 'secondary', avoidHeader, paddingBottom, children, pointerEvents, withFrame = true, onLayout, }) => {
    const styles = useStyles(styleSet);
    const headerHeight = useHeaderHeight();
    const paddingBottomStyle = useMarginBottom('paddingBottom');
    const { paddingHorizontalStyle } = useAppFrameDimensions(isInTab || isWeb);
    const viewStyles = useMemo(() => [
        styles.view,
        type === 'primary' && styles.primary,
        type === 'secondary' && styles.secondary,
        type === 'brand' && styles.brand,
        avoidHeader && { paddingTop: headerHeight },
        paddingBottom && paddingBottomStyle,
        withFrame && { ...styles.static, ...paddingHorizontalStyle },
        style,
    ], [
        avoidHeader,
        headerHeight,
        paddingBottom,
        paddingBottomStyle,
        paddingHorizontalStyle,
        style,
        styles.brand,
        styles.primary,
        styles.secondary,
        styles.static,
        styles.view,
        type,
        withFrame,
    ]);
    const colors = useColors();
    if (type === 'gradient') {
        return (<LinearGradient testID={testID} style={[
                styles.view,
                style,
                withFrame && { ...styles.static, ...paddingHorizontalStyle },
                paddingBottom && paddingBottomStyle,
            ]} {...colors.gradients.brandGradient} onLayout={onLayout}>
        {children}
      </LinearGradient>);
    }
    return (<View testID={testID} style={viewStyles} pointerEvents={pointerEvents} onLayout={onLayout}>
      {children}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    static: {
        overflow: Platform.select({
            android: 'visible',
            default: 'hidden',
        }),
    },
    view: {
        flex: 1,
    },
    secondary: {
        backgroundColor: colors.background.dark,
    },
    primary: {
        backgroundColor: colors.background.light,
    },
    brand: {
        backgroundColor: colors.background.brand,
    },
}));
