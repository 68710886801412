import { useCallback, useEffect, useMemo, useRef } from 'react';
import { mapToCommonErrorAction } from 'hooks';
import { useFetch } from 'hooks/fetch';
import { forceRefreshAnalytics, getBankConnections, getCategories, getFeed, } from 'actions';
import sortAccounts from 'utils/api/sortAccounts';
import { noop } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllMyAccountsFromConnections, selectUserId, } from 'reducers/selectors';
import { createNewSpace, deleteSpace, deleteSpaceMember, fetchSpacesAction, getSpaceInfo, getSpaceInfoSuccess, joinSpace, setAccountSpace, } from '../actions';
import { CREATE_SPACE_SUCCESS } from '../constants';
export const usePrimarySpaceAccounts = (space) => {
    const accountsFromConnections = useAppSelector(selectAllMyAccountsFromConnections);
    const accounts = useMemo(() => sortAccounts(accountsFromConnections), [accountsFromConnections]);
    return useMemo(() => accounts.filter(({ spaceId }) => spaceId === space?.id), [accounts, space]);
};
export const useSpaceInfo = (id) => {
    const spaceInfo = useAppSelector((store) => id ? store.spaces.spaceInfo[id] : undefined);
    const [fetch, busy] = useFetch();
    const dispatch = useAppDispatch();
    const spaceInfoFetch = useCallback(() => {
        if (!busy && id !== undefined && !spaceInfo?.space.isDefault) {
            fetch(getSpaceInfo(id))
                .then((result) => {
                dispatch(getSpaceInfoSuccess(result));
            })
                .catch(() => { });
        }
    }, [busy, fetch, id, spaceInfo]);
    // Call on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => spaceInfoFetch(), []);
    return [spaceInfo, busy, spaceInfoFetch];
};
export const useDeleteSpace = (id, onRemoved) => {
    const [fetch, busy] = useFetch();
    const dispatch = useAppDispatch();
    const remove = useCallback(() => {
        if (!busy) {
            fetch(deleteSpace(id))
                .then(() => {
                dispatch(fetchSpacesAction());
                onRemoved();
            })
                .catch(noop);
        }
    }, [busy, fetch, id, onRemoved]);
    return [busy, remove];
};
export const useSetAccountsSpace = (currentSpace, newSpaces, onPatched) => {
    const mounted = useRef(true);
    const myId = useAppSelector(selectUserId);
    const [createSpace, createSpaceBusy] = useFetch();
    const [patch, patching] = useFetch();
    const dispatch = useAppDispatch();
    const setSpace = useCallback(async () => {
        if (mounted.current &&
            !patching &&
            !createSpaceBusy &&
            currentSpace &&
            newSpaces) {
            const { id: currentId, name, type } = currentSpace;
            const defaultId = newSpaces.map(({ space }) => space).find(({ isDefault }) => isDefault)
                ?.id ?? -1;
            let actualSpace = currentSpace;
            if (currentId < 0) {
                try {
                    actualSpace = (await createSpace(createNewSpace(name, type))).space;
                    dispatch({ type: CREATE_SPACE_SUCCESS });
                    dispatch(fetchSpacesAction());
                }
                catch {
                    // No op
                    return;
                }
            }
            const callsToRun = [];
            newSpaces.forEach(({ space, accounts }) => {
                if (space.id === currentId) {
                    callsToRun.push(...accounts
                        .filter(({ userId }) => userId === myId)
                        .filter(({ spaceId }) => spaceId && spaceId !== currentId)
                        .map(({ id }) => patch(setAccountSpace(id, actualSpace.id))));
                }
                else {
                    callsToRun.push(...accounts
                        .filter(({ userId }) => userId === myId)
                        .filter(({ spaceId }) => spaceId && spaceId === currentId)
                        .map(({ id }) => patch(setAccountSpace(id, defaultId))));
                }
            });
            if (callsToRun.length && mounted.current) {
                try {
                    await Promise.all(callsToRun);
                }
                catch {
                    // No op
                }
                if (mounted.current)
                    onPatched?.(actualSpace);
                dispatch(fetchSpacesAction());
                dispatch(getBankConnections());
                dispatch(forceRefreshAnalytics());
                dispatch(getCategories());
                dispatch(getFeed());
            }
            else {
                onPatched?.(actualSpace);
            }
        }
    }, [
        patching,
        createSpaceBusy,
        currentSpace,
        newSpaces,
        createSpace,
        myId,
        patch,
        onPatched,
        dispatch,
    ]);
    useEffect(() => () => {
        mounted.current = false;
    }, []);
    return [patching || createSpaceBusy, setSpace];
};
export const useJoinSpace = (accessCode, onSuccess, onError) => {
    const [fetch, busy] = useFetch({ muteAlert: true });
    const dispatch = useAppDispatch();
    const join = useCallback(() => {
        if (!busy) {
            fetch(joinSpace(accessCode))
                .then((it) => {
                dispatch(fetchSpacesAction());
                onSuccess(it.space);
            })
                .catch((error) => {
                dispatch(mapToCommonErrorAction(error, error.payload?.status === 404
                    ? 'This space was deleted'
                    : undefined));
                onError();
            });
        }
    }, [accessCode, busy, onError, onSuccess]);
    return [busy, join];
};
export const useDeleteSpaceMember = (id, onSuccess) => {
    const [fetch, busy] = useFetch();
    const remove = useCallback((userId) => {
        if (!busy) {
            fetch(deleteSpaceMember(id, userId))
                .then(() => onSuccess())
                .catch(noop);
        }
    }, [busy, fetch, id, onSuccess]);
    return [remove, busy];
};
