import React, { useCallback, useEffect } from 'react';
import Modal from 'design-system/Modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedSpace } from '../actions';
import UltimateExpiredModalContent from '../components/UltimateExpiredModalContent';
import { selectSelectedSpaceData, selectSpaces } from '../reducers/selectors';
const useUltimateExpiredModal = () => {
    const show = useCallback((space, onDidHideCallback) => {
        Modal.show(<UltimateExpiredModalContent space={space} onButtonPress={onDidHideCallback}/>, {
            nonDismissable: true,
            title: 'Emma Ultimate has expired',
            onDidHideCallback,
        });
    }, []);
    const showSpaceNotPremiumError = useAppSelector((store) => store.spaces.showSpaceNotPremiumError);
    const selectedSpace = useAppSelector(selectSelectedSpaceData);
    const spaces = useAppSelector(selectSpaces);
    const dispatch = useAppDispatch();
    useEffect(() => {
        // This handles any 409 responses from the API
        if (showSpaceNotPremiumError && selectedSpace && !selectedSpace.isDefault) {
            show(selectedSpace, () => {
                const defaultSpace = spaces.find(({ isDefault }) => isDefault);
                if (defaultSpace) {
                    dispatch(setSelectedSpace(defaultSpace.id));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, showSpaceNotPremiumError, selectedSpace]);
    return show;
};
export default useUltimateExpiredModal;
