import React, { memo, useMemo } from 'react';
import Animated, { Extrapolation, interpolate, useAnimatedProps, useAnimatedStyle, } from 'react-native-reanimated';
import { View, Text } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import { rem } from 'design-system/values';
import { Spacer } from 'design-system/Spacer';
import { styles as textStyles } from 'design-system/Text';
import ToolTip from 'design-system/ToolTip';
import { AnimatedTextInput } from 'design-system/AnimatedComponents';
import useStyles from 'hooks/useStyles';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAppSelector } from 'store/hooks';
import * as selectors from 'reducers/selectors';
import createStyleSheets from 'utils/createStyleSheets';
import { formatAmount, formatAmountWorklet } from 'utils/formatting';
import { ChartType } from 'features/accounts/types';
import BlurAmountView from 'design-system/BlurAmountView';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { selectTotalAssetsBalance, selectTotalDebtsBalance, } from 'features/accounts/selectors';
import { useBalances } from '../../hooks/useBalances';
import { isWeb } from '../../../../constants';
const CONFIG = {
    extrapolateLeft: Extrapolation.CLAMP,
    extrapolateRight: Extrapolation.CLAMP,
};
const AccountsOverviewTitle = ({ title, scrollX, chartType, selectedInfoObj, selectedInfoObjState, }) => {
    const styles = useStyles(styleSet);
    const feed = useAppSelector(selectors.selectFeed);
    const scrambled = useAppSelector(selectors.selectScrambled);
    const zero = useAppSelector(selectors.selectDefaultZeroAmount);
    const statusMessage = useAppSelector(selectors.selectStatusMessage);
    const totalDebtBalance = useAppSelector(selectTotalDebtsBalance);
    const totalAssetsBalance = useAppSelector(selectTotalAssetsBalance);
    const balances = useBalances(feed.cards.overview);
    const fallbackItem = useMemo(() => {
        switch (title) {
            case 'Everyday':
            case 'Savings':
            case 'Investments': {
                return balances.find((item) => item.title === title) || zero;
            }
            case 'Net Worth - Main': {
                return balances.find((item) => item.title === 'Net worth') || zero;
            }
            case 'Net Worth - Debt': {
                return { ...totalDebtBalance, amount: totalDebtBalance.amount * -1 };
            }
            case 'Net Worth - Asset': {
                return totalAssetsBalance;
            }
            default: {
                return zero;
            }
        }
    }, [balances, title, totalAssetsBalance, totalDebtBalance, zero]);
    const { width } = useAppFrameDimensions();
    const opacity = useAnimatedStyle(() => {
        const mod = (scrollX?.value || 0) % width;
        const inputOffsets = [0, width / 3, (width / 3) * 2, width];
        return {
            transform: [
                {
                    scale: interpolate(mod, inputOffsets, [1, 0.95, 0.95, 1], CONFIG),
                },
            ],
            opacity: interpolate(mod, inputOffsets, [1, 0, 0, 1], CONFIG),
        };
    }, [scrollX, width]);
    const currencyStr = useMemo(() => selectedInfoObj.value?.currency || fallbackItem.currency, [fallbackItem.currency, selectedInfoObj.value?.currency]);
    const currency = useMemo(() => getSymbolFromCurrency(currencyStr) || currencyStr, [currencyStr]);
    const titleProps = useAnimatedProps(() => ({
        text: selectedInfoObj.value && chartType !== ChartType.assetVsDebt
            ? formatAmountWorklet(selectedInfoObj.value.actualY, currency)
            : formatAmountWorklet(fallbackItem.amount, currency),
    }), [selectedInfoObj, chartType, currency, fallbackItem.amount]);
    const subtitleProps = useAnimatedProps(() => ({
        text: selectedInfoObj.value
            ? String(selectedInfoObj.value.label)
            : statusMessage || '',
    }), [selectedInfoObj, statusMessage]);
    const { isUnlockedForSpace } = useShouldShowUpgrade({
        benefitId: 'netWorth',
    });
    return (<Animated.View style={[opacity, styles.container]}>
      <View style={styles.balanceContainer}>
        <BlurAmountView enabled={scrambled ||
            (!isUnlockedForSpace &&
                (title === 'Net Worth - Main' ||
                    title === 'Net Worth - Debt' ||
                    title === 'Net Worth - Asset'))}>
          {isWeb ? (<Text numberOfLines={1} allowFontScaling={false} style={styles.balanceTextStyle}>
              {selectedInfoObjState && chartType !== ChartType.assetVsDebt
                ? formatAmount(selectedInfoObjState.actualY, currencyStr, 2, scrambled)
                : formatAmount(fallbackItem?.amount || 0, currencyStr, 2, scrambled)}
            </Text>) : (<AnimatedTextInput editable={false} numberOfLines={1} allowFontScaling={false} animatedProps={titleProps} style={styles.balanceTextStyle} defaultValue={formatAmount(fallbackItem?.amount, currencyStr, 2)}/>)}
        </BlurAmountView>
        <>
          <Spacer w={6}/>
          {title === 'Everyday' && (<ToolTip title="Everyday balance" subtitle="This is the total of all your available balances minus the total of all your credit card balances. It can help you understand what your purchasing power is. You can see how this value changes over time in the graph." colorKey="primary" small/>)}
        </>
      </View>
      {isWeb ? (<Text numberOfLines={1} allowFontScaling={false} style={styles.subtitleStyle}>
          {selectedInfoObjState
                ? String(selectedInfoObjState.label)
                : statusMessage || ''}
        </Text>) : (<AnimatedTextInput editable={false} numberOfLines={1} allowFontScaling={false} style={styles.subtitleStyle} animatedProps={subtitleProps} defaultValue={statusMessage || ''}/>)}
    </Animated.View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        marginTop: -rem(4),
        alignItems: 'center',
        justifyContent: 'center',
    },
    balanceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    balanceTextStyle: {
        margin: 0,
        padding: 0,
        textAlign: 'center',
        color: colors.text.primary,
        fontSize: textStyles.Numbers_18.fontSize,
        fontFamily: textStyles.Numbers_18.fontFamily,
    },
    subtitleStyle: {
        margin: 0,
        padding: 0,
        textAlign: 'center',
        color: colors.text.primary,
        fontSize: textStyles.TextThin_14.fontSize,
        fontFamily: textStyles.TextThin_14.fontFamily,
    },
}));
export default memo(AccountsOverviewTitle);
