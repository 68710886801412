import React from 'react';
import { StyleSheet, View } from 'react-native';
import useColors from 'hooks/useColors';
import { rem } from './values';
const BlurAmountView = ({ enabled, children, containerStyle, }) => {
    const colors = useColors();
    return (<View style={containerStyle}>
      <View style={enabled && styles.hide}>{children}</View>
      {enabled && (<View style={[
                StyleSheet.absoluteFill,
                {
                    backgroundColor: colors.cards.transparentOnDark,
                    borderRadius: rem(8),
                },
            ]}/>)}
    </View>);
};
const styles = StyleSheet.create({
    hide: {
        opacity: 0,
    },
});
export default BlurAmountView;
