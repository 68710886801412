import React from 'react';
import { StyleSheet } from 'react-native';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import { rem } from './values';
import { isWeb } from '../constants';
const NavigationHeaderRightIconButton = ({ children, onPress, style, }) => (<TouchableOpacity hoverOnDark onPress={onPress} style={[styles.headerRight, style]} testID="manage-button">
    {children}
  </TouchableOpacity>);
const styles = StyleSheet.create({
    headerRight: {
        justifyContent: 'center',
        ...(isWeb
            ? {
                width: rem(40),
                height: rem(40),
                borderRadius: rem(8),
                alignItems: 'center',
                marginRight: rem(16),
            }
            : {
                height: rem(32),
                paddingLeft: rem(8),
            }),
    },
});
export default NavigationHeaderRightIconButton;
