import React, { memo, useCallback } from 'react';
import { View } from 'react-native';
import { CardView } from 'design-system/CardView';
import Spacer from 'design-system/Spacer';
import Text from 'design-system/Text';
import IconArrowRight from 'design-system/icons/IconArrow';
import { rem } from 'design-system/values';
import BlurAmountView from 'design-system/BlurAmountView';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { selectHasSetBudget, selectScrambled } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { formatAmount } from 'utils/formatting';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { selectTrueBalance } from 'features/accounts/selectors';
import { isAndroid, isWeb } from '../../../../constants';
import AccountSavingsHeader from './AccountSavingsHeader';
const AccountsHeader = ({ data }) => {
    const colors = useColors();
    const scrambled = useAppSelector(selectScrambled);
    const didViewTrueBalanceIntro = useAppSelector((state) => state.deviceSettings.didViewTrueBalanceIntro);
    const navigation = useMainStackNavigation();
    const hasSetBudget = useAppSelector(selectHasSetBudget);
    const trueBalance = useAppSelector((store) => selectTrueBalance(store, data.id));
    const { isUnlockedForSpace: isTrueBalanceUnlocked } = useShouldShowUpgrade({
        benefitId: 'trueBalance',
    });
    const onTrueBalanceRowPress = useCallback(() => {
        if (!isWeb && !didViewTrueBalanceIntro) {
            navigation.navigate('TrueBalanceIntro', {
                id: data.id,
            });
            return;
        }
        navigation.navigate('TrueBalance', { id: data.id });
    }, [data.id, didViewTrueBalanceIntro]);
    const renderBalance = () => {
        const { nativeBalance } = data;
        const mainCurrency = nativeBalance ? nativeBalance.currency : data.currency;
        let mainBalance = data.availableBalance !== null
            ? data.availableBalance
            : data.postedBalance;
        if (nativeBalance)
            mainBalance =
                nativeBalance.availableBalance !== null
                    ? nativeBalance.availableBalance
                    : nativeBalance.postedBalance;
        let creditLimit = 0;
        let availableBalance = 0;
        if (data.type === 'CREDITCARD') {
            mainBalance = nativeBalance
                ? nativeBalance.creditCardBalance
                : data.creditCardBalance;
            creditLimit = nativeBalance
                ? nativeBalance.creditLimit
                : data.creditLimit;
            availableBalance = nativeBalance
                ? nativeBalance.availableBalance
                : data.availableBalance;
        }
        if (data.type === 'LOAN') {
            mainBalance = nativeBalance
                ? nativeBalance.postedBalance
                : data.postedBalance;
            creditLimit = nativeBalance
                ? nativeBalance.creditLimit
                : data.creditLimit;
            availableBalance = nativeBalance
                ? nativeBalance.availableBalance
                : data.availableBalance;
        }
        let overdraftLimit = null;
        let balanceText = data.type === 'PENSION' ? 'Balance ' : 'Available ';
        if (data.type === 'CHECKING' && data.overdraftLimit) {
            overdraftLimit = nativeBalance
                ? nativeBalance.overdraftLimit
                : data.overdraftLimit;
            balanceText = 'Overdraft ';
        }
        if (data.type === 'CREDITCARD' || data.type === 'LOAN') {
            return (<View style={styles.mainWrapper}>
          <View style={styles.creditWrap}>
            <Spacer h={16}/>
            <View style={styles.creditBalance}>
              <BlurAmountView enabled={scrambled}>
                <Text Numbers-48 numberOfLines={1}>
                  {formatAmount(mainBalance, mainCurrency, 2)}
                </Text>
              </BlurAmountView>
            </View>
            {(!!availableBalance || !!creditLimit) && (<View style={styles.creditSubtitle}>
                {!!availableBalance && (<>
                    <Text TextThin-14 Secondary>
                      Available{' '}
                    </Text>
                    <BlurAmountView enabled={scrambled}>
                      <Text NumbersThin-14 Secondary>
                        {formatAmount(availableBalance, mainCurrency, 2)}
                      </Text>
                    </BlurAmountView>
                  </>)}
                {!!availableBalance && !!creditLimit && (<Text TextThin-14> </Text>)}
                {!!creditLimit && (<>
                    <Text TextThin-14 Secondary>
                      Limit{' '}
                    </Text>
                    <BlurAmountView enabled={scrambled}>
                      <Text NumbersThin-14 Secondary>
                        {formatAmount(creditLimit, mainCurrency, 2)}
                      </Text>
                    </BlurAmountView>
                  </>)}
              </View>)}
          </View>
        </View>);
        }
        if (data.type === 'SAVINGS') {
            return (<View style={styles.mainWrapper}>
          <AccountSavingsHeader mainBalance={mainBalance} mainCurrency={mainCurrency} account={data} balanceText={balanceText}/>
        </View>);
        }
        if (data.type === 'CHECKING') {
            const showTrueBalance = Boolean(hasSetBudget && isTrueBalanceUnlocked);
            return (<CardView noMarginBottom>
          <View style={styles.checkingBalanceContainer}>
            <BlurAmountView enabled={scrambled}>
              <Text Numbers-36 numberOfLines={1}>
                {formatAmount(mainBalance, mainCurrency, 2)}
              </Text>
            </BlurAmountView>
            {overdraftLimit ? (<View style={styles.mainWrapper}>
                <Text TextThin-14 Secondary>
                  Overdraft{' '}
                </Text>
                <BlurAmountView enabled={scrambled}>
                  <Text NumbersThin-14 Secondary>
                    {formatAmount(overdraftLimit, mainCurrency, 2)}
                  </Text>
                </BlurAmountView>
              </View>) : null}
          </View>
          {showTrueBalance && (<View style={styles.trueBalanceWrapper}>
              <TouchableHighlight onCard hoverOnLight onPress={onTrueBalanceRowPress} underlayColor={colors.background.underlay}>
                <View style={styles.trueBalanceRow}>
                  <View style={styles.trueBalanceContainer}>
                    <Text Text-16>True balance</Text>
                    <Spacer w={9}/>
                    <IconArrowRight width={7} height={6}/>
                  </View>
                  <BlurAmountView enabled={scrambled}>
                    <Text Numbers-16>
                      {formatAmount((mainBalance || 0) - trueBalance, mainCurrency, 2)}
                    </Text>
                  </BlurAmountView>
                </View>
              </TouchableHighlight>
            </View>)}
        </CardView>);
        }
        return (<View style={styles.mainWrapper}>
        <View style={styles.mainBalanceWrap}>
          <Spacer h={32}/>
          <BlurAmountView enabled={scrambled}>
            <Text Numbers-48 numberOfLines={1}>
              {formatAmount(mainBalance, mainCurrency, 2)}
            </Text>
          </BlurAmountView>
          <Text TextThin-14 Secondary>
            {balanceText}
          </Text>
          {overdraftLimit ? (<View style={styles.mainWrapper}>
              <Text TextThin-14 Secondary>
                Overdraft{' '}
              </Text>
              <BlurAmountView enabled={scrambled}>
                <Text NumbersThin-14 Secondary>
                  {formatAmount(overdraftLimit, mainCurrency, 2)}
                </Text>
              </BlurAmountView>
            </View>) : null}
          <Spacer h={32}/>
        </View>
      </View>);
    };
    const styles = useStyles(styleSet);
    return <View style={styles.container}>{renderBalance()}</View>;
};
export const ACCOUNTS_HEADER_BOTTOM_HEIGHT = rem(16);
const styleSet = createStyleSheets((colors) => ({
    checkingBalanceContainer: {
        padding: rem(16),
        alignItems: 'center',
        justifyContent: 'center',
    },
    trueBalanceWrapper: {
        padding: rem(isWeb ? 8 : 0),
        borderTopWidth: rem(1),
        borderTopColor: colors.borders.divider,
    },
    trueBalanceRow: {
        ...(isWeb
            ? {
                paddingVertical: rem(16),
                paddingHorizontal: rem(8),
            }
            : {
                padding: rem(16),
                borderBottomLeftRadius: rem(16),
                borderBottomRightRadius: rem(16),
            }),
        flexDirection: 'row',
    },
    trueBalanceContainer: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
    },
    container: {
        ...(!isAndroid && {
            overflow: 'hidden',
        }),
    },
    mainBalanceWrap: {
        flex: 1,
        alignItems: 'center',
        alignSelf: 'center',
    },
    mainWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    creditWrap: {
        alignItems: 'center',
        alignSelf: 'center',
    },
    creditBalance: {
        alignItems: 'center',
    },
    creditSubtitle: {
        flexDirection: 'row',
        marginTop: 2,
        paddingBottom: rem(13.5),
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
export default memo(AccountsHeader);
