import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { rem } from '../values';
import { IconWithGradient } from './Icon';
const IconMagicPen = ({ size = rem(24), color, ...props }) => {
    const id = useId();
    const colors = useColors();
    if (color) {
        return (<IconWithGradient id={id} width={size} height={size} viewBox="0 0 24 24" {...props} color={color} defaultGradientKey="ultimate">
        <Path fill={color} d="m20 7.499-1.49 1.49-3-3L17 4.499c.42-.42.96-.62 1.5-.62s1.08.2 1.5.62c.83.83.83 2.17 0 3ZM17.81 9.7 7 20.5c-.83.83-2.17.83-3 0-.83-.83-.83-2.17 0-3L14.81 6.7l3 3ZM10.45 3.5l.41-1.39c.04-.13 0-.27-.09-.37-.09-.1-.25-.14-.38-.1L9 2.05l-1.39-.41c-.13-.04-.27 0-.37.09-.1.1-.13.24-.09.37l.4 1.4-.41 1.39c-.04.13 0 .27.09.37.1.1.24.13.37.09l1.4-.4 1.39.41c.04.01.07.02.11.02.1 0 .19-.04.27-.11.1-.1.13-.24.09-.37l-.41-1.4Z"/>
        <Path fill={color} d="m6.45 9.5.41-1.39c.04-.13 0-.27-.09-.37-.1-.1-.24-.13-.37-.09l-1.4.4-1.39-.41c-.13-.04-.27 0-.37.09-.1.1-.13.24-.09.37l.4 1.4-.41 1.39c-.04.13 0 .27.09.37.1.1.24.13.37.09l1.39-.41 1.39.41c.03.01.07.01.11.01.1 0 .19-.04.27-.11.1-.1.13-.24.09-.37l-.4-1.38ZM21.45 14.5l.41-1.39c.04-.13 0-.27-.09-.37-.1-.1-.24-.13-.37-.09l-1.39.41-1.39-.41c-.13-.04-.27 0-.37.09-.1.1-.13.24-.09.37l.41 1.39-.41 1.39c-.04.13 0 .27.09.37.1.1.24.13.37.09l1.39-.41 1.39.41c.03.01.07.01.11.01.1 0 .19-.04.27-.11.1-.1.13-.24.09-.37l-.42-1.38Z"/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} width={size} height={size} viewBox="0 0 24 24" {...props} defaultGradientKey="ultimate">
      <Path fill="#962DFF" d="m20 7.499-1.49 1.49-3-3L17 4.499c.42-.42.96-.62 1.5-.62s1.08.2 1.5.62c.83.83.83 2.17 0 3ZM17.81 9.7 7 20.5c-.83.83-2.17.83-3 0-.83-.83-.83-2.17 0-3L14.81 6.7l3 3ZM10.45 3.5l.41-1.39c.04-.13 0-.27-.09-.37-.09-.1-.25-.14-.38-.1L9 2.05l-1.39-.41c-.13-.04-.27 0-.37.09-.1.1-.13.24-.09.37l.4 1.4-.41 1.39c-.04.13 0 .27.09.37.1.1.24.13.37.09l1.4-.4 1.39.41c.04.01.07.02.11.02.1 0 .19-.04.27-.11.1-.1.13-.24.09-.37l-.41-1.4Z"/>
      <Path fill={colors.elements.secondary} d="m6.45 9.5.41-1.39c.04-.13 0-.27-.09-.37-.1-.1-.24-.13-.37-.09l-1.4.4-1.39-.41c-.13-.04-.27 0-.37.09-.1.1-.13.24-.09.37l.4 1.4-.41 1.39c-.04.13 0 .27.09.37.1.1.24.13.37.09l1.39-.41 1.39.41c.03.01.07.01.11.01.1 0 .19-.04.27-.11.1-.1.13-.24.09-.37l-.4-1.38ZM21.45 14.5l.41-1.39c.04-.13 0-.27-.09-.37-.1-.1-.24-.13-.37-.09l-1.39.41-1.39-.41c-.13-.04-.27 0-.37.09-.1.1-.13.24-.09.37l.41 1.39-.41 1.39c-.04.13 0 .27.09.37.1.1.24.13.37.09l1.39-.41 1.39.41c.03.01.07.01.11.01.1 0 .19-.04.27-.11.1-.1.13-.24.09-.37l-.42-1.38Z"/>
    </IconWithGradient>);
};
export default IconMagicPen;
