import React, { useLayoutEffect } from 'react';
import StatusBar from 'design-system/StatusBar';
import useColors from 'hooks/useColors';
import ReanimatedStackHeader from 'design-system/hoc/ReanimatedStackHeader';
import { useSharedValue } from 'react-native-reanimated';
import { SelectAccountsPage } from './containers';
import { isWeb } from '../../constants';
const CreateSpaceAccounts = ({ route, navigation, }) => {
    const colors = useColors();
    const scrollY = useSharedValue(0);
    useLayoutEffect(() => {
        navigation.setOptions({
            title: isWeb ? 'Create space accounts' : undefined,
            headerTitle: () => (<ReanimatedStackHeader title="Add Accounts" scrollY={scrollY}/>),
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary, scrollY]);
    const spaceInfo = route.params?.spaceInfo;
    const onNext = (space) => {
        navigation.navigate('CreateSpaceFinished', { space });
    };
    return (<>
      <StatusBar barStyle="dark-content" animated/>
      <SelectAccountsPage spaceInfo={spaceInfo} onFinish={onNext} scrollY={scrollY}/>
    </>);
};
export default CreateSpaceAccounts;
