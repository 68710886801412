import { addDays } from 'date-fns';
import * as types from './types';
import { SavingsPlanRuleType } from './types';
const uuidV4 = require('uuid/v4');
export const fakeAccount = __DEV__
    ? {
        status: 'ACTIVE',
        kycVerification: {
            type: types.VerificationType.Identity,
            status: types.KYCStatus.Pending,
            requiredUserAction: 'UPLOAD_IDENTITY_DOCUMENT',
        },
    }
    : undefined;
export const emulateKYCNotStarted = __DEV__;
export const fakePots = [
    {
        id: '1',
        name: 'Christmas gifts',
        emoji: '🍎',
        balance: { amount: 91.37, currency: 'GBP' },
        goal: { amount: 200, currency: 'GBP', date: 'Thu Sep 22 2022 21:59:32 GMT+0545' },
    },
    {
        id: '2',
        name: 'Ibiza 2023',
        emoji: '🍊',
        balance: { amount: 91.37, currency: 'GBP' },
    },
];
export const useFakeData = false;
export const useFakePotsTransactions = __DEV__ && [
    {
        id: '1',
        type: 'deposit',
        title: 'Top up',
        formattedDate: '5:20 PM',
        formattedAmount: '$100',
    },
    {
        id: '2',
        type: 'withdraw',
        title: 'Withdraw',
        formattedDate: '5:05 PM',
        formattedAmount: '-$100',
    },
    {
        id: '3',
        title: 'Transfer',
        formattedDate: '5:00 PM',
        type: 'transferWithdraw',
        formattedAmount: '-$100',
    },
    {
        id: '4',
        title: 'Transfer',
        formattedDate: '4:45PM',
        type: 'transferDeposit',
        formattedAmount: '$100',
    },
];
/**
 * This will show "Your top-up is pending" in Pots,
 * and will also trigger periodic fetch for `/pending-top-ups` endpoint for 90 seconds.
 */
export const emulatePendingTopups = __DEV__ && true;
export const showFakeUpcomingAutosavePayments = __DEV__ && false;
export const fakeUpcomingAutosavePayments = [
    {
        id: uuidV4(),
        skipped: false,
        executionDate: addDays(new Date(), 1).toISOString(),
        amount: { value: 100, currency: 'GBP' },
        payoutTimestamp: addDays(new Date(), 2).toISOString(),
        destinations: [
            {
                potId: 'WALLET',
                percentage: 100,
                amount: {
                    value: 100,
                    currency: 'GBP',
                },
            },
        ],
    },
];
export const showFakeAutosaveActivities = __DEV__ && false;
export const fakeAutosaveActivities = [
    {
        id: uuidV4(),
        timestamp: '2024-02-13T00:00:00.000Z',
        amount: { value: 100, currency: 'GBP' },
        type: 'AUTO_SAVE',
        title: 'Autosave',
        status: 'PENDING',
        savingsPlanInfo: {
            payoutTimestamp: '2024-02-21T00:00:00.000Z',
            destinations: [
                {
                    potId: 'WALLET',
                    percentage: 90,
                    amount: {
                        value: 90,
                        currency: 'GBP',
                    },
                },
                {
                    potId: uuidV4(),
                    percentage: 10,
                    amount: {
                        value: 10,
                        currency: 'GBP',
                    },
                },
            ],
            rules: [
                {
                    type: SavingsPlanRuleType.AUTOMATED,
                    amount: {
                        value: 100,
                        currency: 'GBP',
                    },
                },
            ],
        },
    },
    {
        id: uuidV4(),
        timestamp: '2024-02-12T00:00:00.000Z',
        amount: { value: 100, currency: 'GBP' },
        type: 'AUTO_SAVE',
        title: 'Autosave',
        status: 'COMPLETED',
        savingsPlanInfo: {
            payoutTimestamp: '2024-02-19T00:00:00.000Z',
            destinations: [
                {
                    potId: 'WALLET',
                    percentage: 90,
                    amount: {
                        value: 90,
                        currency: 'GBP',
                    },
                },
                {
                    potId: uuidV4(),
                    percentage: 10,
                    amount: {
                        value: 10,
                        currency: 'GBP',
                    },
                },
            ],
            rules: [
                {
                    type: SavingsPlanRuleType.AUTOMATED,
                    amount: {
                        value: 100,
                        currency: 'GBP',
                    },
                },
            ],
        },
    },
    {
        id: uuidV4(),
        timestamp: '2024-01-02T00:00:00.000Z',
        amount: { value: 100, currency: 'GBP' },
        type: 'AUTO_SAVE',
        title: 'Autosave',
        status: 'SKIPPED',
        savingsPlanInfo: {
            payoutTimestamp: '2024-01-18T00:00:00.000Z',
            destinations: [
                {
                    potId: 'WALLET',
                    percentage: 90,
                    amount: {
                        value: 90,
                        currency: 'GBP',
                    },
                },
                {
                    potId: uuidV4(),
                    percentage: 10,
                    amount: {
                        value: 10,
                        currency: 'GBP',
                    },
                },
            ],
            rules: [
                {
                    type: SavingsPlanRuleType.AUTOMATED,
                    amount: {
                        value: 100,
                        currency: 'GBP',
                    },
                },
            ],
        },
    },
    {
        id: uuidV4(),
        timestamp: '2023-12-12T00:00:00.000Z',
        amount: { value: 200, currency: 'GBP' },
        type: 'AUTO_SAVE',
        title: 'Autosave',
        status: 'FAILED',
        savingsPlanInfo: {
            payoutTimestamp: '2023-12-19T00:00:00.000Z',
            destinations: [
                {
                    potId: 'WALLET',
                    percentage: 90,
                    amount: {
                        value: 180,
                        currency: 'GBP',
                    },
                },
                {
                    potId: uuidV4(),
                    percentage: 10,
                    amount: {
                        value: 20,
                        currency: 'GBP',
                    },
                },
            ],
            rules: [
                {
                    type: SavingsPlanRuleType.AUTOMATED,
                    amount: {
                        value: 100,
                        currency: 'GBP',
                    },
                },
            ],
        },
    },
    {
        id: uuidV4(),
        timestamp: '2023-12-02T00:00:00.000Z',
        amount: { value: 200, currency: 'GBP' },
        type: 'AUTO_SAVE',
        title: 'Autosave',
        status: 'FAILED',
        savingsPlanInfo: {
            payoutTimestamp: '2023-12-20T00:00:00.000Z',
            destinations: [
                {
                    potId: 'WALLET',
                    percentage: 90,
                    amount: {
                        value: 180,
                        currency: 'GBP',
                    },
                },
                {
                    potId: uuidV4(),
                    percentage: 10,
                    amount: {
                        value: 20,
                        currency: 'GBP',
                    },
                },
            ],
            rules: [
                {
                    type: SavingsPlanRuleType.AUTOMATED,
                    amount: {
                        value: 100,
                        currency: 'GBP',
                    },
                },
            ],
        },
    },
    {
        id: uuidV4(),
        timestamp: '2023-11-02T00:00:00.000Z',
        amount: { value: 50, currency: 'GBP' },
        type: 'AUTO_SAVE',
        title: 'Autosave',
        status: 'PENDING',
        savingsPlanInfo: {
            payoutTimestamp: '2023-11-21T00:00:00.000Z',
            destinations: [
                {
                    potId: 'WALLET',
                    percentage: 90,
                    amount: {
                        value: 45,
                        currency: 'GBP',
                    },
                },
                {
                    potId: uuidV4(),
                    percentage: 10,
                    amount: {
                        value: 5,
                        currency: 'GBP',
                    },
                },
            ],
            rules: [
                {
                    type: SavingsPlanRuleType.AUTOMATED,
                    amount: {
                        value: 100,
                        currency: 'GBP',
                    },
                },
            ],
        },
    },
    {
        id: uuidV4(),
        timestamp: '2023-10-02T00:00:00.000Z',
        amount: { value: 50, currency: 'GBP' },
        type: 'AUTO_SAVE',
        title: 'Autosave',
        status: 'COMPLETED',
        savingsPlanInfo: {
            payoutTimestamp: '2023-10-22T00:00:00.000Z',
            destinations: [
                {
                    potId: 'WALLET',
                    percentage: 90,
                    amount: {
                        value: 45,
                        currency: 'GBP',
                    },
                },
                {
                    potId: uuidV4(),
                    percentage: 10,
                    amount: {
                        value: 5,
                        currency: 'GBP',
                    },
                },
            ],
            rules: [
                {
                    type: SavingsPlanRuleType.AUTOMATED,
                    amount: {
                        value: 100,
                        currency: 'GBP',
                    },
                },
            ],
        },
    },
];
