import React, { useCallback, useLayoutEffect } from 'react';
import StatusBar from 'design-system/StatusBar';
import useColors from 'hooks/useColors';
import { useSharedValue } from 'react-native-reanimated';
import { SelectAccountsPage } from './containers';
const EditSpaceAccounts = ({ route, navigation, }) => {
    const colors = useColors();
    const scrollY = useSharedValue(0);
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Edit accounts',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const onNext = useCallback(() => {
        navigation.pop();
    }, []);
    return (<>
      <StatusBar barStyle="dark-content" animated/>
      <SelectAccountsPage scrollY={scrollY} spaceInfo={route.params?.spaceInfo} onFinish={onNext} withoutHeader/>
    </>);
};
export default EditSpaceAccounts;
