import React, { useMemo } from 'react';
import { isSameDay, format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { SettingsItem } from 'design-system/SettingsItem';
import { IconCalendar } from 'design-system/icons/IconCalendar';
const TransactionOriginalDateItem = ({ transaction }) => {
    const originalDate = useMemo(() => {
        if (transaction.customDate &&
            !isSameDay(parseISO(transaction.bookingDate), parseISO(transaction.customDate))) {
            return transaction.bookingDate;
        }
        return undefined;
    }, [transaction.bookingDate, transaction.customDate]);
    if (!originalDate) {
        return null;
    }
    return (<SettingsItem title="Original date" description={format(toZonedTime(originalDate, 'UTC'), 'EEE, MMM d yyyy')} icon={<IconCalendar gradientKey="skyBlue"/>}/>);
};
export default TransactionOriginalDateItem;
