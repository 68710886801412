import { CONNECTING_SECOND_LOGIN, SET_SHOULD_SHOW_FAKE_LOADING_SCREEN, SET_SIGN_IN_SPACE, SKIPPED_PREMIUM_SCREEN, SKIPPED_PREMIUM_SCREEN_SECOND, SKIPPED_PUSH_NOTIFICATIONS, SKIPPED_SECOND_LOGIN, SKIPPED_SET_BUDGETS, UPGRADED_TO_PREMIUM, SET_SIGN_IN_NAVIGATION_KEY, VIEWED_PREMIUM_WELCOME_SCREEN, } from './types';
export const skippedPremiumScreen = () => ({
    type: SKIPPED_PREMIUM_SCREEN,
});
export const upgradedToPremium = () => ({
    type: UPGRADED_TO_PREMIUM,
});
export const skippedPremiumScreenSecond = () => ({
    type: SKIPPED_PREMIUM_SCREEN_SECOND,
});
export const skippedPushNotifications = () => ({
    type: SKIPPED_PUSH_NOTIFICATIONS,
});
export const skippedSecondLogin = () => ({
    type: SKIPPED_SECOND_LOGIN,
});
export const connectingSecondLogin = () => ({
    type: CONNECTING_SECOND_LOGIN,
});
export const skippedSetBudgets = () => ({
    type: SKIPPED_SET_BUDGETS,
});
export const viewedPremiumWelcomeScreen = () => ({
    type: VIEWED_PREMIUM_WELCOME_SCREEN,
});
export const setShouldShowFakeLoadingScreen = (showFakeLoadingScreen) => ({
    showFakeLoadingScreen,
    type: SET_SHOULD_SHOW_FAKE_LOADING_SCREEN,
});
export const setSignInSpace = (params) => ({
    payload: params,
    type: SET_SIGN_IN_SPACE,
});
export const setSignInNavigationKey = (key, value) => ({
    key,
    value,
    type: SET_SIGN_IN_NAVIGATION_KEY,
});
