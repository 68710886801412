import getSymbolFromCurrency from 'currency-symbol-map';
import React, { memo, useMemo } from 'react';
import { Text, View } from 'react-native';
import Animated, { useAnimatedProps, } from 'react-native-reanimated';
import { rem } from 'design-system';
import { AnimatedTextInput } from 'design-system/AnimatedComponents';
import { styles as textStyles } from 'design-system/Text';
import BlurAmountView from 'design-system/BlurAmountView';
import useStyles from 'hooks/useStyles';
import * as selectors from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import { getBalance } from 'utils/api/getBalance';
import createStyleSheets from 'utils/createStyleSheets';
import { formatAmount, formatAmountWorklet } from 'utils/formatting';
import { isWeb } from '../../../constants';
const AccountTransactionsScreenTitle = ({ data, selectedInfoObj, selectedInfoObjState, }) => {
    const styles = useStyles(styleSet);
    const scrambled = useAppSelector(selectors.selectScrambled);
    const defaultCurrency = useAppSelector(selectors.selectDefaultCurrency);
    const [primaryBalance] = getBalance(data);
    const fallbackItem = useMemo(() => ({
        amount: primaryBalance?.amount || 0,
        currency: primaryBalance?.currency || defaultCurrency,
    }), [defaultCurrency, primaryBalance?.amount, primaryBalance?.currency]);
    const currency = useMemo(() => getSymbolFromCurrency(fallbackItem.currency) || '', [fallbackItem.currency]);
    const defaultCurrencySymbol = useMemo(() => getSymbolFromCurrency(defaultCurrency) || '', [defaultCurrency]);
    const titleProps = useAnimatedProps(() => ({
        text: selectedInfoObj.value
            ? formatAmountWorklet(selectedInfoObj.value.actualY, defaultCurrencySymbol)
            : formatAmountWorklet(fallbackItem?.amount || 0, currency),
    }), [
        selectedInfoObj.value,
        defaultCurrencySymbol,
        fallbackItem?.amount,
        currency,
    ]);
    const subtitleProps = useAnimatedProps(() => ({
        text: selectedInfoObj.value
            ? String(selectedInfoObj.value.label)
            : data.name,
    }), [selectedInfoObj, data.name]);
    return (<Animated.View style={styles.container}>
      <View style={styles.header}>
        {isWeb ? (<>
            <Text Numbers-18 numberOfLines={1} allowFontScaling={false} style={styles.balanceTextStyle}>
              {selectedInfoObjState
                ? formatAmount(selectedInfoObjState.actualY, defaultCurrencySymbol, 2, scrambled)
                : formatAmount(fallbackItem?.amount || 0, currency, 2, scrambled)}
            </Text>
            <View style={styles.balance}>
              <Text numberOfLines={1} allowFontScaling={false} style={styles.subtitleStyle}>
                {selectedInfoObjState
                ? String(selectedInfoObjState.label)
                : data.name}
              </Text>
            </View>
          </>) : (<>
            <BlurAmountView enabled={scrambled}>
              <AnimatedTextInput editable={false} numberOfLines={1} allowFontScaling={false} animatedProps={titleProps} style={styles.balanceTextStyle} defaultValue={formatAmount(fallbackItem?.amount || 0, currency, 2)}/>
            </BlurAmountView>
            <View style={styles.balance}>
              <AnimatedTextInput editable={false} numberOfLines={1} allowFontScaling={false} style={styles.subtitleStyle} animatedProps={subtitleProps} defaultValue={data.name}/>
            </View>
          </>)}
      </View>
    </Animated.View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        marginTop: -rem(4),
        alignItems: 'center',
        justifyContent: 'center',
    },
    balanceTextStyle: {
        margin: 0,
        padding: 0,
        color: colors.text.primary,
        fontSize: textStyles.Numbers_18.fontSize,
        fontFamily: textStyles.Numbers_18.fontFamily,
        textAlign: 'center',
    },
    header: {
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'column',
    },
    balance: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    subtitleStyle: {
        margin: 0,
        padding: 0,
        color: colors.text.primary,
        fontSize: textStyles.TextThin_14.fontSize,
        fontFamily: textStyles.TextThin_14.fontFamily,
        textAlign: 'center',
    },
}));
export default memo(AccountTransactionsScreenTitle);
