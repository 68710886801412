import { call, select, spawn, takeEvery, takeLatest, put } from 'typed-redux-saga/macro';
import { REGISTER_USER_SUCCESS } from 'actions/types';
import { selectUser } from 'reducers/selectors';
import { navigationRef } from 'utils/navigationv6';
import { updateTradingAccount } from '../api/account';
import { getLatestPrices } from '../actions';
import { selectAccountStatus } from '../reducers/selectors';
import topupSaga from './topupSaga';
import withdrawSagas from './withdrawSagas';
function* fetchTradingAccountStatus() {
    const user = yield* select(selectUser);
    if (user.guessedHomeCountry === 'GB') {
        log('[investSaga] fetching trading account status...', false, 'cyan');
        yield* call(updateTradingAccount, true);
    }
    else {
        log('[investSaga] skipping, user is not GB', false, 'cyan');
    }
}
function* handleInvestInvite() {
    const user = yield* select(selectUser);
    const isGB = user.guessedHomeCountry === 'GB';
    const status = yield* select(selectAccountStatus);
    if (isGB && status !== 'ACTIVE') {
        if (navigationRef.isReady()) {
            navigationRef.navigate('Invest');
        }
    }
}
/**
 * fetching the latest prices for all the symbols that we have in pending stock alerts
 */
function* fetchPricesForPriceAlertSymbols(action) {
    const symbolSet = new Set();
    action.payload.alerts.forEach((alert) => {
        symbolSet.add(alert.symbol);
    });
    const symbols = [...symbolSet];
    if (symbols.length) {
        yield* put(getLatestPrices(symbols));
    }
}
function* investSaga() {
    log('[investSaga] starting...', false, 'cyan');
    yield* takeLatest('INVEST_GET_STOCK_ALERTS_SUCCESS_ACTION', fetchPricesForPriceAlertSymbols);
    yield* takeEvery(REGISTER_USER_SUCCESS, fetchTradingAccountStatus);
    yield* takeEvery((action) => action.type === 'SET_REFERRER_ID', handleInvestInvite);
    yield* spawn(topupSaga);
    yield* spawn(withdrawSagas);
}
export default investSaga;
