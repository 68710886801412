import React, { memo, useCallback, useEffect, useId, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, { useAnimatedProps, useAnimatedStyle, useDerivedValue, useSharedValue, withSpring, withTiming, } from 'react-native-reanimated';
import Svg, { Defs, G, Use } from 'react-native-svg';
import { AnimatedPath } from 'design-system/AnimatedComponents';
import calculatePath from 'features/invest/components/Chart/worklets/path';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { triggerChartSelectionFeedback } from 'utils/hapticFeedback';
import { isWeb } from '../../constants';
import Pointer from './Pointer';
import MarketActivePointer from './MarketActivePointer';
const strokeWidth = Platform.OS === 'android' ? 5 : 3.32;
const shadowOpacity = Platform.OS === 'android' ? 0.2 : 0.1;
const LineChart = ({ width, points, points2, height, strokeColor, activePoint, hasActiveMarker, selectedInfoObj, selectedInfoObj2, paddingHorizontal, chartWrapperHeight, setSelectedInfoObj, setSelectedInfoObj2, canSelectEmptyPoints, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const progress = useSharedValue(0);
    const isGestureActive = useSharedValue(false);
    const currentPath = useSharedValue([]);
    const currentPath2 = useSharedValue([]);
    const previousPath = useSharedValue([]);
    const previousPath2 = useSharedValue([]);
    useEffect(() => {
        if (points && (canSelectEmptyPoints || points?.length)) {
            if (currentPath.value.length === 0) {
                previousPath.value = points;
            }
            else {
                previousPath.value = currentPath.value;
            }
            if (points2 !== undefined) {
                if (currentPath2.value.length === 0) {
                    previousPath2.value = points2;
                }
                else {
                    previousPath2.value = currentPath2.value;
                }
            }
            progress.value = 0;
            currentPath.value = points;
            if (points2 !== undefined) {
                currentPath2.value = points2;
            }
            progress.value = withTiming(1);
        }
    }, [points, canSelectEmptyPoints]);
    const path = useDerivedValue(() => calculatePath(currentPath.value, previousPath.value, progress.value, {
        width,
        height,
    }), [currentPath, previousPath, progress, width, height]);
    const path2 = useDerivedValue(() => {
        if (points2 === undefined) {
            return '';
        }
        return calculatePath(currentPath2.value, previousPath2.value, progress.value, {
            width,
            height,
        });
    }, [width, height, points2, currentPath2, previousPath2, progress]);
    const animatedProps = useAnimatedProps(() => {
        const props = {
            d: path.value,
        };
        return props;
    }, [path]);
    const animatedProps2 = useAnimatedProps(() => {
        if (points2 === undefined) {
            return {};
        }
        const props = {
            d: path2.value,
        };
        return props;
    }, [points2, path2]);
    const opacityStyles = useAnimatedStyle(() => ({
        opacity: withSpring(!selectedInfoObj.value ? 1 : 0.5),
    }), [selectedInfoObj]);
    const onEnd = useCallback(() => {
        'worklet';
        isGestureActive.value = false;
        // eslint-disable-next-line no-param-reassign
        selectedInfoObj.value = undefined;
        if (isWeb) {
            setSelectedInfoObj(undefined);
        }
        if (selectedInfoObj2) {
            // eslint-disable-next-line no-param-reassign
            selectedInfoObj2.value = undefined;
            if (isWeb) {
                setSelectedInfoObj2?.(undefined);
            }
        }
    }, [
        isGestureActive,
        selectedInfoObj,
        selectedInfoObj2,
        setSelectedInfoObj,
        setSelectedInfoObj2,
    ]);
    const onChange = useCallback((event) => {
        'worklet';
        if (!isGestureActive.value) {
            triggerChartSelectionFeedback();
        }
        isGestureActive.value = true;
        if (!currentPath.value.length) {
            // eslint-disable-next-line no-param-reassign
            selectedInfoObj.value = undefined;
            if (isWeb) {
                setSelectedInfoObj(undefined);
            }
            return;
        }
        const computedX = (event.x - (paddingHorizontal || 0)) / width;
        const index = computedX > 1
            ? currentPath.value.length - 1
            : currentPath.value.findIndex((point) => point.x >= computedX);
        if (index > 0) {
            const updated = {
                ...currentPath.value[index],
                x: (currentPath.value[index]?.x || 0) * width,
                y: (currentPath.value[index]?.y || 0) * height + 10,
            };
            // eslint-disable-next-line no-param-reassign
            selectedInfoObj.value = updated;
            if (isWeb) {
                setSelectedInfoObj(updated);
            }
            if (points2 && selectedInfoObj2) {
                const updated2 = {
                    ...currentPath2.value[index],
                    x: (currentPath2.value[index]?.x || 0) * width,
                    y: (currentPath2.value[index]?.y || 0) * height + 10,
                };
                // eslint-disable-next-line no-param-reassign
                selectedInfoObj2.value = updated2;
                if (isWeb) {
                    setSelectedInfoObj2?.(updated2);
                }
            }
        }
        else if (index === -1) {
            const currentPathLen = currentPath.value.length;
            const updated = {
                ...currentPath.value[currentPathLen - 1],
                x: (currentPath.value[currentPathLen - 1]?.x || 0) * width,
                y: (currentPath.value[currentPathLen - 1]?.y || 0) * height + 10,
            };
            // eslint-disable-next-line no-param-reassign
            selectedInfoObj.value = updated;
            if (isWeb) {
                setSelectedInfoObj(updated);
            }
        }
        else {
            const updated = {
                ...currentPath.value[0],
                x: (currentPath.value[0]?.x || 0) * width,
                y: (currentPath.value[0]?.y || 0) * height + 10,
            };
            // eslint-disable-next-line no-param-reassign
            selectedInfoObj.value = updated;
            if (isWeb) {
                setSelectedInfoObj(updated);
            }
            if (points2 && selectedInfoObj2) {
                const updated2 = {
                    ...currentPath2.value[0],
                    x: (currentPath2.value[0]?.x || 0) * width,
                    y: (currentPath2.value[0]?.y || 0) * height + 10,
                };
                // eslint-disable-next-line no-param-reassign
                selectedInfoObj2.value = updated2;
                if (isWeb) {
                    setSelectedInfoObj2?.(updated2);
                }
            }
        }
    }, [
        currentPath,
        currentPath2,
        height,
        isGestureActive,
        paddingHorizontal,
        points2,
        selectedInfoObj,
        selectedInfoObj2,
        setSelectedInfoObj,
        setSelectedInfoObj2,
        width,
    ]);
    const onDrag = useMemo(() => Gesture.Simultaneous(Gesture.Pan().onChange(onChange).onEnd(onEnd), Gesture.LongPress()
        .onBegin(onChange)
        .onStart(onChange)
        .onTouchesCancelled(onEnd)
        .onEnd(onEnd)
        .onTouchesCancelled(onEnd)
        .minDuration(0)
        .maxDistance(100000)
        .shouldCancelWhenOutside(false)), [onChange, onEnd]);
    const id = useId();
    return (<View style={[styles.lineChartWrapper, { height: chartWrapperHeight }]}>
      <View style={{ height: height + 20 }}>
        <GestureDetector gesture={onDrag}>
          <Animated.View style={isWeb ? undefined : styles.container}>
            <Animated.View style={opacityStyles}>
              <Svg width={width} height={height + 20} // temporary fix for clipped chart
     style={isWeb
            ? undefined
            : [
                styles.shadow,
                !!strokeColor && { shadowColor: strokeColor },
            ]} viewBox={`0 0 ${width} ${height}`}>
                <Defs>
                  <AnimatedPath id={`chartLine${id}`} strokeLinejoin="round" animatedProps={animatedProps} stroke={strokeColor || colors.elements.brand} fill="transparent"/>
                  {points2 !== undefined && (<AnimatedPath id={`chartLine2${id}`} strokeLinejoin="round" animatedProps={animatedProps2} stroke={colors.elements.brandMedium} fill="transparent"/>)}
                </Defs>
                <G>
                  <Use x={0} y={0} xlinkHref={`#chartLine${id}`} opacity={shadowOpacity} strokeWidth={strokeWidth}/>
                  <Use x={0} y={0} xlinkHref={`#chartLine${id}`} strokeWidth={1.66}/>
                  {points2 !== undefined && (<>
                      <Use x={0} y={0} xlinkHref={`#chartLine2${id}`} opacity={shadowOpacity} strokeWidth={strokeWidth}/>
                      <Use x={0} y={0} strokeWidth={1.66} xlinkHref={`#chartLine2${id}`}/>
                    </>)}
                </G>
              </Svg>
            </Animated.View>
          </Animated.View>
        </GestureDetector>
        {hasActiveMarker && (<MarketActivePointer width={width} height={height} color={strokeColor} finalPoint={activePoint}/>)}
        <Pointer color={strokeColor} useLine={points2 !== undefined} selectedInfoObj={selectedInfoObj}/>
      </View>
    </View>);
};
export default memo(LineChart);
const styleSet = createStyleSheets((colors) => ({
    container: {
        shadowRadius: 2,
        shadowOpacity: 1,
        shadowColor: colors.shadows.chart,
        shadowOffset: { width: 3, height: 4 },
    },
    lineChartWrapper: {
        justifyContent: 'center',
    },
    shadow: {
        shadowOpacity: 1,
        shadowRadius: 2.5,
        shadowColor: colors.elements.brand,
        shadowOffset: { width: 0, height: 0 },
    },
}));
