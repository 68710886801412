import React, { useMemo } from 'react';
import { View } from 'react-native';
import Share from 'utils/packages/rnShare';
import Button from 'design-system/Button';
import { ButtonGroup } from 'design-system/ButtonGroup';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import { AppView } from 'design-system/AppView';
import Text from 'design-system/Text';
import { IconSquareCircle } from 'design-system/icons';
import IconSend from 'design-system/icons/IconSend';
import ShareButton from 'design-system/ShareButton';
import StatusBar from 'design-system/StatusBar';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../constants';
const SpaceCreatedPage = ({ space, onFinish }) => {
    const styles = useStyles(styleSet);
    const link = useMemo(() => space?.accessLink?.replace(/^https?:\/\//, '') ?? '', [space?.accessLink]);
    const onShare = () => Share.open({ message: space?.accessLink });
    const paddingBottom = useMarginBottom('paddingBottom');
    const colors = useColors();
    const renderIcon = () => {
        if (isWeb) {
            return (<View style={styles.iconContainer}>
          <View style={styles.iconBackground}>
            <IconSend width={54} height={54}/>
          </View>
        </View>);
        }
        return (<IconSquareCircle width={100} height={100} color={colors.cards.onDark} style={styles.icon}>
        <IconSend width={54} height={54}/>
      </IconSquareCircle>);
    };
    return (<AppView type="secondary">
      <StatusBar barStyle="dark-content" animated/>
      <View style={styles.content}>
        <Spacer flex={4}/>
        {renderIcon()}
        <>
          <Text Text-24 centered style={styles.title}>
            Share your space!
          </Text>
          <Text TextThin-16 Secondary centered style={styles.message}>
            {`You can invite people to view\nyour space`}
          </Text>
        </>
        <Spacer flex={5}/>
        <View style={paddingBottom}>
          <ButtonGroup type="default">
            <ShareButton title={link} onPress={onShare}/>
            <Button brand title="Done" onPress={onFinish}/>
          </ButtonGroup>
        </View>
      </View>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    content: {
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
    },
    icon: {
        alignSelf: 'center',
    },
    title: {
        marginHorizontal: rem(24),
        marginTop: rem(24),
    },
    message: {
        marginHorizontal: rem(24),
        marginTop: rem(8),
    },
    iconContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        height: 100,
        width: 100,
        backgroundColor: colors.cards.onDark,
        borderRadius: 43,
    },
    iconBackground: {
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export default SpaceCreatedPage;
