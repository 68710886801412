import { createSelector } from 'reselect';
import { Tier } from 'features/premium/entities';
import { isUnlocked } from 'utils';
import { hasCompletedSetupSelector } from 'features/feed/components/completeSetup/selectors';
import { defaultFeedPriorityArr } from 'features/settings/organize/constants';
import { organizeFeed } from 'features/settings/organize/utils';
export const selectFeedPriority = createSelector([
    (state) => isUnlocked(state.user.user, Tier.pro),
    (state) => state.deviceSettings.feedPersonalization,
    (state) => state.feed.walkthrough?.status,
    hasCompletedSetupSelector,
], (canOrganizeFeed, feedPersonalization, walkthroughStatus, hasCompletedSetup) => {
    if (canOrganizeFeed &&
        feedPersonalization &&
        walkthroughStatus !== 'in-progress') {
        return organizeFeed(feedPersonalization, hasCompletedSetup);
    }
    return {
        priority: hasCompletedSetup
            ? defaultFeedPriorityArr.filter((item) => item !== 'completeSetup')
            : defaultFeedPriorityArr,
        removed: [],
    };
});
