import { format, parseISO, subYears, isBefore } from 'date-fns';
import { useState } from 'react';
import { toZonedTime } from 'date-fns-tz';
import { useDateTimePicker } from './useDateTimePicker';
const defaultDobDate = parseISO('2000-01-01');
const getMaximumDobDate = (minAge) => subYears(new Date(), minAge);
export const DOB_DISPLAY_FORMAT = 'dd/MM/yyyy';
export const DOB_STORAGE_FORMAT = 'yyyy-MM-dd';
/**
 * returns dob in dd/MM/yyyy string format
 */
const useDateTimePickerDOB = (user, minAge = 18) => {
    const [dob, setDob] = useState(() => user?.dateOfBirth
        ? format(toZonedTime(user.dateOfBirth, 'UTC'), DOB_STORAGE_FORMAT)
        : '');
    const maxDobObj = getMaximumDobDate(minAge);
    const [maximumDob] = useState(maxDobObj);
    const dobDate = dob ? new Date(dob) : undefined;
    // eslint-disable-next-line no-nested-ternary
    const initialValueObj = dobDate
        ? isBefore(dobDate, maxDobObj)
            ? dobDate
            : defaultDobDate
        : defaultDobDate;
    const { showDateTimePicker } = useDateTimePicker({
        initialValue: initialValueObj,
        maximumDate: maximumDob,
        onSubmitDate: (date) => {
            setDob(format(date, DOB_STORAGE_FORMAT));
        },
    });
    /**
     * Any date sent as UTC 00:00:00 should be converted using date-fns toZonedTime
     * to display correctly in different timezones.
     * Example: toZonedTime(dateOfBirth, 'UTC') then format with date-fns
     */
    return { showDateTimePicker, dob };
};
export default useDateTimePickerDOB;
