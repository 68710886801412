import React, { memo, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import emoji from 'node-emoji';
import Alert from 'utils/packages/Alert';
import ScrollView from 'design-system/ScrollView';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import { useSetAccountsSpace } from '../hooks';
import { useSpaceAccounts } from '../hooks/useSpaceAccounts';
import { CreateSpacePageButton, SelectAccountsBody } from '../components';
import { isWeb } from '../../../constants';
const SelectAccountsPage = ({ spaceInfo, withoutHeader, onFinish, scrollY, }) => {
    const [accounts, onSelected] = useSpaceAccounts(spaceInfo);
    const [busy, updateSpace] = useSetAccountsSpace(spaceInfo?.space, accounts, onFinish);
    const onNext = useCallback(async () => {
        if (busy || !spaceInfo?.space)
            return;
        if (spaceInfo.space.id < 0 &&
            !accounts
                .find(({ space: { id } }) => id === spaceInfo.space.id)
                ?.accounts?.filter(({ id }) => id)?.length) {
            Alert.alert(emoji.emojify('Slow down :face_with_rolling_eyes:'), 'You need to add at least one account to a space before creating it.');
        }
        else {
            await updateSpace();
        }
    }, [accounts, busy, spaceInfo?.space, updateSpace]);
    return (<View style={styles.content}>
      <ScrollView secondary scrollY={scrollY}>
        {!withoutHeader && (<ScreenInfoHeader title="Add accounts" subTitle={isWeb
                ? 'Click accounts to move them to the new space'
                : 'Tap accounts to move them to the new space'}/>)}
        <SelectAccountsBody accounts={accounts} onSelected={busy ? undefined : onSelected}/>
      </ScrollView>
      <CreateSpacePageButton buttonTitle={withoutHeader ? 'Save' : 'Next'} onClick={onNext} buttonVisible buttonPending={busy}/>
    </View>);
};
const styles = StyleSheet.create({
    content: {
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
    },
});
export default memo(SelectAccountsPage);
