import { Tier } from './entities';
export const isUnlocked = (user, tier) => {
    if (tier === Tier.free)
        return true;
    if (typeof user === 'object' &&
        (!user.emmaProStatus || !user.emmaProStatus.active))
        return false;
    const userTier = typeof user === 'object' ? user.emmaProStatus.tier : user;
    if (userTier === Tier.ultimate) {
        return true; // Ultimate is the top plan, it has all the features
    }
    if (tier === Tier.ultimate) {
        return false; // user is not on Ultimate according to condition above
    }
    return userTier === tier || userTier === Tier.pro;
};
