import React, { useCallback, useEffect, useMemo, useState, memo, } from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedScrollHandler, } from 'react-native-reanimated';
import { CommonActions } from '@react-navigation/native';
import { rem } from 'design-system/values';
import Indicator from 'design-system/Indicator';
import Button, { HEIGHT_PLUS_PADDING } from 'design-system/Button';
import { ButtonGroup } from 'design-system/ButtonGroup';
import FlatListItemCard from 'design-system/FlatListItemCard';
import { UpgradeWebModal } from 'design-system/UpgradeWebModal';
import { SettingsItemSwitch } from 'design-system/SettingsItem';
import useStyles from 'hooks/useStyles';
import { isUnlocked } from 'features/premium/isUnlocked';
import { useMarginBottom } from 'hooks/useMarginBottom';
import createStyleSheets from 'utils/createStyleSheets';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { selectUserTier } from 'features/premium/selectors/tier';
import { isWeb } from '../../../../constants';
import { useNotificationsPreferences } from '../hooks/useNotificationsPreferences';
import { filterPreferences } from '../utils';
const strings = {
    buttonTitle: 'Save',
    promotions: 'Promotions',
};
const preferenceItems = {
    'alerts.global.email': {
        title: 'Email communications',
        description: "Keep this off if you don't want any emails",
    },
    'alerts.product_update.email': {
        title: 'Product updates',
        description: 'Stay up to date on Emma products, services and offers',
    },
    'alerts.marketing.email': {
        title: 'Marketing',
        description: 'Receive exclusive, time-sensitive, offers just for you!',
    },
};
const notificationKeys = Object.keys(preferenceItems);
const notificationSections = [
    { id: 'global', preferenceKeys: ['alerts.global.email'] },
    {
        id: 'promotions',
        title: strings.promotions,
        preferenceKeys: ['alerts.product_update.email', 'alerts.marketing.email'],
    },
];
const EmailNotificationSettings = ({ error, isFetching, scrollYPush, ListHeaderComponent, }) => {
    const { emailNormalizedNotificationPreferences: notificationPreferences, updatePreferences, } = useNotificationsPreferences();
    const styles = useStyles(styleSet);
    const colors = useColors();
    const [sectionShown, setSectionShown] = useState(!!isWeb);
    const userTier = useAppSelector(selectUserTier);
    useEffect(() => {
        if (!isWeb) {
            const timeout = setTimeout(() => {
                setSectionShown(true);
            }, 400);
            return () => {
                clearTimeout(timeout);
            };
        }
        return () => { };
    }, []);
    const navigation = useMainStackNavigation();
    const [preferencesState, setPreferencesState] = useState(() => filterPreferences(notificationPreferences, notificationKeys));
    // Keep the local state in sync
    useEffect(() => {
        setPreferencesState(filterPreferences(notificationPreferences, notificationKeys));
    }, [notificationPreferences]);
    const onPreferenceChange = useCallback((value, preferenceKey) => {
        const { requiredTier } = preferenceItems[preferenceKey];
        if (!requiredTier || isUnlocked(userTier, requiredTier)) {
            if (preferenceKey === 'alerts.global.email') {
                if (!value) {
                    setPreferencesState({
                        'alerts.global.email': false,
                        'alerts.product_update.email': false,
                        'alerts.marketing.email': false,
                    });
                }
                else {
                    setPreferencesState({
                        'alerts.global.email': true,
                        'alerts.product_update.email': true,
                        'alerts.marketing.email': true,
                    });
                }
            }
            else {
                setPreferencesState((oldState) => ({
                    ...oldState,
                    'alerts.global.email': true,
                    [preferenceKey]: value,
                }));
            }
        }
        else if (isWeb) {
            UpgradeWebModal.show(requiredTier);
        }
        else {
            navigation.navigate('EmmaProModal', { tier: requiredTier });
        }
    }, [userTier]);
    const getPreferenceValue = useCallback((preferenceKey) => {
        const { requiredTier } = preferenceItems[preferenceKey];
        if (!requiredTier || isUnlocked(userTier, requiredTier)) {
            return preferencesState[preferenceKey];
        }
        return false;
    }, [preferencesState, userTier]);
    const data = useMemo(() => notificationKeys.reduce((prev, preferenceKey) => ({
        ...prev,
        [preferenceKey]: {
            preferenceKey,
            title: preferenceItems[preferenceKey].title,
            description: preferenceItems[preferenceKey].description,
            value: getPreferenceValue(preferenceKey),
            onValueChange: onPreferenceChange,
        },
    }), {}), [getPreferenceValue, onPreferenceChange]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', HEIGHT_PLUS_PADDING + rem(32), paddingHorizontalStyle);
    const scrollHandlerPush = useAnimatedScrollHandler((handle) => {
        // eslint-disable-next-line no-param-reassign
        scrollYPush.value = handle.contentOffset.y;
    });
    const renderItem = useCallback((item, index, itemsLength) => {
        const { preferenceKey, title, description, value, onValueChange } = item;
        return (<FlatListItemCard key={preferenceKey} index={index} style={styles.eachItem} itemsLength={itemsLength}>
          <SettingsItemSwitch key={preferenceKey} itemKey={preferenceKey} title={title} description={description} value={value} onValueChange={onValueChange}/>
        </FlatListItemCard>);
    }, [styles.eachItem]);
    if (!sectionShown) {
        return null;
    }
    if ((isFetching && Object.keys(notificationPreferences).length === 0) ||
        error ||
        Object.keys(preferencesState).length === 0) {
        return (<View style={styles.container}>
        <Indicator dark/>
      </View>);
    }
    return (<>
      <Animated.ScrollView onScroll={scrollHandlerPush} scrollEventThrottle={16} showsVerticalScrollIndicator={false} contentContainerStyle={[styles.scrollContainer, paddingBottom]}>
        {ListHeaderComponent}
        {notificationSections.map((section) => (<View key={section.id}>
            {section.title && (<Text Text-16 color={colors.text.secondary} style={styles.sectionTitle}>
                {section.title}
              </Text>)}
            {section.preferenceKeys.map((key, index) => renderItem(data[key], index, section.preferenceKeys.length))}
          </View>))}
      </Animated.ScrollView>
      <ButtonGroup type="floating" margins>
        <Button brand title={strings.buttonTitle} onPress={() => {
            updatePreferences(preferencesState);
            if (!navigation.canGoBack() && isWeb) {
                navigation.dispatch(CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'Feed' }],
                }));
            }
            else {
                navigation.goBack();
            }
        }}/>
      </ButtonGroup>
    </>);
};
export default memo(EmailNotificationSettings);
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    scrollContainer: {
        paddingTop: rem(8),
        gap: rem(24),
    },
    sectionTitle: {
        paddingBottom: rem(16),
        marginHorizontal: rem(16),
    },
    eachItem: {
        backgroundColor: colors.cards.onDark,
    },
}));
