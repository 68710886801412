import { CommonActions } from '@react-navigation/native';
import { DaysOfTheWeek } from 'design-system/day-of-the-week-picker/values';
import { useSaveBudget } from 'features/budgeting/hooks/useSaveBudget';
import { useCallback } from 'react';
import { Frequencies } from 'reducers/types';
import { getOrdinalSuffix } from 'utils';
import { useMainStackNavigation } from 'utils/types/navigationV6';
const periodToDescription = (period) => {
    const on = period.weekNumber + getOrdinalSuffix(period.weekNumber || 1);
    const daysOfTheWeek = DaysOfTheWeek[period.weekday || DaysOfTheWeek.Monday];
    const { days } = period;
    switch (period.frequency) {
        case Frequencies.EveryMonth:
            return `Your period will reset every ${on} ${daysOfTheWeek}.`;
        case Frequencies.FixedDate:
            if (!days) {
                return '';
            }
            return `Your period will reset on the ${days[0]}${getOrdinalSuffix(days[0])} day of each month.`;
        case Frequencies.EveryFourWeeks:
            return `Your period will reset every 4 weeks on ${daysOfTheWeek}.`;
        case Frequencies.EveryTwoWeeks:
            return `Your period will reset every 2 weeks on ${daysOfTheWeek}.`;
        case Frequencies.Weekly:
            return `Your period will reset every week on ${daysOfTheWeek}.`;
        case Frequencies.LastWorkingDay:
            return 'Your period will reset on the last working day of the month.';
        case Frequencies.Monthly:
            return 'Your period will reset from first to last day of the month.';
        case Frequencies.TwiceAMonth:
            if (!days) {
                return '';
            }
            const sortedDays = days.slice().sort((a, b) => a - b);
            return `Your period will reset on the ${sortedDays[0]}${getOrdinalSuffix(sortedDays[0])} and ${sortedDays[1]}${getOrdinalSuffix(sortedDays[1])} day of each month.`;
        default:
            return '';
    }
};
const useSavePeriodAndNavigateToAnalytics = () => {
    const navigation = useMainStackNavigation();
    const [isFetching, save] = useSaveBudget();
    const savePeriodAndNavigateToAnalytics = useCallback(async (newPeriod) => {
        await save(undefined, false, [], newPeriod);
        const periodDescription = periodToDescription(newPeriod);
        navigation.navigate('Success', {
            title: 'Success',
            subTitle: `${periodDescription || 'The default period was set.'} You can now track your spending!`,
            onContinue: () => {
                navigation.dispatch((state) => {
                    const routes = state.routes.filter((r) => ![
                        'AnalyticsIntro',
                        'SelectPeriod',
                        'EditBudgetFrequency',
                        'SelectDate',
                        'HolidaySelection',
                        'Rollover',
                        'EditBudget',
                        'Budgets',
                        'Success',
                    ].includes(r.name));
                    return CommonActions.reset({
                        ...state,
                        routes,
                        index: routes.length - 1,
                    });
                });
                navigation.navigate('Analytics');
                return true;
            },
        });
    }, [save]);
    return [isFetching, savePeriodAndNavigateToAnalytics];
};
export default useSavePeriodAndNavigateToAnalytics;
