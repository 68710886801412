import React, { useMemo, useState, useEffect, useCallback, useImperativeHandle, } from 'react';
import { View } from 'react-native';
import Collapsible from 'utils/packages/rnCollapsible';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming, } from 'react-native-reanimated';
import AccountBalance from 'features/accounts/components/overview/AccountBalance';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { splitStyle } from 'utils/index';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { IconArrow } from './icons/IconArrow';
import Text from './Text';
import { rem } from './values';
import BlurAmountView from './BlurAmountView';
import { isWeb } from '../constants';
export const CollapsableContainer = React.forwardRef(({ title, style, children, titleContent, titleBottomContent, rightContent, amount, color, onOpen, hasData = true, isCollapsedByDefault, collapsable = true, unmountChildrenWhenCollapsed = false, blur = false, }, ref) => {
    const [collapsed, setCollapsed] = useState(isCollapsedByDefault);
    const sv = useSharedValue(isCollapsedByDefault ? 90 : -90);
    useEffect(() => {
        sv.value = withTiming(collapsed ? 90 : -90, {
            duration: 300,
            easing: Easing.out(Easing.cubic),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapsed]);
    const rotationStyle = useAnimatedStyle(() => ({
        transform: [{ rotate: `${sv.value}deg` }],
    }));
    const [outer, inner] = splitStyle(style, {
        removeBackground: true,
        removeBorders: true,
    });
    const onPressHeader = useCallback(() => {
        if (collapsable) {
            if (collapsed) {
                onOpen?.();
            }
            setCollapsed(!collapsed);
        }
    }, [collapsable, collapsed, onOpen]);
    const expand = useCallback(() => {
        setCollapsed(false);
    }, []);
    useImperativeHandle(ref, () => ({ expand }), [expand]);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const titleContainer = useMemo(() => {
        if (amount && color) {
            return (<View style={styles.titleContainer}>
            <View style={styles.titleInnerContainer}>
              <Text Text-16 style={styles.title}>
                {title}
              </Text>
              {hasData && (<Animated.View style={[styles.arrow, rotationStyle]}>
                  <IconArrow width={8} height={8}/>
                </Animated.View>)}
            </View>
            {rightContent || (<BlurAmountView enabled={blur}>
                <AccountBalance amount={Number(amount.amount)} currency={amount.currency} color={color}/>
              </BlurAmountView>)}
          </View>);
        }
        return (<View style={styles.titleContainer}>
          <View style={styles.titleInnerContainer}>
            {Boolean(title) && (<Text Text-16 style={styles.title}>
                {title}
              </Text>)}
            {titleContent}
          </View>
          {rightContent}
          {collapsable && hasData && (<Animated.View style={[styles.arrow, rotationStyle]}>
              <IconArrow />
            </Animated.View>)}
        </View>);
    }, [
        amount,
        blur,
        collapsable,
        color,
        hasData,
        rightContent,
        rotationStyle,
        styles.arrow,
        styles.title,
        styles.titleContainer,
        styles.titleInnerContainer,
        title,
        titleContent,
    ]);
    return (<View style={[styles.container, outer]}>
        <TouchableHighlight hoverOnDark style={styles.header} underlayColor={colors.background.underlay} onPress={onPressHeader} disabled={!hasData}>
          <View>
            {titleContainer}
            {titleBottomContent}
          </View>
        </TouchableHighlight>
        <Collapsible collapsed={collapsed} style={[styles.content, inner]}>
          {collapsed && (unmountChildrenWhenCollapsed || isWeb) ? null : (<View style={styles.webPadding}>{children}</View>)}
        </Collapsible>
      </View>);
});
const styleSet = createStyleSheets((colors) => ({
    container: {
        borderRadius: rem(16),
        backgroundColor: colors.cards.onDark,
        overflow: 'hidden',
    },
    titleContainer: {
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    titleInnerContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: 1,
    },
    title: {
        marginEnd: rem(8),
    },
    header: {
        width: '100%',
        paddingVertical: rem(17.5),
        paddingLeft: rem(16),
        paddingRight: rem(16),
    },
    arrow: {
        width: rem(24),
        height: rem(24),
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
    content: {
        borderTopColor: colors.background.dark,
        borderTopWidth: rem(1),
    },
    webPadding: {
        ...(isWeb && {
            padding: rem(8),
        }),
    },
}));
