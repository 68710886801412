import { SubscriptionPlatform } from 'react-native-iap';
import { TierPeriod, } from 'features/premium/entities';
import { formatAmount } from 'utils/formatting';
import { tryToRoundToZeroPointNinetyNine } from 'features/premium/tryToRoundToZeroPointNinetyNine';
import { getBaseSubscriptionOfferAndroid } from 'utils';
export const formatYearlyPrice = ({ price, period, currency, }) => {
    const priceAsFloat = typeof price === 'string' ? parseFloat(price) : price;
    return period === TierPeriod.monthly
        ? formatAmount(priceAsFloat * 12, currency)
        : formatAmount(priceAsFloat, currency);
};
export const formatMonthlyPriceFromYearly = ({ price, currency, }) => formatAmount(tryToRoundToZeroPointNinetyNine((typeof price === 'string' ? parseFloat(price) : price) / 12), currency);
export const formatWeeklyPriceFromMonthly = ({ price, currency, }) => formatAmount((typeof price === 'string' ? parseFloat(price) : price) / 4, currency);
export const canUseFreeTrial = (iapSubscription, isEligibleForIntroOffer) => {
    if (iapSubscription.platform === SubscriptionPlatform.ios) {
        return (iapSubscription.introductoryPricePaymentModeIOS === 'FREETRIAL' &&
            (isEligibleForIntroOffer === true ||
                // If the check hasn't ran yet we default to show the trial
                isEligibleForIntroOffer === undefined));
    }
    if (iapSubscription.platform === SubscriptionPlatform.android) {
        const baseOffer = getBaseSubscriptionOfferAndroid(iapSubscription);
        if (baseOffer.pricingPhases.pricingPhaseList.length) {
            return (baseOffer.pricingPhases.pricingPhaseList[0].priceAmountMicros === '0');
        }
    }
    return false;
};
