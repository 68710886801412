import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { View, Pressable } from 'react-native';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import TouchableBounce from 'utils/packages/TouchableBounce';
import Button, { HEIGHT } from 'design-system/Button';
import { rem } from 'design-system/values';
import ScrollView from 'design-system/ScrollView';
import Spacer from 'design-system/Spacer';
import { SettingsItemContent, SettingsItemTitle, SettingsItemRightContent, } from 'design-system/SettingsItem';
import Separator from 'design-system/Separator';
import ReanimatedStackHeader from 'design-system/hoc/ReanimatedStackHeader';
import InfoCard from 'design-system/InfoCard';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import Text from 'design-system/Text';
import IconPlus from 'design-system/icons/IconPlus';
import Squircle from 'design-system/Squircle';
import FeatureIntroduction from 'design-system/FeatureIntroduction';
import IconLayers from 'design-system/icons/IconLayers';
import { IconTwoUser } from 'design-system/icons/IconTwoUser';
import { IconShare } from 'design-system/icons/IconShare';
import { IconSettings } from 'design-system/icons/IconSettings';
import { Indicator } from 'design-system/Indicator';
import { CardView } from 'design-system/CardView';
import { selectIsUserUltimate } from 'features/premium/selectors/tier';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import { useSharedValue } from 'react-native-reanimated';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import Links from 'utils/links';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { setSelectedSpace } from '../actions';
import { SpaceItem } from '../components';
import { selectSelectedSpace } from '../reducers/selectors';
import { isWeb } from '../../../constants';
const SPACES_INTRO_ITEMS = [
    {
        icon: <IconTwoUser gradientKey="darkPink"/>,
        title: 'Group',
        description: 'Group your accounts in Spaces and set different budgets',
    },
    {
        icon: <IconSettings gradientKey="orange"/>,
        title: 'Manage',
        description: 'Manage your personal, joint and business accounts all in Emma.',
    },
    {
        icon: <IconShare gradientKey="green"/>,
        title: 'Share',
        description: 'Share Spaces with your partner(s) and allow them to use Pro features such as Custom Categories.',
    },
];
const strings = {
    title: 'Introducing spaces',
    subTitle: 'Great for business owners, self-employed and families',
};
const MySpacesPage = ({ spaces, goBack, onShowExpiredModal, onCreateSpace, onEditSpace, onSpaceAccounts, isLoading, }) => {
    const dispatch = useAppDispatch();
    const navigation = useMainStackNavigation();
    const styles = useStyles(styleSet);
    const colors = useColors();
    const scrollY = useSharedValue(0);
    const hasSpaces = spaces && spaces.length > 1;
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: () => (<ReanimatedStackHeader title="My Spaces" scrollY={scrollY}/>),
        });
    }, [scrollY]);
    const spaceSelection = useAppSelector(selectSelectedSpace);
    // for FE radio button UI only
    const [selectedSpaceId, setSelectedSpaceId] = useState(spaceSelection);
    const selectedSpace = spaces?.find((space) => space.id === selectedSpaceId);
    const isUltimate = useAppSelector(selectIsUserUltimate);
    const userId = useAppSelector((store) => store.user.user.id);
    const onChangeSpace = useCallback(() => {
        if (selectedSpace === undefined) {
            return;
        }
        const isMySpace = userId === selectedSpace.createdBy;
        if (selectedSpace.isDefault ||
            selectedSpace.isPremium ||
            (isMySpace && isUltimate)) {
            dispatch(setSelectedSpace(selectedSpace.id));
        }
        else {
            onShowExpiredModal(selectedSpace);
        }
        navigation.navigate('Success', {
            topTitle: 'App changed to:',
            title: `${selectedSpace.isDefault ? 'Primary' : selectedSpace.name} space`,
            subTitle: 'Only the accounts that you have moved to this space will be visible in the app. ',
            buttonText: 'Done',
            onContinue: () => {
                goBack(2);
                return true;
            },
        });
    }, [goBack, isUltimate, onShowExpiredModal, selectedSpace, userId]);
    const onEdit = useCallback((item) => {
        if (item.isDefault) {
            return;
        }
        const isMySpace = userId === item.createdBy;
        if (item.isPremium || (isMySpace && isUltimate)) {
            onEditSpace?.(item);
        }
        else {
            onShowExpiredModal(item);
        }
    }, [isUltimate, onEditSpace, onShowExpiredModal, userId]);
    const contentContainerStyle = useMarginBottom('paddingBottom', HEIGHT + rem(16));
    const onHelpPress = useCallback(() => {
        Links.openKnownLink('spacesHelp');
    }, []);
    const reorderedSpaces = useMemo(() => {
        if (!spaces)
            return [];
        const currentSpace = spaces?.find((space) => space.id === spaceSelection);
        if (currentSpace === undefined)
            return spaces;
        return [
            currentSpace,
            ...spaces.filter((item) => item.id !== currentSpace.id),
        ];
    }, [spaces, spaceSelection]);
    const [isHovering, setIsHovering] = useState(false);
    const handleHoverIn = useCallback(() => {
        setIsHovering(true);
    }, []);
    const handleHoverOut = useCallback(() => {
        setIsHovering(false);
    }, []);
    const renderInfoCard = useCallback(() => {
        if (isWeb) {
            return (<Pressable onPress={onHelpPress} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut}>
          <InfoCard backgroundColor={colors.cards.onDark} colorKey="secondary">
            <Text TextThin-14 Secondary>
              Moving an account to a custom space will remove it from your
              Primary space. You cannot have the same account in 2 spaces.{' '}
              <Text Primary Text-14 underline={isWeb && isHovering}>
                Learn more about spaces
              </Text>
            </Text>
          </InfoCard>
        </Pressable>);
        }
        return (<TouchableBounce onPress={onHelpPress}>
        <InfoCard backgroundColor={colors.cards.onDark} colorKey="secondary">
          <Text TextThin-14 Secondary>
            Moving an account to a custom space will remove it from your Primary
            space. You cannot have the same account in 2 spaces.{' '}
            <Text Primary Text-14>
              Learn more about spaces
            </Text>
          </Text>
        </InfoCard>
      </TouchableBounce>);
    }, [
        colors.cards.onDark,
        handleHoverIn,
        handleHoverOut,
        isHovering,
        onHelpPress,
    ]);
    if (isLoading && !hasSpaces) {
        return (<View style={styles.container}>
        {isLoading && !hasSpaces && (<View testID="my-spaces-loader" style={styles.loader}>
            <Indicator dark/>
          </View>)}
      </View>);
    }
    return (<View style={styles.container}>
      {hasSpaces ? (<ScrollView secondary scrollY={scrollY} contentContainerStyle={contentContainerStyle}>
          <ScreenInfoHeader title={onEditSpace ? 'Edit custom spaces' : 'My spaces'} subTitle={onEditSpace ? '' : 'Edit or create spaces'}/>
          {onEditSpace && (<InfoCard backgroundColor={colors.cards.onDark} colorKey="secondary">
              <Text TextThin-14 Secondary>
                Your primary space can’t be deleted and it’s your default space.
                Feel free to move the accounts to your custom spaces.
              </Text>
            </InfoCard>)}
          <CardView webPadding style={onEditSpace && styles.connectToList}>
            <TouchableHighlight hoverOnDark onPress={onCreateSpace} underlayColor={colors.background.underlay} style={styles.outerWrapper}>
              <>
                <SettingsItemContent>
                  <SettingsItemTitle>
                    {hasSpaces ? 'Add a new Space' : 'Create a Space'}
                  </SettingsItemTitle>
                </SettingsItemContent>
                <SettingsItemRightContent>
                  <View style={styles.iconRow}>
                    <Spacer w={16}/>
                    <Squircle width={rem(32)} height={rem(32)} color={colors.elements.brandLight}>
                      <IconPlus color={colors.text.textOnBrand}/>
                    </Squircle>
                  </View>
                </SettingsItemRightContent>
              </>
            </TouchableHighlight>
          </CardView>
          <View style={[
                styles.listContainer,
                onEditSpace && styles.connectToButton,
            ]}>
            {reorderedSpaces.map((item, index) => (<React.Fragment key={item.id}>
                <SpaceItem space={item} isSelected={(spaceSelection === undefined && item.isDefault) ||
                    item.id === spaceSelection} onEdit={onEditSpace ? () => onEdit(item) : undefined} onAccounts={item.isDefault ? () => onSpaceAccounts(item) : undefined} selectedSpaceId={selectedSpaceId} onSelect={setSelectedSpaceId}/>
                {!onEditSpace && index === 0 && (<Separator style={isWeb && styles.separator}/>)}
              </React.Fragment>))}
          </View>
          <Spacer h={16}/>
          {renderInfoCard()}
        </ScrollView>) : (<FeatureIntroduction title={strings.title} subTitle={strings.subTitle} items={SPACES_INTRO_ITEMS} buttonText="Create a space" onPressNext={onCreateSpace} topIcon={<IconLayers colorKey="brand"/>}/>)}
      {!onEditSpace &&
            selectedSpaceId &&
            selectedSpaceId !== spaceSelection &&
            (selectedSpace?.isDefault || selectedSpace?.name) && (<Button title={`Change to ${selectedSpace?.isDefault ? 'Primary' : selectedSpace?.name} Space`} floating brand onPress={onChangeSpace}/>)}
    </View>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        width: '100%',
        flex: 1,
        alignItems: 'stretch',
    },
    scroll: {
        flex: 1,
    },
    noItemsContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    card: {
        resizeMode: 'contain',
    },
    cardForeground: {
        position: 'absolute',
    },
    defaultSubTitle: {
        fontWeight: '300',
    },
    listContainer: {
        backgroundColor: colors.cards.onDark,
        marginHorizontal: rem(16),
        borderRadius: rem(16),
        ...(isWeb && {
            padding: 8,
        }),
    },
    iconRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    outerWrapper: {
        borderRadius: rem(16),
        overflow: 'hidden',
        backgroundColor: colors.cards.onDark,
        flexDirection: 'row',
        alignItems: 'center',
        padding: rem(16),
    },
    separator: {
        marginVertical: 8,
        marginHorizontal: -8,
    },
    loader: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    connectToList: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginBottom: 0,
    },
    disconnectFromList: { paddingBottom: 8 },
    connectToButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        paddingTop: 0,
    },
}));
export default MySpacesPage;
