import { useState, useEffect, useCallback } from 'react';
import { isAfter, parseISO } from 'date-fns';
import { sha256 } from 'utils/packages/sha256';
import * as Keychain from 'utils/packages/keychain';
import { setPasscodeInKeychainSuccess, verifyPasscode, } from 'actions';
import { KEYCHAIN_SERVICE_HASHED, storePasscode } from 'utils/passcode';
import { useReSelector } from 'utils/hooksApi';
import { useAppDispatch } from 'store/hooks';
import useFetchOld, { muteAllButThrows } from './useFetch';
import Sentry from '../utils/sentry';
import { ThrowableFetchError } from './fetch';
export const usePasscodeVerifier = () => {
    const [, , , fetch] = useFetchOld(muteAllButThrows);
    const dispatch = useAppDispatch();
    const { passCode, userId, pinLastChangedAtLocal, pinLastChangedAtServer } = useReSelector((state) => ({
        passCode: state.user.passCode,
        userId: state.user.user.id,
        pinLastChangedAtLocal: state.user.pinLastChangedAt,
        pinLastChangedAtServer: state.user.user.pinLastChangedAt,
    }));
    const [isOutdated, setOutdated] = useState(false);
    useEffect(() => {
        const isOutdated = pinLastChangedAtLocal !== undefined &&
            pinLastChangedAtLocal !== null &&
            pinLastChangedAtServer !== undefined &&
            pinLastChangedAtServer !== null &&
            pinLastChangedAtLocal !== pinLastChangedAtServer &&
            isAfter(parseISO(pinLastChangedAtServer), parseISO(pinLastChangedAtLocal));
        setOutdated(isOutdated);
    }, [pinLastChangedAtLocal, pinLastChangedAtServer]);
    return [
        useCallback(async (enteredPasscode, forceVerifyOnServer) => {
            if (!isOutdated && !forceVerifyOnServer) {
                try {
                    const [passCodeInKeychain, enteredPasscodeHashed] = await Promise.all([
                        Keychain.getGenericPassword({
                            service: KEYCHAIN_SERVICE_HASHED,
                        }),
                        sha256(`${enteredPasscode}_${userId}`),
                    ]);
                    if (typeof passCodeInKeychain !== 'boolean' &&
                        passCodeInKeychain.username === userId.toFixed(0) &&
                        passCodeInKeychain.password === enteredPasscodeHashed) {
                        return true;
                    }
                }
                catch (e) {
                    Sentry.logError({
                        filename: 'usePasscodeVerifier.ts',
                        message: 'Get generic passcode or sha256 failed',
                        err: e,
                    });
                }
                if (typeof passCode === 'string' && passCode !== '') {
                    // The passcode is still in redux (it hasnt been migrated yet)
                    return enteredPasscode === passCode;
                }
            }
            // outdated OR missing OR incorrect local pin
            let newPinLastChangedAt = pinLastChangedAtServer;
            try {
                const verifyResult = await fetch(verifyPasscode(enteredPasscode));
                if (!verifyResult) {
                    // This should only happen on an invalid access token
                    throw new Error('Could not verify passcode');
                }
                const { isPinValid, pinLastChangedAt } = verifyResult;
                newPinLastChangedAt = pinLastChangedAt;
                if (!isPinValid) {
                    return false;
                }
            }
            catch (e) {
                if (e instanceof ThrowableFetchError) {
                    switch (e.fetchError.apiPayload?.status) {
                        case 403:
                            return false;
                        case 422:
                            // user has no passcode, calling component should force them to make one
                            return null;
                        default:
                            throw e;
                    }
                }
                throw e;
            }
            const result = await storePasscode(enteredPasscode, userId.toFixed(0));
            // since the password is now correct we can set our local pinLastChangedAt to match the server
            if (result) {
                dispatch(setPasscodeInKeychainSuccess(newPinLastChangedAt));
            }
            return true;
        }, [passCode, userId, pinLastChangedAtLocal, pinLastChangedAtServer]),
        isOutdated,
    ];
};
export default usePasscodeVerifier;
