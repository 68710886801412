import React, { useLayoutEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Indicator } from 'design-system/Indicator';
import { SettingsItem, SettingsItemSwitch } from 'design-system/SettingsItem';
import { SettingsSection } from 'design-system/SettingsSection';
import { AppView } from 'design-system/AppView';
import { rem } from 'design-system/values';
import * as Icons from 'design-system/icons';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import Modal from 'utils/packages/Modal';
import { useScrambleMode } from 'hooks';
import useColors from 'hooks/useColors';
import { useSmartRulesNavigation } from 'hooks/useSmartRulesNavigation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useAccelerometerSupport } from 'hooks/useAccelerometerSupport';
import { setDeviceSettingsKey } from 'actions/deviceSettings';
import { selectFeatureFlag } from 'reducers/selectors';
import { selectHasPreApprovedTransfersFeature } from 'features/pre-approved-transfers/selectors';
import { SecuritySettingsSection } from './SecuritySettingsSection';
import { DeleteAccountSettingsSection } from './DeleteAccountSettingsSection';
import { HelpButton } from './AccountSettingsScreen';
import { isWeb, isDesktop } from '../../../constants';
const strings = {
    headerTitle: 'Account',
    personalDetails: {
        title: 'Personal Details',
    },
    scrambleMode: {
        title: 'Scramble Mode',
        description: 'Hide your actual amount',
    },
    dataExport: {
        title: 'Export Your Data',
    },
    fraudDetection: {
        title: 'Fraud Detection',
        description: 'Monitor your emails and phone numbers on the dark web',
    },
    smartRules: {
        title: 'Smart Rules',
    },
    web: {
        title: 'Emma for Web',
    },
    restorePurchases: {
        title: 'Restore in-app purchases',
    },
    bankLogins: {
        title: 'Bank Logins',
    },
    scrambleModal: {
        title: 'Balances and transactions are now hidden',
        description: 'Flip your device to hide/show your balance or use the switcher in Account setting screen.',
        dontShowAgain: "Don't show again",
        ok: 'OK',
    },
    flipToScramble: {
        title: 'Flip to enable scramble mode',
    },
};
export const AccountSettingsList = withAnimatedNavigationHeader(({ navigation, onScroll }) => {
    const dispatch = useAppDispatch();
    const userId = useAppSelector((state) => state.user.user.id);
    // if true, don't show the modal after enabling scramble mode
    const hideScrambleModal = useAppSelector((state) => state.deviceSettings.hideScrambleModal);
    const hasAccelerometerSupport = useAccelerometerSupport();
    const flipToScramble = useAppSelector((state) => state.deviceSettings.flipToScramble);
    useLayoutEffect(() => {
        if (!isWeb) {
            navigation.setOptions({
                headerRight: () => <HelpButton />,
            });
        }
    }, []);
    const colors = useColors();
    const onPressSmartRules = useSmartRulesNavigation();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const onExportYourData = () => navigation.navigate('ExportingPeriod');
    const onPreApprovedTransfers = () => {
        navigation.navigate('PreApprovedTransfers', {});
    };
    const hasPreApprovedTransfersFeature = useAppSelector(selectHasPreApprovedTransfersFeature);
    const { setScrambled, scrambled } = useScrambleMode();
    const handleScrambledChange = (value) => {
        setScrambled(value);
        if (value && !hideScrambleModal) {
            Modal.showConfirmation(strings.scrambleModal.title, strings.scrambleModal.description, strings.scrambleModal.dontShowAgain, strings.scrambleModal.ok, () => {
                dispatch(setDeviceSettingsKey('hideScrambleModal', true));
                Modal.hide();
            }, // Don't show again button
            () => Modal.hide() // OK button
            );
        }
    };
    const webQRCodeScanner = useAppSelector((store) => selectFeatureFlag(store, 'web_qr_code_scanner_in_settings').value);
    if (userId === -1) {
        return (<AppView type="secondary" withFrame={false}>
          <Indicator dark/>
        </AppView>);
    }
    return (<AppView withFrame={false}>
        <ScrollView onScroll={onScroll} style={paddingHorizontalStyle} scrollEventThrottle={16}>
          <SettingsSection noMarginTop style={styles.marginTop}>
            <SettingsItem title={strings.personalDetails.title} onPress={() => navigation.navigate('PersonalDetails')} icon={<Icons.IconProfile />}/>
            <SecuritySettingsSection />
            {!isWeb && webQRCodeScanner && (<SettingsItem title={strings.web.title} onPress={() => {
                navigation.navigate('WebQRCodeScanner');
            }} icon={<Icons.IconScan alt colorKey="brand"/>}/>)}
          </SettingsSection>

          {!isWeb && (<>
              <SettingsSection noMarginTop style={styles.marginTop}>
                <SettingsItemSwitch value={scrambled} title={strings.scrambleMode.title} description={strings.scrambleMode.description} onValueChange={handleScrambledChange} icon={<Icons.IconMagicPen color={colors.status.positive}/>}/>
                {!isDesktop && hasAccelerometerSupport && (<SettingsItemSwitch value={flipToScramble} title={strings.flipToScramble.title} onValueChange={(value) => dispatch(setDeviceSettingsKey('flipToScramble', value))} icon={<Icons.IconDoubleRotateBold gradientKey="peach"/>}/>)}
              </SettingsSection>

              <SettingsSection noMarginTop style={styles.marginTop}>
                <SettingsItem title={strings.dataExport.title} onPress={onExportYourData} icon={<Icons.IconDownload />}/>
                <SettingsItem title={strings.smartRules.title} onPress={onPressSmartRules} icon={<Icons.IconLightBulb alt gradientColors={colors.gradients.plus.colors}/>}/>
                {hasPreApprovedTransfersFeature && (<SettingsItem title={'Pre-approved transfers'} onPress={onPreApprovedTransfers} icon={<Icons.IconMoneyCheck gradientKey="darkPink"/>}/>)}
                <SettingsItem title={strings.bankLogins.title} onPress={() => navigation.navigate('Logins', {})} icon={<Icons.IconBank gradientKey="lightPurple"/>}/>
              </SettingsSection>
            </>)}
          <DeleteAccountSettingsSection />
        </ScrollView>
      </AppView>);
}, ({ colors }) => ({
    title: strings.headerTitle,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    marginTop: {
        marginTop: rem(16),
    },
});
