import React from 'react';
import { View, StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import RowAccount from 'features/accounts/components/RowAccount';
const SelectedAccountItem = ({ account, onPress }) => (<View style={styles.container}>
    {account.id ? (<View style={styles.item}>
        <RowAccount onCard style={styles.rowAccount} data={account} isSelectingAccounts onPress={onPress}/>
      </View>) : null}
  </View>);
const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
    },
    item: {
        overflow: 'hidden',
    },
    rowAccount: {
        paddingHorizontal: rem(16),
    },
});
export default SelectedAccountItem;
