import React, { memo, useMemo } from 'react';
import { View, StyleSheet, TouchableBounce, TouchableHighlight, } from 'react-native';
import Text from 'design-system/Text';
import Spacer from 'design-system/Spacer';
import { IconArrow } from 'design-system';
import { rem } from 'design-system/values';
import TouchableBounceHighlight from 'design-system/TouchableBounceHighlight';
import LogoBankAccount from 'design-system/LogoBankAccount';
import BlurAmountView from 'design-system/BlurAmountView';
import RowAccount from 'features/accounts/components/RowAccount';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppSelector } from 'store/hooks';
import Logo from './Logo';
const AccountCard = ({ style, onCard, isMultipleSelect, ...props }) => {
    const scrambled = useAppSelector((store) => store.utils.scrambled);
    const colors = useColors();
    const styles = useStyles(styleSet);
    const content = useMemo(() => {
        if ('data' in props) {
            const { data, white, transparent, borderRadius, backgroundColor, tintColor, alt, withBorder, isSelected, } = props;
            return (<View style={[
                    styles.container,
                    alt && styles.altContainer,
                    white && styles.white,
                    transparent && styles.transparent,
                    borderRadius !== undefined && { borderRadius },
                    backgroundColor !== undefined && { backgroundColor },
                    withBorder && styles.withBorder,
                    style,
                ]}>
          <View style={styles.rowAccountContainer}>
            <View style={styles.flex}>
              <RowAccount data={data} onCard={onCard} primaryBalanceOnly tintColor={tintColor} isSelectingAccounts selected={isSelected} isMultipleSelect={isMultipleSelect} logo={<LogoBankAccount onCard={onCard} square={!alt} white={!white} data={data} showHidden={props.showHiddenLogo}/>} disabled/>
            </View>
            {!!props?.showArrow && (<>
                <Spacer w={10}/>
                <IconArrow />
              </>)}
          </View>
        </View>);
        }
        const { investConnectedAccount, white, textOnly } = props;
        if (textOnly) {
            return (<View style={[styles.textOnlyRow, styles.centered]}>
          <Text TextThin-14 {...(white ? { White: true } : { Secondary: true })}>
            Bank account{' '}
          </Text>
          <BlurAmountView enabled={scrambled}>
            <Text TextThin-14 {...(white ? { White: true } : { Secondary: true })}>
              {`${investConnectedAccount.ukSortCode.replace(/(\d\d)(\d\d)(\d\d)/, '$1-$2-$3')} ${investConnectedAccount.ukAccountNumber}`}
            </Text>
          </BlurAmountView>
        </View>);
        }
        return (<View style={[styles.investAccountContainer, white && styles.white]}>
        {!!investConnectedAccount.bankIconUrl && (<>
            <Logo R44 internalPadding uri={investConnectedAccount.bankIconUrl}/>
            <Spacer w={16}/>
          </>)}
        <View style={[styles.accountInfoContainer, styles.row, style]}>
          <BlurAmountView enabled={scrambled}>
            <Text Text-16 numberOfLines={1}>
              {`${investConnectedAccount.ukSortCode.replace(/(\d\d)(\d\d)(\d\d)/, '$1-$2-$3')} ${investConnectedAccount.ukAccountNumber}`}
            </Text>
          </BlurAmountView>
        </View>
      </View>);
    }, [
        props,
        styles.investAccountContainer,
        styles.white,
        styles.accountInfoContainer,
        styles.row,
        styles.container,
        styles.altContainer,
        styles.transparent,
        styles.withBorder,
        styles.rowAccountContainer,
        styles.flex,
        styles.textOnlyRow,
        styles.centered,
        style,
        scrambled,
        onCard,
        isMultipleSelect,
    ]);
    if ('onPress' in props) {
        if ('alt' in props && props.alt) {
            if (props.onPress && props.bounceHighlight) {
                return (<TouchableBounceHighlight onPress={props.onPress}>
            {content}
          </TouchableBounceHighlight>);
            }
            return (<TouchableHighlight activeOpacity={1} onPress={props.onPress} underlayColor={colors.background.underlay}>
          {content}
        </TouchableHighlight>);
        }
        return <TouchableBounce onPress={props.onPress}>{content}</TouchableBounce>;
    }
    return content;
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: rem(16),
        borderRadius: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    investAccountContainer: {
        padding: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    withBorder: {
        borderWidth: 1,
        borderColor: colors.cards.onDark,
    },
    altContainer: {
        borderRadius: 0,
        backgroundColor: colors.cards.onDark,
    },
    accountIcon: {
        width: rem(32),
        height: rem(32),
        borderRadius: rem(8),
        backgroundColor: colors.background.dark,
        alignSelf: 'center',
    },
    accountIconOnWhite: {
        backgroundColor: colors.cards.onLight,
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: colors.borders.logoStroke,
    },
    accountInfoContainer: {
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    textOnlyRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: rem(-4),
    },
    centered: {
        justifyContent: 'center',
    },
    white: {
        backgroundColor: colors.cards.onDark,
    },
    transparent: {
        backgroundColor: 'transparent',
    },
    dot: {
        width: rem(4),
        height: rem(4),
        borderRadius: rem(2),
        backgroundColor: colors.text.primary,
        marginRight: rem(8),
    },
    flex: {
        flex: 1,
    },
    rowAccountContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));
export default memo(AccountCard);
