import Suggestions from 'design-system/suggestions/Suggestions';
import React from 'react';
const SUGGESTIONS_DATA = [
    {
        name: 'Personal',
    },
    {
        name: 'Joint',
    },
    {
        name: 'Business',
    },
    {
        name: 'Family',
    },
];
const SpaceNameSuggestions = ({ name, handleSuggestionPress, suggestedData, }) => (<Suggestions title="Suggestions" data={SUGGESTIONS_DATA} name={name} handleSuggestionPress={handleSuggestionPress} suggestedData={suggestedData} oneLine/>);
export default SpaceNameSuggestions;
