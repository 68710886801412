import Config from 'utils/packages/configs';
import 'es6-symbol/implement';
import { Platform } from 'react-native';
import { formatISO, addSeconds, isBefore, parseISO } from 'date-fns';
import sentry from 'utils/sentry';
import { encode } from 'base-64';
import { emojify } from 'node-emoji';
import form from 'utils/api/form';
import { getClientId } from 'utils/clientId';
import Facebook from 'utils/facebook';
import { getItems, removeTokens, setTokens } from 'utils/jwtHelpers';
import * as actionsTypes from '../actions/types';
import { appVersion, isWeb } from '../constants';
// eslint-disable-next-line import/no-extraneous-dependencies
const uuidV4 = require('uuid/v4');
const debug = __DEV__ && true; // feel free to turn this on to see all network logs
const onlyRequest = __DEV__ && true; // turn this on if you don't want to see response bodies
const debugUrlFilter = /.*/; // filter logs by any regexp here
// We try all api that are `GET` X times in case of errors
const NB_API_TRY_DEFAULT = 2;
// WARNING: don't set any values below to zero, otherwise those API requests will be ignored.
const NB_API_TRY_OVERWRITE = {
    [actionsTypes.GET_FEED_REQUEST]: 3,
};
export const TOKEN_LIFETIME_SAFETY_MARGIN_SEC = 15; // this includes network time for 2 processes: from server to client when creating token, and from client to server when using it
async function getTokenRequestOptions(params) {
    const [[, client_id], [, refresh_token]] = await getItems([
        'client_id',
        'refresh_token',
    ]);
    if (refresh_token === null) {
        return null;
    }
    const method = 'POST';
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const scopes = ['offline_access'];
    if (params?.scope) {
        scopes.push(params.scope);
    }
    const optionalPin = {};
    if (params?.pin) {
        optionalPin.pin = params.pin;
    }
    const body = form({
        grant_type: params?.scope ? 'escalate_scope' : 'refresh_token',
        client_id,
        scope: scopes.join(' '),
        refresh_token,
        ...optionalPin,
    });
    return {
        method,
        body,
        headers,
    };
}
// false is returned when refresh token is expired. User has to relogin, and there is no point of calling this function within the next few seconds at least
async function getNewTokens(options) {
    let res = null;
    try {
        let params;
        if (options?.pin && options?.scope) {
            params = { pin: options.pin, scope: options.scope };
        }
        const opts = await getTokenRequestOptions(params);
        if (opts === null) {
            return false;
        }
        res = await fetch(`${Config.API_URL}/oauth/token`, opts);
    }
    catch (e) {
        if (options?.next && options?.errorType) {
            options.next({
                extra: options.extra,
                type: options.errorType,
                error: 'No network',
                errorType: 'FetchError',
            });
        }
        return null;
    }
    if (res && res.ok) {
        try {
            const data = await res.json();
            if (data.refresh_token && data.access_token) {
                const tokenLifetimeInSeconds = (data.expires_in ?? 25) - TOKEN_LIFETIME_SAFETY_MARGIN_SEC;
                const timestamp = formatISO(addSeconds(new Date(), tokenLifetimeInSeconds));
                log(`New ${options?.pin ? 'escalated ' : ''}token will expire in ${tokenLifetimeInSeconds} seconds`, false);
                await setTokens(data.access_token, data.refresh_token, timestamp);
                return [data.access_token, timestamp, data.refresh_token];
            }
            sentry.logError({
                message: 'Refresh token or access token is missing in response of oauth/token',
                fingerprint: ['middleware', 'api', 'getNewTokens'],
                data,
            });
        }
        catch (error) {
            // in JSON conversion, which means API error, or access_token/refresh_token was not found in response
            sentry.logError({
                message: '/oauth/token: response is not JSON',
                fingerprint: ['middleware', 'api', 'getNewTokens'],
                err: error,
            });
        }
    }
    if (options?.next && options?.errorType) {
        options.next({
            extra: options.extra,
            type: options.errorType,
            errorType: 'ApiError',
            payload: res,
        });
    }
    if (res.status === 403) {
        if (!options?.pin && !options?.scope) {
            removeTokens();
        }
        return false; // false signals the caller to avoid calling this function again
    }
    return null;
}
async function* getAccessTokenFromStorageGenerator() {
    let refreshToken; // undefined means we need to try to get it from AsyncStorage
    let accessToken = null;
    let timestamp = null;
    let next;
    let errorType;
    let pin = null;
    let scope = null;
    let extra = null;
    try {
        [[, refreshToken], [, accessToken], [, timestamp]] = await getItems([
            'refresh_token',
            'access_token',
            'expiration_date',
        ]);
    }
    catch {
        // no worries - we will try again below
    }
    const processInput = (params) => {
        if (params.accessToken && params.timestamp && params.refreshToken) {
            accessToken = params.accessToken;
            refreshToken = params.refreshToken;
            timestamp = params.timestamp;
            return true;
        }
        if (params.refreshToken) {
            refreshToken = params.refreshToken;
            return true;
        }
        if (params.next) {
            next = params.next;
        }
        if (params.extra) {
            extra = params.extra;
        }
        else {
            extra = null;
        }
        if (params.errorType) {
            errorType = params.errorType;
        }
        if (params.pin && params.scope) {
            pin = params.pin;
            scope = params.scope;
        }
        else {
            pin = null;
            scope = null;
        }
        return false;
    };
    const showLogoutAlert = () => {
        if (next) {
            next({
                extra,
                type: errorType,
                errorType: 'SessionExpired',
                error: 'Session expired',
                errorMessage: emojify('Your session has expired. Please login again. :pray:'),
                requestTimestamp: String(new Date().getTime()),
                errorDisplayModal: true,
            });
        }
    };
    // Any generator needs to be started.
    // A generator is started when somebody first calls it
    // When they do call us for the very first time, we run until the first `yield` (to the right of `yield`)
    // Left part then receives parameters passed via next: `.next(parameters)`.
    // We catch these parameters below, and they are our internal state from now on
    // `next` can even be cached since it never changes
    // But `errorType` will be overwritten every time
    processInput(yield null);
    // Now we are called from within api middleware, which passed `next` to us
    while (true) {
        if (refreshToken === null) {
            // log('SKIPPING. 403 occured recently');
            showLogoutAlert();
            processInput(yield null);
            // eslint-disable-next-line no-continue
            continue;
        }
        if (accessToken === null || timestamp === null) {
            try {
                // loops with await are a regular thing in async generators
                // eslint-disable-next-line no-await-in-loop
                [[, accessToken], [, timestamp]] = await getItems([
                    'access_token',
                    'expiration_date',
                ]);
            }
            catch (error) {
                // it will just go fetch a new token below
            }
        }
        if (accessToken &&
            timestamp &&
            isBefore(new Date(), parseISO(timestamp)) &&
            !pin) {
            // We return (yield) access_token
            // And upon next invocation, we "eat" passed parameters
            processInput(yield accessToken);
        }
        else {
            try {
                // loops with await are a regular thing in async generators
                // eslint-disable-next-line no-await-in-loop
                const newTokens = await getNewTokens({
                    next,
                    errorType,
                    pin,
                    scope,
                    extra,
                });
                if (newTokens === null || newTokens === false) {
                    if (newTokens === false && !pin && !scope) {
                        // dont null it if the request token had a pin
                        refreshToken = null; // we don't have a valid refresh token, and user was already asked to relogin
                    }
                    // same thing happens here. `yield` is assymetric
                    processInput(yield null);
                }
                else {
                    [accessToken, timestamp, refreshToken] = newTokens;
                    processInput(yield accessToken);
                }
            }
            catch (error) {
                processInput(yield null);
            }
        }
    }
}
export const getAccessToken = getAccessTokenFromStorageGenerator(); // this can be easily reused, just supply `dispatch` instead of `next`
getAccessToken.next(); // start up generator
const apiMiddleware = ({ getState }) => (next) => async (action) => {
    const callAPI = action[CALL_API];
    const { spaces: { selectedSpace: spaceId }, } = getState();
    if (typeof callAPI === 'undefined') {
        return next(action);
    }
    const { method, body, types, extra, pin, scope, noAccessToken, muteAlert, muteAlertForToken, } = callAPI;
    const [requestType, successType, errorType] = types;
    next({ type: requestType, extra });
    let accessToken = null;
    if (!noAccessToken) {
        accessToken = (await getAccessToken.next({
            next: (res) => next({
                ...res,
                // We must pass through actual API method/muteAlert so the error handler follows the logic of whether or not to show the modal
                method,
                muteAlert,
                muteAlertForToken,
            }),
            errorType,
            pin,
            extra,
            scope,
        })).value;
        if (!accessToken || accessToken === 'error') {
            if (pin && scope) {
                // if pin shall we return and then calling component can show passcode?
                return {
                    type: errorType,
                    errorType: 'PinError',
                    extra,
                    request: requestType,
                    method,
                    muteAlert,
                };
            }
            log('ERROR: access_token', false, 'red'); // which means: AsyncStorage issue or auth API issue or refresh token is expired and user has to relogin
            return undefined;
        }
    }
    // Setup headers for the api call
    const { endpoint, headers = {}, addSpaceIdHeader } = callAPI;
    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }
    headers['Client-Date'] = new Date().toString();
    headers['X-Request-ID'] = uuidV4();
    if (!isWeb) {
        headers['app-version'] = appVersion;
        try {
            headers['Emma-Client-Data'] = encode(JSON.stringify(await Facebook.getAnalyticsInfo()));
        }
        catch (e) {
            log('Unable to write Emma-Client-Data header');
        }
    }
    headers.requestTimestamp = String(new Date().getTime());
    headers.Platform = Platform.OS;
    try {
        headers['Emma-Client-Id'] = await getClientId();
    }
    catch (e) {
        log('Unable to write Emma-Client-Id header');
    }
    if (spaceId && addSpaceIdHeader) {
        headers.space = spaceId.toString();
    }
    // Execute a single api call and return data from it
    const callApi = async () => {
        let res = null;
        if (debug && endpoint.match(debugUrlFilter)) {
            log(`${method} ${endpoint}`, false);
            if (body) {
                try {
                    log(JSON.parse(body));
                }
                catch (error) {
                    // no op
                }
            }
        }
        try {
            res = await fetch(Config.API_URL + endpoint, { method, body, headers });
        }
        catch (e) {
            return {
                type: errorType,
                error: 'No network',
                errorType: 'FetchError',
                request: requestType,
                muteAlert,
                extra,
            };
        }
        if (res && res.ok) {
            try {
                const { headers, ok, status, statusText, type, url } = res;
                const data = await res.json();
                if (debug && endpoint.match(debugUrlFilter)) {
                    if (!onlyRequest) {
                        if (endpoint.match(debugUrlFilter))
                            log(data, true, 'green');
                    }
                    log(`${method} ${endpoint}: ${JSON.stringify(data).length}`, false, 'green');
                }
                return {
                    apiPayload: { headers, ok, status, statusText, type, url },
                    type: successType,
                    payload: data,
                    extra,
                };
            }
            catch (e) {
                // which means response was not a JSON - will be considered below as API error
            }
        }
        if (debug && endpoint.match(debugUrlFilter)) {
            log(`NOT OK: ${method} ${endpoint}`, false, 'red');
            try {
                const text = await res.clone().text();
                if (text) {
                    try {
                        const data = JSON.parse(text);
                        log(`\n${JSON.stringify(data, null, '  ')}`, false, 'yellow');
                        log(`access_token was: ${accessToken}`, false);
                    }
                    catch (typeError) {
                        log(`\n${text}`, false, 'yellow');
                    }
                }
                else {
                    log(`\nEMPTY RESPONSE`, false, 'yellow');
                }
            }
            catch (error) {
                log(`\nCOULD NOT SHOW RESPONSE: ${error}`, false, 'yellow');
            }
        }
        return {
            type: errorType,
            errorType: 'ApiError',
            payload: res,
            extra,
            request: requestType,
            method,
            muteAlert,
        };
    };
    // Loops NB_API_TRY times for each call in case of error (default to 1 time only)
    const nbTry = NB_API_TRY_OVERWRITE[requestType] || NB_API_TRY_DEFAULT;
    for (let i = 0; i < nbTry; i++) {
        const result = await callApi(); // eslint-disable-line no-await-in-loop
        const status = result && result.payload && result.payload.status
            ? result.payload.status.toString()
            : '';
        // Retry only if call is: GET and http status is 5XX
        if (method !== 'GET' ||
            (result && result.errorType !== 'ApiError') ||
            status.charAt(0) !== '5' ||
            i === nbTry - 1) {
            return next(result);
        }
    }
    log('You should never put 0 into NB_API_TRY_OVERWRITE', false, 'red');
    sentry.logError({
        message: 'You should never put 0 into NB_API_TRY_OVERWRITE',
        filename: 'middleware/api',
        data: NB_API_TRY_OVERWRITE,
        fingerprint: ['middleware', 'api', 'NB_API_TRY_OVERWRITE'],
    });
    return undefined; // never gets here, unless somebody sets NB_API_TRY_OVERWRITE to zero. This is to satisfy typescript
};
export const CALL_API = Symbol('Call API');
export { apiMiddleware };
