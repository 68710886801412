import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import { IconWithGradient } from 'design-system/icons/Icon';
export const IconTwoUser = (props) => {
    const id = useId();
    if (props.alt) {
        return (<IconWithGradient id={id} {...props} defaultGradientKey="pro">
        <Path d="M5.30769 7.88571C5.30769 5.73969 7.06411 4 9.23077 4C11.3974 4 13.1538 5.73969 13.1538 7.88571C13.1538 10.0317 11.3974 11.7714 9.23077 11.7714C7.06411 11.7714 5.30769 10.0317 5.30769 7.88571Z" fill={`url(#gradient_${id})`}/>
        <Path d="M6.75123 13.5261L6.91563 13.5001C8.44935 13.2577 10.0122 13.2577 11.5459 13.5001L11.7103 13.5261C13.8714 13.8677 15.4615 15.714 15.4615 17.8816C15.4615 19.0516 14.504 20 13.3228 20H5.13874C3.95755 20 3 19.0516 3 17.8816C3 15.714 4.59016 13.8677 6.75123 13.5261Z" fill={`url(#gradient_${id})`}/>
        <Path d="M14.7692 4C14.3869 4 14.0769 4.307 14.0769 4.68571C14.0769 5.06442 14.3869 5.37143 14.7692 5.37143C16.1712 5.37143 17.3077 6.49711 17.3077 7.88571C17.3077 9.27432 16.1712 10.4 14.7692 10.4C14.3869 10.4 14.0769 10.707 14.0769 11.0857C14.0769 11.4644 14.3869 11.7714 14.7692 11.7714C16.9359 11.7714 18.6923 10.0317 18.6923 7.88571C18.6923 5.73969 16.9359 4 14.7692 4Z" fill={`url(#gradient_${id})`}/>
        <Path d="M15.9149 13.4916C15.5326 13.4916 15.2226 13.7986 15.2226 14.1773C15.2226 14.556 15.5326 14.863 15.9149 14.863H16.8086C16.8829 14.863 16.9573 14.8688 17.0306 14.8804C18.5197 15.1158 19.6154 16.388 19.6154 17.8816C19.6154 18.2942 19.2778 18.6286 18.8613 18.6286H16.9753C16.5929 18.6286 16.283 18.9356 16.283 19.3143C16.283 19.693 16.5929 20 16.9753 20H18.8613C20.0425 20 21 19.0516 21 17.8816C21 15.714 19.4098 13.8677 17.2488 13.5261C17.1032 13.5031 16.9559 13.4916 16.8086 13.4916H15.9149Z" fill={`url(#gradient_${id})`}/>
      </IconWithGradient>);
    }
    return (<IconWithGradient id={id} {...props} defaultGradientKey="pro">
      <Path fillRule="evenodd" clipRule="evenodd" d="M14.2124 7.76241C14.2124 10.4062 12.0489 12.5248 9.34933 12.5248C6.6507 12.5248 4.48631 10.4062 4.48631 7.76241C4.48631 5.11865 6.6507 3 9.34933 3C12.0489 3 14.2124 5.11865 14.2124 7.76241ZM2 17.9174C2 15.47 5.38553 14.8577 9.34933 14.8577C13.3347 14.8577 16.6987 15.4911 16.6987 17.9404C16.6987 20.3877 13.3131 21 9.34933 21C5.364 21 2 20.3666 2 17.9174ZM16.1734 7.84875C16.1734 9.19506 15.7605 10.4513 15.0364 11.4948C14.9611 11.6021 15.0276 11.7468 15.1587 11.7698C15.3407 11.7995 15.5276 11.8177 15.7184 11.8216C17.6167 11.8704 19.3202 10.6736 19.7908 8.87118C20.4885 6.19676 18.4415 3.79543 15.8339 3.79543C15.5511 3.79543 15.2801 3.82418 15.0159 3.87688C14.9797 3.88454 14.9405 3.90179 14.921 3.93246C14.8955 3.97174 14.9141 4.02253 14.9396 4.05607C15.7233 5.13216 16.1734 6.44206 16.1734 7.84875ZM19.3173 13.7023C20.5932 13.9466 21.4317 14.444 21.7791 15.1694C22.0736 15.7635 22.0736 16.4534 21.7791 17.0475C21.2478 18.1705 19.5335 18.5318 18.8672 18.6247C18.7292 18.6439 18.6186 18.5289 18.6333 18.3928C18.9738 15.2805 16.2664 13.8048 15.5658 13.4656C15.5364 13.4493 15.5296 13.4263 15.5325 13.411C15.5345 13.4014 15.5472 13.3861 15.5697 13.3832C17.0854 13.3545 18.7155 13.5586 19.3173 13.7023Z" fill={`url(#gradient_${id})`}/>
    </IconWithGradient>);
};
