import React, { useId } from 'react';
import { Path } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from './Icon';
const IconCopy = (props) => {
    const id = useId();
    const colors = useColors();
    return (<IconWithGradient id={id} {...props} defaultGradientKey="ultimate">
      <Path fill={props.fill ?? colors.text.secondary} d="M21.414 6.49999L18 3.08594C17.8148 2.8996 17.5944 2.75187 17.3517 2.65129C17.1089 2.55071 16.8486 2.49929 16.5859 2.50001H11C9.93948 2.50114 8.92272 2.92292 8.17281 3.67282C7.42291 4.42272 7.00111 5.43947 6.99997 6.49999H5.99998C4.93947 6.50112 3.92271 6.92291 3.17282 7.67281C2.42292 8.42271 2.00113 9.43946 2 10.5V18.5C2.00113 19.5605 2.42292 20.5773 3.17282 21.3272C3.92271 22.0771 4.93947 22.4988 5.99998 22.5H13C14.0605 22.4988 15.0772 22.0771 15.8272 21.3272C16.5771 20.5773 16.9989 19.5605 17 18.5H18C19.0605 18.4989 20.0773 18.0771 20.8272 17.3272C21.5771 16.5773 21.9988 15.5605 22 14.5V7.91407C21.9985 7.38393 21.788 6.87577 21.414 6.49999ZM17 4.91407L19.5859 7.49998H18.2651C17.9298 7.49968 17.6082 7.36636 17.371 7.12927C17.1339 6.89218 17.0004 6.57069 17 6.23534V4.91407ZM13 20.5H5.99998C5.46973 20.4994 4.96138 20.2885 4.58643 19.9135C4.21149 19.5386 4.00058 19.0302 3.99997 18.5V10.5C4.00058 9.96973 4.21149 9.46138 4.58643 9.08644C4.96138 8.7115 5.46974 8.5006 5.99998 8.50001H6.99997V14.5C7.00111 15.5605 7.42291 16.5773 8.17281 17.3272C8.92272 18.0771 9.93948 18.4989 11 18.5H15C14.9994 19.0302 14.7885 19.5386 14.4135 19.9135C14.0386 20.2885 13.5302 20.4994 13 20.5ZM18 16.5H11C10.4698 16.4994 9.9614 16.2885 9.58645 15.9135C9.2115 15.5386 9.00059 15.0303 8.99998 14.5V6.49999C9.00059 5.96974 9.2115 5.46138 9.58645 5.08644C9.96139 4.71149 10.4697 4.50058 11 4.49998H15V6.23534C15.0011 7.10091 15.3455 7.93072 15.9576 8.54273C16.5697 9.15474 17.3995 9.499 18.2651 9.49999H20V14.5C19.9994 15.0303 19.7885 15.5386 19.4135 15.9135C19.0386 16.2885 18.5302 16.4994 18 16.5Z"/>
    </IconWithGradient>);
};
export default IconCopy;
