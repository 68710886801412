import React, { memo, useCallback, useMemo } from 'react';
import { View, ScrollView } from 'react-native';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { selectSortedCustomCategories } from 'features/transactions/selectors';
import { isWeb } from '../../../../constants';
const CategoryMenu = ({ openItem, onSelect, onClose }) => {
    const categories = useAppSelector((store) => store.user.categories);
    const customCategories = useAppSelector(selectSortedCustomCategories);
    const data = useMemo(() => (categories ? customCategories.concat(categories) : customCategories), [categories, customCategories]);
    const styles = useStyles(styleSet);
    const colors = useColors();
    const select = useCallback((idx) => {
        onSelect(data[idx], openItem.id);
        onClose();
    }, [data, onClose, onSelect, openItem.id]);
    const content = useMemo(() => {
        const options = data.map((item, index) => {
            const isSelected = openItem.selected.id === data[index].id;
            return (<TouchableHighlight onCard hoverOnLight key={item.id} activeOpacity={1} onPress={() => select(index)} underlayColor={colors.background.underlay} style={isSelected && isWeb && styles.selectedStyles}>
          <View style={styles.itemRow}>
            <Text Text-16 OffBlack>
              {item.displayName}
            </Text>
          </View>
        </TouchableHighlight>);
        });
        return (<ScrollView showsVerticalScrollIndicator={false} overScrollMode="never">
        {options}
      </ScrollView>);
    }, [
        colors.background.underlay,
        data,
        openItem.selected.id,
        select,
        styles.itemRow,
        styles.selectedStyles,
    ]);
    if (isWeb) {
        const height = data.length > 4 ? 200 : undefined;
        return <View style={{ height }}>{content}</View>;
    }
    return content;
};
const styleSet = createStyleSheets((colors) => ({
    itemRow: {
        flexDirection: 'row',
        paddingVertical: rem(16),
        paddingHorizontal: rem(isWeb ? 8 : 16),
    },
    selectedStyles: {
        backgroundColor: colors.control.hoverOnLight,
    },
}));
export default memo(CategoryMenu);
