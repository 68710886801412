import React, { useCallback, useLayoutEffect } from 'react';
import useColors from 'hooks/useColors';
import StatusBar from 'design-system/StatusBar';
import { SpaceNamePage } from './containers';
const CreateSpaceName = ({ navigation, }) => {
    const onNext = useCallback((space) => {
        navigation.navigate('CreateSpaceAccounts', { spaceInfo: { space } });
    }, []);
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Space name',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    return (<>
      <StatusBar barStyle="dark-content" animated/>
      <SpaceNamePage onSubmitted={onNext} buttonTitle="Next"/>
    </>);
};
export default CreateSpaceName;
