import { useCallback } from 'react';
import Links from 'utils/links';
import Energy from 'utils/energy';
import { getEnquiries } from 'actions';
import { useAppDispatch, useAppStore } from 'store/hooks';
import checkFeatureFlag from 'utils/featureFlags';
import { selectFeatureFlags, selectUser } from 'reducers/selectors';
import { loanTypeReasonValue } from 'features/borrow/monevo/models';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import QuotesModal from './components/QuotesModal';
const useLoans = () => {
    const dispatch = useAppDispatch();
    const navigation = useMainStackNavigation();
    const store = useAppStore();
    const callback = useCallback((type) => {
        const featureFlags = selectFeatureFlags(store.getState());
        const user = selectUser(store.getState());
        const { guessedHomeCountry } = user;
        const dispatchGetEnquiries = (id) => dispatch(getEnquiries(id));
        const onLoans = (url, type) => {
            const flow = guessedHomeCountry === 'GB' ? 'monevo' : 'monevo_us';
            if (url) {
                QuotesModal.show(url, flow, type);
            }
            else if (type === 'CREDIT_CARD') {
                navigation.navigate('UserInfo', {
                    flow,
                    type,
                    loan_purpose: loanTypeReasonValue.CREDIT_CARD,
                });
            }
            else {
                navigation.navigate('LoanAmount', { flow, type });
            }
        };
        if (guessedHomeCountry === 'GB') {
            if (checkFeatureFlag(featureFlags, 'monevo_api')) {
                Energy.getLoans(dispatchGetEnquiries, 'MONEVO_UK', onLoans, type);
            }
            else {
                Links.openLink('https://emma.monevo.co.uk/');
            }
        }
        // We are only showing in GB for now, but this logic
        // else if (
        //   featureFlags.monevo_us_api &&
        //   featureFlags.monevo_us_api.value === 'yes'
        // ) {
        //   Energy.getLoans(dispatchGetEnquiries as any, 'MONEVO_US', onLoans);
        //   return;
        // } else {
        //   Links.openLink('https://emma.monevo.us/?campaign_code=cF1ZawMGCwdHTHw');
        // }
    }, []);
    return callback;
};
export default useLoans;
