export var Exchange;
(function (Exchange) {
    Exchange["AMEX"] = "AMEX";
    Exchange["ARCA"] = "ARCA";
    Exchange["BATS"] = "BATS";
    Exchange["NYSE"] = "NYSE";
    Exchange["NASDAQ"] = "NASDAQ";
    Exchange["NYSEARCA"] = "NYSEARCA";
})(Exchange || (Exchange = {}));
export var StaticCollectionIds;
(function (StaticCollectionIds) {
    StaticCollectionIds["MOST_POPULAR"] = "MOST_POPULAR";
    StaticCollectionIds["MOST_WATCHED"] = "MOST_WATCHED";
    StaticCollectionIds["BIGGEST_LOSERS"] = "BIGGEST_LOSERS";
    StaticCollectionIds["BIGGEST_GAINERS"] = "BIGGEST_GAINERS";
    StaticCollectionIds["HIGHEST_EARNERS"] = "HIGHEST_EARNERS";
    StaticCollectionIds["HIGHEST_MARKET_CAP"] = "HIGHEST_MARKET_CAP";
    StaticCollectionIds["HIGHEST_DIVIDEND_YIELD"] = "HIGHEST_DIVIDEND_YIELD";
    StaticCollectionIds["NUMBER_OF_EMPLOYEES"] = "HIGHEST_NUMBER_OF_EMPLOYEES";
})(StaticCollectionIds || (StaticCollectionIds = {}));
export var AssetFilterCategory;
(function (AssetFilterCategory) {
    AssetFilterCategory["NAME"] = "NAME";
    AssetFilterCategory["SYMBOL"] = "SYMBOL";
    AssetFilterCategory["PRICE"] = "PRICE";
    AssetFilterCategory["PRICE_CHANGE"] = "PRICE_CHANGE";
})(AssetFilterCategory || (AssetFilterCategory = {}));
export var AssetStateCache;
(function (AssetStateCache) {
    AssetStateCache[AssetStateCache["SYNCED"] = 0] = "SYNCED";
    AssetStateCache[AssetStateCache["ADDING"] = 1] = "ADDING";
    AssetStateCache[AssetStateCache["REMOVING"] = 2] = "REMOVING";
})(AssetStateCache || (AssetStateCache = {}));
export var KYCDocumentType;
(function (KYCDocumentType) {
    KYCDocumentType["PASSPORT"] = "Passport";
    KYCDocumentType["DRIVING_LICENCE"] = "Driving Licence";
    KYCDocumentType["PROVISIONAL_DRIVING_LICENCE"] = "Provisional Driving Licence";
    KYCDocumentType["NATIONAL_ID"] = "National ID";
    KYCDocumentType["UK_BIOMETRIC_RESIDENCE_PERMIT"] = "Residence Permit";
})(KYCDocumentType || (KYCDocumentType = {}));
var PendingTopupStatus;
(function (PendingTopupStatus) {
    PendingTopupStatus["Pending"] = "PENDING";
    PendingTopupStatus["UnderReview"] = "UNDER_REVIEW";
})(PendingTopupStatus || (PendingTopupStatus = {}));
export var SelectedPositionFilter;
(function (SelectedPositionFilter) {
    SelectedPositionFilter["TOTAL_VALUE"] = "Total value";
    SelectedPositionFilter["GAIN_LOSS"] = "Gain / loss";
    SelectedPositionFilter["RETURN_PERCENT"] = "Return %";
})(SelectedPositionFilter || (SelectedPositionFilter = {}));
export var FinancialsPeriod;
(function (FinancialsPeriod) {
    FinancialsPeriod["ANNUAL"] = "Annual";
    FinancialsPeriod["QUARTERLY"] = "Quarterly";
})(FinancialsPeriod || (FinancialsPeriod = {}));
export var OrderType;
(function (OrderType) {
    OrderType["LIMIT"] = "limit";
    OrderType["MARKET"] = "market";
    OrderType["RECURRING"] = "recurring";
})(OrderType || (OrderType = {}));
export var SellOrderType;
(function (SellOrderType) {
    SellOrderType["MARKET"] = "market";
    SellOrderType["LIMIT"] = "limit";
    SellOrderType["STOP"] = "stop";
})(SellOrderType || (SellOrderType = {}));
export var LimitStopOrderExpiryDateType;
(function (LimitStopOrderExpiryDateType) {
    LimitStopOrderExpiryDateType["TODAY"] = "TODAY";
    LimitStopOrderExpiryDateType["CUSTOM"] = "CUSTOM";
    LimitStopOrderExpiryDateType["SIX_MONTHS"] = "SIX_MONTHS";
    LimitStopOrderExpiryDateType["NINETY_DAYS"] = "NINETY_DAYS";
})(LimitStopOrderExpiryDateType || (LimitStopOrderExpiryDateType = {}));
export var RecurringBuyInterval;
(function (RecurringBuyInterval) {
    RecurringBuyInterval["WEEKLY"] = "WEEKLY";
    RecurringBuyInterval["MONTHLY"] = "MONTHLY";
    RecurringBuyInterval["QUARTERLY"] = "QUARTERLY";
})(RecurringBuyInterval || (RecurringBuyInterval = {}));
export var Weekdays;
(function (Weekdays) {
    Weekdays["MONDAY"] = "MONDAY";
    Weekdays["TUESDAY"] = "TUESDAY";
    Weekdays["WEDNESDAY"] = "WEDNESDAY";
    Weekdays["THURSDAY"] = "THURSDAY";
    Weekdays["FRIDAY"] = "FRIDAY";
})(Weekdays || (Weekdays = {}));
export const corporateEventLabelMap = {
    MERGER: 'Merger',
    SPLIT: 'Stock split',
    DIVIDEND: 'Dividend',
    HOLIDAY: 'Market holiday',
    EX_DIVIDEND: 'Ex-Dividend',
    SYMBOL_CHANGE: 'Symbol change',
    LISTING_CHANGE: 'Listing change',
};
export var HolidayStatus;
(function (HolidayStatus) {
    HolidayStatus["open"] = "open";
    HolidayStatus["closed"] = "closed";
})(HolidayStatus || (HolidayStatus = {}));
export var SplitSubType;
(function (SplitSubType) {
    SplitSubType["REVERSE"] = "REVERSE";
    SplitSubType["FORWARD"] = "FORWARD";
    SplitSubType["UNIT"] = "UNIT";
})(SplitSubType || (SplitSubType = {}));
export const stockSplitNameMap = {
    [SplitSubType.UNIT]: 'Unit',
    [SplitSubType.REVERSE]: 'Reverse stock',
    [SplitSubType.FORWARD]: 'Forward stock',
};
export var MergerSubType;
(function (MergerSubType) {
    MergerSubType["CASH"] = "CASH";
    MergerSubType["STOCK"] = "STOCK";
    MergerSubType["CASH_STOCK"] = "CASH_STOCK";
})(MergerSubType || (MergerSubType = {}));
export var DividendSubType;
(function (DividendSubType) {
    DividendSubType["CASH"] = "CASH";
    DividendSubType["STOCK"] = "STOCK";
    DividendSubType["EX_CASH"] = "EX_CASH";
    DividendSubType["EX_STOCK"] = "EX_STOCK";
})(DividendSubType || (DividendSubType = {}));
