import React from 'react';
import { AppView } from 'design-system/AppView';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import StatusBar from 'design-system/StatusBar';
import { PrimaryAccountsPage } from './containers';
const PrimarySpaceAccounts = withAnimatedNavigationHeader(({ route, scrollHandler }) => {
    const space = route.params?.space;
    return (<AppView withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <PrimaryAccountsPage space={space} scrollHandler={scrollHandler}/>
        </AppView>);
}, ({ colors }) => ({
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
    title: 'Primary space',
    hideTitleOnScroll: true,
}));
export default PrimarySpaceAccounts;
