import { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import sortAccounts from 'utils/api/sortAccounts';
import { selectListAccountsFromConnections, selectUserId, } from 'reducers/selectors';
import { selectSpaces } from '../reducers/selectors';
const getAccountsFromSpaceTiers = (accounts, proPreviewAccounts, ultimatePreviewAccounts) => {
    const previewAccounts = [
        ...(proPreviewAccounts || []),
        ...(ultimatePreviewAccounts || []),
    ];
    const merged = [
        ...accounts,
        ...previewAccounts.filter((account) => accounts.find((acc) => acc.id === account.id) === undefined),
    ];
    return merged;
};
export const useSpaceAccounts = (spaceInfo) => {
    const myId = useAppSelector(selectUserId);
    const accountsFromConnections = useAppSelector(selectListAccountsFromConnections);
    const spaces = useAppSelector(selectSpaces);
    const accounts = useMemo(() => {
        const merged = getAccountsFromSpaceTiers(accountsFromConnections, spaceInfo?.proPreviewAccounts, spaceInfo?.ultimatePreviewAccounts);
        return sortAccounts(merged);
    }, [
        accountsFromConnections,
        spaceInfo?.proPreviewAccounts,
        spaceInfo?.ultimatePreviewAccounts,
    ]);
    const [selectedAccounts, setSelectedAccounts] = useState(() => {
        const id = spaceInfo?.space?.id;
        if (id && spaceInfo.accounts) {
            const accs = getAccountsFromSpaceTiers(spaceInfo.accounts, spaceInfo?.proPreviewAccounts, spaceInfo?.ultimatePreviewAccounts);
            const result = accs && id
                ? accs?.map((it) => ({
                    ...it,
                    spaceId: id,
                }))
                : accounts.filter(({ spaceId }) => spaceId === id);
            return result;
        }
        return [];
    });
    const accountsToDisplay = useMemo(() => {
        if (!spaceInfo?.space || !spaces)
            return [];
        const result = [{ space: spaceInfo.space, accounts: selectedAccounts }];
        const filteredSpaces = spaces.filter(({ id }) => id !== spaceInfo.space.id);
        const selectedIds = selectedAccounts.map(({ id }) => id);
        const filteredAccounts = accounts.filter(({ id }) => !selectedIds.includes(id));
        filteredSpaces.forEach((it) => {
            const items = filteredAccounts.filter(({ spaceId }) => spaceId === it.id || (it.isDefault && spaceId === spaceInfo?.space.id));
            if (items.length > 0) {
                result.push({ space: it, accounts: items });
            }
        });
        return result;
    }, [accounts, selectedAccounts, spaceInfo, spaces]);
    const onSelected = useCallback((index, subIndex) => {
        setSelectedAccounts((items) => {
            if (accountsToDisplay[index].accounts[subIndex].userId !== myId)
                return items;
            if (index === 0) {
                items.splice(subIndex, 1);
                return [...items];
            }
            return [...items, accountsToDisplay[index].accounts[subIndex]];
        });
    }, [accountsToDisplay, myId]);
    return [accountsToDisplay, onSelected];
};
