import React, { useLayoutEffect } from 'react';
import EmptyComponent from 'design-system/EmptyComponent';
import useColors from 'hooks/useColors';
import { SpaceCreatedPage } from './containers';
import { isWeb } from '../../constants';
const CreateSpaceFinished = ({ route, navigation, }) => {
    const colors = useColors();
    useLayoutEffect(() => {
        navigation.setOptions({
            title: isWeb ? 'Space created' : '',
            gestureEnabled: false,
            headerLeft: EmptyComponent,
            headerShown: false,
        });
    }, [colors.background.brand]);
    const space = route.params?.space;
    const onNext = () => {
        navigation.pop(3);
    };
    return <SpaceCreatedPage space={space} onFinish={onNext}/>;
};
export default CreateSpaceFinished;
