import { useCallback, useEffect, useState } from 'react';
import { getPreferences, updatePreferences } from 'actions';
import { benefits } from 'features/premium/benefits';
import { checkIsBenefitIdAvailableOnFree } from 'features/premium/checkIsBenefitIdAvailableOnFree';
import useRequiredBenefitCallback from 'features/premium/hooks/useRequiredBenefitCallback';
import { isUnlocked } from 'features/premium/isUnlocked';
import { selectFeatureFlags } from 'reducers/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserTier } from 'features/premium/selectors/tier';
const selectPreferences = (store) => store.user.preferences;
const useBillReminders = () => {
    const [billRemindersOn, setBillReminders] = useState(false);
    const dispatch = useAppDispatch();
    const userTier = useAppSelector(selectUserTier);
    const guessedHomeCountry = useAppSelector((store) => store.user.user.guessedHomeCountry);
    const preferences = useAppSelector(selectPreferences);
    const setBillRemindersOn = useRequiredBenefitCallback(useCallback((value) => {
        setBillReminders(value);
        dispatch(updatePreferences({
            'alerts.bill_reminder_weekly.push': value,
        }));
    }, []), 'billReminders');
    useEffect(() => {
        dispatch(getPreferences());
    }, []);
    const featureFlags = useAppSelector(selectFeatureFlags);
    useEffect(() => {
        if (isUnlocked(userTier, benefits.billReminders.minTier) ||
            checkIsBenefitIdAvailableOnFree('billReminders', guessedHomeCountry, featureFlags)) {
            if (preferences && preferences['alerts.bill_reminder_weekly.push']) {
                setBillReminders(!!preferences['alerts.bill_reminder_weekly.push']?.value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userTier, preferences]);
    return { billRemindersOn, setBillRemindersOn };
};
export default useBillReminders;
