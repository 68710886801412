import { Gender, Title } from 'reducers/types';
const LOAN_PERIOD = [
    // { value: 6, label: '6 months', gradientKey: 'darkPurple' },
    { value: 12, label: '1 year', gradientKey: 'green' },
    { value: 24, label: '2 year', gradientKey: 'lightPink' },
    { value: 36, label: '3 year', gradientKey: 'red' },
    { value: 48, label: '4 year', gradientKey: 'ultimate' },
    { value: 60, label: '5+ years', gradientKey: 'orange' },
];
const LOAN_REASON = [
    { value: 3, label: 'Car Purchase', emoji: '🚗' },
    { value: 7, label: 'Debt Consolidation', emoji: '💳' },
    { value: 8, label: 'Holiday', emoji: '🏖' },
    { value: 9, label: 'Home Improvements', emoji: '🏡' },
    { value: 10, label: 'Medical Bill', emoji: '👨‍⚕️' },
    { value: 18, label: 'Other', emoji: '💸' },
];
export const loanTypeReasonValue = {
    LOAN: 0,
    DEBT: 7,
    CAR_FINANCE: 3,
    CREDIT_CARD: 29,
    HOME_IMPROVEMENTS: 9,
};
const EDUCATION_LEVEL = [
    { value: 'HIGH_SCHOOL', label: 'High School' },
    { value: 'ASSOCIATE', label: 'Associate' },
    { value: 'BACHELORS', label: 'Bachelors' },
    { value: 'MASTERS', label: 'Masters' },
    { value: 'OTHER_GRADUATE_DEGREE', label: 'Other graduate degree' },
    { value: 'OTHER', label: 'Other' },
];
const CREDIT_RATING = [
    { value: 500, label: 'Poor (600 and under)' },
    { value: 600, label: 'Fair (600-660)' },
    { value: 660, label: 'Good (660-720)' },
    { value: 720, label: 'Excellent (720+)' },
];
const GENDER = [
    { value: Gender.F, label: 'Female', emoji: '👩‍💼' },
    { value: Gender.M, label: 'Male', emoji: '👨‍💼' },
];
export const TITLES = [
    { value: Title.Mr, label: 'Mr' },
    { value: Title.Mrs, label: 'Mrs' },
    { value: Title.Miss, label: 'Miss' },
    { value: Title.Ms, label: 'Ms' },
    { value: Title.Dr, label: 'Dr' },
    { value: Title.Other, label: 'Other' },
];
const MARITAL_STATUS = [
    { value: 'MARRIED', label: 'Married', emoji: '💍' },
    { value: 'PARTNER', label: 'Partner', emoji: '👫' },
    { value: 'SINGLE', label: 'Single', emoji: '🚶‍♂️' },
    { value: 'CO_HABITING', label: 'Co-habiting', emoji: '🏡' },
    { value: 'SEPARATED', label: 'Separated', emoji: '🥶' },
    { value: 'DIVORCED', label: 'Divorced', emoji: '🍾' },
    { value: 'CIVIL_UNION', label: 'Civil Union', emoji: '👭' },
    { value: 'WIDOWED', label: 'Widowed', emoji: '💐' },
];
const DEPENDANTS = [0, 1, 2, 3, 4, 5];
const RESIDENT_TYPE_US = [
    { value: 'HOMEOWNER_WITH_MORTGAGE', label: 'Home Owner' },
    { value: 'RENTING', label: 'Rent' },
    { value: 'LIVING_WITH_FAMILY', label: 'Living With Family' },
    { value: 'OTHER', label: 'Other' },
];
const RESIDENT_TYPE = [
    { value: 'HOMEOWNER_NO_MORTGAGE', label: 'Home Owner - With No Mortgage' },
    { value: 'HOMEOWNER_WITH_MORTGAGE', label: 'Home Owner - With Mortgage' },
    { value: 'RENTING', label: 'Rent' },
    { value: 'LIVING_WITH_FAMILY', label: 'Living With Family' },
    { value: 'LIVING_WITH_PARENTS', label: 'Living with Parents' },
    { value: 'JOINT_OWNER', label: 'Joint owner' },
    { value: 'TENANT_UNFURNISHED', label: 'Tenant-Unfurnished' },
    { value: 'TENANT_FURNISHED', label: 'Tenant-Furnished' },
    { value: 'COUNCIL_TENANT', label: 'Council Tenant' },
    { value: 'OTHER', label: 'Other' },
];
const SALARY_FREQUENCY = [
    { value: 1, label: 'Weekly' },
    { value: 2, label: 'Every 2 weeks' },
    { value: 3, label: 'Twice a month' },
    { value: 4, label: 'Monthly' },
];
const EMPLOYMENT_STATUS = [
    { value: '1', label: 'Full Time Employed' },
    { value: '2', label: 'Part Time Employed' },
    { value: '3', label: 'Self Employed' },
    { value: '4', label: 'Temporarily Employed' },
    { value: '5', label: 'Student' },
    { value: '6', label: 'Pension' },
    { value: '7', label: 'Disability' },
    { value: '8', label: 'Unemployed' },
    { value: '9', label: 'Homemaker' },
];
const EMPLOYMENT_STATUS_MONEYGURU = [
    { value: '1', label: 'Employed' },
    { value: '2', label: 'Part Time Employed' },
    { value: '3', label: 'Self Employed' },
    { value: '5', label: 'Student' },
    { value: '6', label: 'Retired' },
    { value: '8', label: 'Unemployed' },
];
export var EmploymentIndustry;
(function (EmploymentIndustry) {
    EmploymentIndustry["ACCOUNTANCY"] = "ACCOUNTANCY";
    EmploymentIndustry["MEDIA_AND_ADVERTISING"] = "MEDIA_AND_ADVERTISING";
    EmploymentIndustry["BUSINESS_CONSULTANCY"] = "BUSINESS_CONSULTANCY";
    EmploymentIndustry["CALL_CENTRE_OPERATIONS"] = "CALL_CENTRE_OPERATIONS";
    EmploymentIndustry["CLEANING_SERVICES"] = "CLEANING_SERVICES";
    EmploymentIndustry["COMPUTER_SERVICES"] = "COMPUTER_SERVICES";
    EmploymentIndustry["CONSTRUCTION"] = "CONSTRUCTION";
    EmploymentIndustry["EDUCATION"] = "EDUCATION";
    EmploymentIndustry["UTILITIES"] = "UTILITIES";
    EmploymentIndustry["FINANCE"] = "FINANCE";
    EmploymentIndustry["HEALTH"] = "HEALTH";
    EmploymentIndustry["HOTELS_AND_RESTAURANTS"] = "HOTELS_AND_RESTAURANTS";
    EmploymentIndustry["INSURANCE"] = "INSURANCE";
    EmploymentIndustry["LEGAL_SERVICES"] = "LEGAL_SERVICES";
    EmploymentIndustry["LEISURE_AND_CULTURE"] = "LEISURE_AND_CULTURE";
    EmploymentIndustry["MANUFACTURING"] = "MANUFACTURING";
    EmploymentIndustry["MILITARY"] = "MILITARY";
    EmploymentIndustry["MINING_AND_QUARRYING"] = "MINING_AND_QUARRYING";
    EmploymentIndustry["PUBLIC_ADMINISTRATION"] = "PUBLIC_ADMINISTRATION";
    EmploymentIndustry["PUBLISHING"] = "PUBLISHING";
    EmploymentIndustry["REAL_ESTATE_AND_PROPERTY"] = "REAL_ESTATE_AND_PROPERTY";
    EmploymentIndustry["RESEARCH_AND_DEVELOPMENT"] = "RESEARCH_AND_DEVELOPMENT";
    EmploymentIndustry["RETAIL"] = "RETAIL";
    EmploymentIndustry["TELECOMS_AND_INTERNET"] = "TELECOMS_AND_INTERNET";
    EmploymentIndustry["TRANSPORTATION"] = "TRANSPORTATION";
    EmploymentIndustry["OTHER"] = "OTHER";
})(EmploymentIndustry || (EmploymentIndustry = {}));
const EMPLOYMENT_INDUSTRIES = [
    { value: 1, label: 'Accountancy', industry: EmploymentIndustry.ACCOUNTANCY },
    {
        value: 2,
        label: 'Advertising/Media',
        industry: EmploymentIndustry.MEDIA_AND_ADVERTISING,
    },
    {
        value: 3,
        label: 'Business Consultancy',
        industry: EmploymentIndustry.BUSINESS_CONSULTANCY,
    },
    {
        value: 4,
        label: 'Call Centre Operations',
        industry: EmploymentIndustry.CALL_CENTRE_OPERATIONS,
    },
    {
        value: 5,
        label: 'Cleaning Services',
        industry: EmploymentIndustry.CLEANING_SERVICES,
    },
    {
        value: 6,
        label: 'Computer Services',
        industry: EmploymentIndustry.COMPUTER_SERVICES,
    },
    {
        value: 7,
        label: 'Construction',
        industry: EmploymentIndustry.CONSTRUCTION,
    },
    { value: 8, label: 'Education', industry: EmploymentIndustry.EDUCATION },
    {
        value: 9,
        label: 'Electricity/Gas/Water',
        industry: EmploymentIndustry.UTILITIES,
    },
    { value: 10, label: 'Finance', industry: EmploymentIndustry.FINANCE },
    { value: 11, label: 'Health', industry: EmploymentIndustry.HEALTH },
    {
        value: 12,
        label: 'Hotels/Restaurants',
        industry: EmploymentIndustry.HOTELS_AND_RESTAURANTS,
    },
    { value: 13, label: 'Insurance', industry: EmploymentIndustry.INSURANCE },
    {
        value: 14,
        label: 'Legal Services',
        industry: EmploymentIndustry.LEGAL_SERVICES,
    },
    {
        value: 15,
        label: 'Leisure/Culture',
        industry: EmploymentIndustry.LEISURE_AND_CULTURE,
    },
    {
        value: 16,
        label: 'Manufacturing',
        industry: EmploymentIndustry.MANUFACTURING,
    },
    { value: 17, label: 'Military', industry: EmploymentIndustry.MILITARY },
    {
        value: 18,
        label: 'Mining/Quarrying',
        industry: EmploymentIndustry.MINING_AND_QUARRYING,
    },
    {
        value: 19,
        label: 'Public Administration',
        industry: EmploymentIndustry.PUBLIC_ADMINISTRATION,
    },
    { value: 20, label: 'Publishing', industry: EmploymentIndustry.PUBLISHING },
    {
        value: 21,
        label: 'Real Estate/Property',
        industry: EmploymentIndustry.REAL_ESTATE_AND_PROPERTY,
    },
    {
        value: 22,
        label: 'Research/Development',
        industry: EmploymentIndustry.RESEARCH_AND_DEVELOPMENT,
    },
    { value: 23, label: 'Retail', industry: EmploymentIndustry.RETAIL },
    {
        value: 24,
        label: 'Telecoms/Internet',
        industry: EmploymentIndustry.TELECOMS_AND_INTERNET,
    },
    {
        value: 25,
        label: 'Transportation',
        industry: EmploymentIndustry.TRANSPORTATION,
    },
    { value: 16, label: 'Other', industry: EmploymentIndustry.OTHER },
];
const US_STATES = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsilvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];
export const employmentStatusMap = {
    Employed: 'EMPLOYED',
    Unemployed: 'UNEMPLOYED',
    Student: 'STUDENT',
    Retired: 'RETIRED',
};
export const employmentStatuses = Object.keys(employmentStatusMap);
export const fundingSourceMap = {
    'Employment Income': 'EMPLOYMENT_INCOME',
    Investments: 'INVESTMENTS',
    Inheritance: 'INHERITANCE',
    'Business Income': 'BUSINESS_INCOME',
    Savings: 'SAVINGS',
    Family: 'FAMILY',
};
export const fundingSources = Object.keys(fundingSourceMap);
export { GENDER, MARITAL_STATUS, DEPENDANTS, RESIDENT_TYPE, SALARY_FREQUENCY, EMPLOYMENT_STATUS, LOAN_REASON, LOAN_PERIOD, EMPLOYMENT_STATUS_MONEYGURU, EMPLOYMENT_INDUSTRIES, CREDIT_RATING, US_STATES, RESIDENT_TYPE_US, EDUCATION_LEVEL, };
