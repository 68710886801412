import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { View, Keyboard, Platform, ScrollView } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import StatusBar from 'design-system/StatusBar';
import { AppView, rem } from 'design-system';
import IconArrowTriangle from 'design-system/icons/IconArrowTriangle';
import { getFeed } from 'actions/feed';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Modal from 'utils/packages/Modal';
import { useAppDispatch } from 'store/hooks';
import useAppRating from 'hooks/useAppRating';
import RNMaskedText from 'utils/packages/rnMaskedText';
import createStyleSheets from 'utils/createStyleSheets';
import { muteErrorAndResult, useFetchOld } from 'hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { getOrdinalSuffix } from 'utils/formatting';
import TouchableBounce from 'utils/packages/TouchableBounce';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import { useRequiredBenefitCallbackWithSpaces } from 'features/premium/hooks/useRequiredBenefitCallback';
import { FREQUENCY } from '../utils/frequency';
import SubscriptionLogo from '../components/SubscriptionLogo';
import { addSubscription, getSubscriptions } from '../actions';
import SelectDateRangeModalContent from '../components/modal/SelectDateRangeModalContent';
import SubscriptionFrequencyModalContent from '../components/modal/SubscriptionFrequencyModalContent';
import { isDesktop, isWeb } from '../../../constants';
export const getRangeString = (start, end) => {
    if (start === 0 || end === 0) {
        return '- and -';
    }
    return `${start}${getOrdinalSuffix(start)} and ${end}${getOrdinalSuffix(end)}`;
};
const calcAmount = (fromSub, fromTransaction) => {
    if (fromSub !== undefined && fromSub !== null) {
        return fromSub;
    }
    if (fromTransaction) {
        return Math.abs(fromTransaction?.nativeAmount
            ? fromTransaction.nativeAmount.amount
            : fromTransaction.amount);
    }
    return 0;
};
export const getMinAndMax = (subscription) => {
    if (!subscription) {
        return ['0', '0'];
    }
    const { minAmount, maxAmount } = subscription;
    const start = {
        nativeAmount: subscription.nativeAmount,
        amount: subscription.amount,
    };
    const minTransaction = subscription?.transactions?.reduce((a, b) => {
        if (a.nativeAmount && b.nativeAmount)
            return a.nativeAmount.amount > b.nativeAmount.amount ? a : b;
        return a.amount > b.amount ? a : b;
    }, start);
    const maxTransaction = subscription?.transactions?.reduce((a, b) => {
        if (a.nativeAmount && b.nativeAmount)
            return a.nativeAmount.amount < b.nativeAmount.amount ? a : b;
        return a.amount < b.amount ? a : b;
    }, start);
    return [
        Math.floor(calcAmount(minAmount, minTransaction)).toFixed(0),
        Math.ceil(calcAmount(maxAmount, maxTransaction)).toFixed(0),
    ];
};
const AdjustSubscriptionScreen = ({ navigation, route, }) => {
    const colors = useColors();
    const dispatch = useAppDispatch();
    const styles = useStyles(styleSet);
    const rateApp = useAppRating();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const txn = route.params?.data;
    useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: '',
            title: 'Add Recurring Payment',
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const firstValueTextInput = useRef(null);
    const secondValueTextInput = useRef(null);
    const [state, setState] = useState({
        isFrequencyMenuOpen: false,
        firstValue: String(Math.floor(Math.abs(txn.nativeAmount?.amount || txn.amount))),
        secondValue: String(Math.ceil(Math.abs(txn.nativeAmount?.amount || txn.amount))),
        frequency: null,
        from: null,
        to: null,
        range: getRangeString(0, 0),
    });
    const openCalendar = useCallback(() => {
        const onFinish = ({ start, end }) => {
            const rangeString = getRangeString(start, end);
            setState((s) => ({
                ...s,
                from: start,
                to: end,
                range: rangeString,
            }));
            Modal.hide();
        };
        Keyboard.dismiss();
        Modal.show(<SelectDateRangeModalContent data={{ start: state.from || undefined, end: state.to || undefined }} onFinish={onFinish}/>, {
            title: isWeb ? 'Select a range' : undefined,
        });
    }, [state.from, state.to]);
    const onOpenFrequency = useCallback(() => {
        const select = (frequency) => {
            setState((s) => ({ ...s, frequency }));
            Modal.hide();
        };
        Keyboard.dismiss();
        Modal.show(<SubscriptionFrequencyModalContent paymentFrequency={state.frequency || undefined} onSelect={select}/>, { increasedTopPadding: true, title: isWeb ? 'Frequency' : undefined });
    }, [state.frequency]);
    const onChangeFirstValue = useCallback((_, rawData) => {
        if (rawData !== undefined) {
            setState({
                ...state,
                firstValue: String(rawData),
            });
        }
    }, [state]);
    const onChangeSecondValue = useCallback((_, rawData) => {
        if (rawData !== undefined) {
            setState({
                ...state,
                secondValue: String(rawData),
            });
        }
    }, [state]);
    const [, , isFetching, fetch] = useFetchOld(muteErrorAndResult);
    const onPressConfirm = useRequiredBenefitCallbackWithSpaces(useCallback(async () => {
        Keyboard.dismiss();
        const { from, to, firstValue, secondValue, frequency } = state;
        const body = {
            baseTransactionId: txn.id,
        };
        if (from)
            body.minMonthDay = from;
        if (to)
            body.maxMonthDay = to;
        if (firstValue)
            body.minAmount = firstValue;
        if (secondValue)
            body.maxAmount = secondValue;
        if (frequency)
            body.userPaymentFrequency = frequency;
        const response = await fetch(addSubscription(body));
        if (response?.subscription) {
            dispatch(getFeed());
            dispatch(getSubscriptions());
            navigation.navigate('Success', {
                title: 'Success',
                subTitle: 'New recurring payment has been\ncreated.',
                onContinue: () => {
                    if (route.params?.onSuccess) {
                        navigation.pop(2);
                    }
                    else {
                        navigation.navigate('Subscriptions');
                    }
                    rateApp();
                    return true;
                },
            });
            route.params?.onSuccess?.();
        }
    }, [route.params, state, txn.id, rateApp]), 'recurringPayments');
    const [focused, setFocused] = useState();
    const onFocus = useCallback((key) => () => {
        setFocused(key);
    }, []);
    const onBlur = useCallback((key) => () => {
        if (focused === key) {
            setFocused(undefined);
        }
    }, [focused]);
    const { frequency } = state;
    const keyboardVerticalOffset = 0;
    const textInputOptions = {
        unit: getSymbolFromCurrency(txn.currency),
        separator: '.',
        delimiter: ',',
        precision: 0,
    };
    const name = txn.customName || txn.merchant?.displayName || txn.counterpartName;
    return (<AppView withFrame={false}>
      <StatusBar barStyle="dark-content" animated/>
      <View style={styles.content}>
        <ScrollView scrollEnabled={false} keyboardShouldPersistTaps="handled" contentContainerStyle={[styles.content, paddingHorizontalStyle]}>
          <KeyboardAvoidingView style={styles.keyboardView} keyboardVerticalOffset={keyboardVerticalOffset} behavior={Platform.OS === 'android' ? 'height' : 'padding'} enabled>
            <View style={styles.header}>
              <Text Text-26 style={styles.headerDisplayName}>
                {name}
              </Text>
              <SubscriptionLogo name={name} url={txn.merchant?.iconUrl}/>
            </View>
            <View>
              <Text TextThin-16 style={styles.firstRow}>
                Only includes payments from
              </Text>
              <View style={styles.row}>
                <RNMaskedText keyboardType="number-pad" type="money" returnKeyType={isDesktop ? undefined : 'done'} ref={firstValueTextInput} value={state.firstValue} includeRawValueInChangeText selectionColor={colors.text.selection} underlineColorAndroid="transparent" onChangeText={onChangeFirstValue} options={textInputOptions} onFocus={onFocus('from')} onBlur={onBlur('from')} style={[
            styles.firstInput,
            focused && focused === 'from' ? styles.borderColor : {},
        ]}/>
                <Text TextThin-16 style={styles.toText}>
                  to
                </Text>
                <RNMaskedText keyboardType="number-pad" type="money" ref={secondValueTextInput} value={state.secondValue} includeRawValueInChangeText underlineColorAndroid="transparent" onChangeText={onChangeSecondValue} options={textInputOptions} onFocus={onFocus('to')} selectionColor={colors.text.selection} onBlur={onBlur('to')} returnKeyType={isDesktop ? undefined : 'done'} style={[
            styles.firstInput,
            focused && focused === 'to' ? styles.borderColor : {},
        ]}/>
                <Text TextThin-16 style={styles.toText}>
                  {frequency === 30 ? 'between' : 'with a'}
                </Text>
                {frequency === 30 ? (<>
                    <TouchableBounce style={styles.btn} onPress={openCalendar} hoverStyle={styles.hoverOnDark}>
                      <View style={styles.selectButton}>
                        <Text Text-16>{state.range}</Text>
                        <IconArrowTriangle style={styles.selectButtonIcon} color={colors.text.secondary}/>
                      </View>
                    </TouchableBounce>
                    <Text TextThin-16 style={styles.withAText}>
                      with a{' '}
                    </Text>
                  </>) : null}
                <TouchableBounce style={styles.btn} onPress={onOpenFrequency} hoverStyle={styles.hoverOnDark}>
                  <View style={styles.selectButton}>
                    <Text Text-16>
                      {state.frequency
            ? FREQUENCY[state.frequency].toLowerCase()
            : '--'}
                    </Text>
                    <IconArrowTriangle style={styles.selectButtonIcon} color={colors.text.secondary}/>
                  </View>
                </TouchableBounce>
                <Text TextThin-16>frequency.</Text>
              </View>
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
        <Button brand title="Save" floating isFetching={isFetching} onPress={onPressConfirm}/>
      </View>
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    keyboardView: {
        flex: 1,
        marginBottom: rem(100),
        justifyContent: 'center',
        paddingHorizontal: rem(16),
    },
    content: {
        flex: 1,
    },
    header: {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: rem(80),
        justifyContent: 'space-between',
    },
    headerDisplayName: {
        flexShrink: 1,
        paddingRight: rem(16),
    },
    firstInput: {
        ...(isWeb && {
            width: 200,
        }),
        borderWidth: 2,
        fontSize: rem(24),
        textAlign: 'center',
        paddingTop: rem(10),
        borderRadius: rem(16),
        paddingBottom: rem(10),
        paddingHorizontal: rem(14),
        color: colors.text.primary,
        fontFamily: 'Nunito-SemiBold',
        borderColor: colors.background.dark,
        backgroundColor: colors.cards.onDark,
    },
    borderColor: {
        color: colors.text.brand,
        borderColor: colors.text.brand,
    },
    toText: {
        marginHorizontal: rem(16),
    },
    withAText: {
        marginRight: rem(16),
    },
    btn: {
        marginEnd: rem(16),
        borderRadius: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    hoverOnDark: {
        backgroundColor: colors.control.hoverOnDark,
    },
    selectButton: {
        padding: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    selectButtonIcon: {
        marginStart: rem(10),
    },
    firstRow: {
        marginBottom: rem(32),
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: rem(32),
        paddingRight: rem(32),
    },
}));
export default AdjustSubscriptionScreen;
