import React from 'react';
import { StyleSheet, View } from 'react-native';
import { parseISO } from 'date-fns';
import { Button } from 'design-system/Button';
import { CardView } from 'design-system/CardView';
import { ProgressBar } from 'design-system/ProgressBar';
import { SettingsItem, SettingsItemRightContent, } from 'design-system/SettingsItem';
import { Spacer } from 'design-system/Spacer';
import Squircle from 'design-system/Squircle';
import Text from 'design-system/Text';
import { IconGoal } from 'design-system/icons';
import IconPlus from 'design-system/icons/IconPlus';
import { rem } from 'design-system/values';
import BlurAmountView from 'design-system/BlurAmountView';
import useShouldShowUpgrade from 'features/premium/hooks/useShouldShowUpgrade';
import { selectIsScrambled } from 'hooks';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import useColors from 'hooks/useColors';
import { useAppSelector } from 'store/hooks';
import { formatAmount } from 'utils';
import { formatDateForItem, getNumberOfDaysToDate } from 'utils/formatting';
import TouchableBounce from 'utils/packages/TouchableBounce';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isWeb } from '../../../../constants';
const AccountSavingsHeader = ({ account: data, mainBalance, mainCurrency, balanceText, }) => {
    const { isUnlocked: isSavingsGoalsUnlocked, onPressUpgrade: onPressUpgradeSavingsGoals, } = useShouldShowUpgrade({
        benefitId: 'savingGoals',
    });
    const colors = useColors();
    const goal = useAppSelector((store) => store.accounts.goals[data.id]);
    const scrambled = useAppSelector(selectIsScrambled);
    const { width } = useAppFrameDimensions();
    const navigation = useMainStackNavigation();
    return (<View style={styles.savingsHeadWrap}>
      <Spacer h={32}/>
      <BlurAmountView enabled={scrambled}>
        <Text Numbers-48 numberOfLines={1}>
          {formatAmount(mainBalance, mainCurrency, 2)}
        </Text>
      </BlurAmountView>
      <Text TextThin-14>{balanceText}</Text>
      {isWeb ? null : (<>
          <Spacer h={16}/>
          {isSavingsGoalsUnlocked ? (<TouchableBounce onPress={() => navigation.navigate('SavingGoalEdit', {
                    accountId: data.id,
                    goalId: goal?.id,
                })} style={{ width }}>
              {goal ? (<CardView noMarginBottom withPadding>
                  <View style={styles.row}>
                    <Text Numbers-16 flex>
                      {goal.percentageProgress.toFixed(1)}%
                    </Text>
                    <BlurAmountView enabled={scrambled}>
                      <Text Numbers-16>{`${formatAmount(goal.currentBalance, goal.currency, 2)} / ${formatAmount(goal.targetAmount, goal.currency, 2)}`}</Text>
                    </BlurAmountView>
                  </View>
                  <Spacer h={8}/>
                  <ProgressBar animateInitial={false} colors={colors.gradients.ultimate.colors} barBackgroundColor={colors.cards.transparentOnDark} position={goal.percentageProgress}/>
                  <Spacer h={8}/>
                  <View style={styles.row}>
                    <Text TextThin-14 Secondary flex>
                      {getNumberOfDaysToDate(parseISO(goal.targetDate))} left
                    </Text>
                    <Text TextThin-14 Secondary>
                      {formatDateForItem(parseISO(goal.targetDate))}
                    </Text>
                  </View>
                </CardView>) : (<CardView noMarginBottom noMarginTop>
                  <SettingsItem disabled title="Add savings goal" description="Set a goal on your savings" icon={<Squircle width={rem(44)} height={rem(44)} color={colors.background.light}>
                        <IconGoal />
                      </Squircle>}>
                    <SettingsItemRightContent>
                      <IconPlus color={colors.text.secondary}/>
                    </SettingsItemRightContent>
                  </SettingsItem>
                </CardView>)}
            </TouchableBounce>) : (<Button hug small square brandLight title="Upgrade to see savings goal" onPress={onPressUpgradeSavingsGoals}/>)}
        </>)}
    </View>);
};
const styles = StyleSheet.create({
    savingsHeadWrap: {
        alignItems: 'center',
    },
    row: {
        flexDirection: 'row',
    },
});
export default AccountSavingsHeader;
