import { useMemo, useState } from 'react';
import { useSharedValue } from 'react-native-reanimated';
export const OVERVIEW_DEFAULT_PERIOD = '3M';
export const OVERVIEW_DEFAULT_PERIODS = ['1W', '1M', '3M', '6M', '1Y'];
export const STOCK_CHART_DEFAULT_PERIOD = '1D';
export const STOCK_CHART_DEFAULT_PERIODS = [
    '1D',
    '1W',
    '1M',
    '1Y',
    'MAX',
];
const useLineGraphData = (chartPeriods, defaultPeriod) => {
    const initialState = useMemo(() => {
        const portfolioDataSet = {};
        chartPeriods.forEach((eachPeriod) => {
            portfolioDataSet[eachPeriod] = undefined;
        });
        return portfolioDataSet;
    }, [chartPeriods]);
    const [portfolioDataSet, setPortfolioDataSet] = useState(initialState);
    const [portfolioDataSet2, setPortfolioDataSet2] = useState(initialState);
    /**
     * This controls to show loader over chart
     */
    const [requesting, setRequesting] = useState(true);
    /**
     * This controls the period selector
     */
    const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
    /**
     * This shows the default value on the chart labels by default
     */
    const [infoObj, setInfoObj] = useState(undefined);
    /**
     * This controls the value to be shown when the gesture handler is active
     */
    const selectedInfoObj = useSharedValue(undefined);
    const selectedInfoObj2 = useSharedValue(undefined);
    return {
        infoObj,
        setInfoObj,
        portfolioDataSet,
        setPortfolioDataSet,
        portfolioDataSet2,
        setPortfolioDataSet2,
        selectedPeriod,
        setSelectedPeriod,
        selectedInfoObj,
        selectedInfoObj2,
        requesting,
        setRequesting,
    };
};
export default useLineGraphData;
