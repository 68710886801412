import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Modal from 'utils/packages/Modal';
import { rem } from 'design-system/values';
import { Button } from 'design-system/Button';
import { ButtonGroup } from 'design-system/ButtonGroup';
import { useAppSelector } from 'store/hooks';
import { navigate } from 'utils/navigationv6';
import { Tier } from 'features/premium/entities';
import { selectUserId } from 'reducers/selectors';
const UltimateExpiredModalContent = ({ space, onButtonPress, }) => {
    const userId = useAppSelector(selectUserId);
    if (!space)
        return null;
    const onPressUpgrade = () => {
        Modal.hide(() => {
            navigate('EmmaProModal', {
                tier: Tier.ultimate,
                benefitId: 'spaces',
            });
            onButtonPress?.();
        });
    };
    const onPressMoveAccounts = () => {
        Modal.hide(() => {
            navigate('EditSpace', {
                id: space.id,
                space,
            });
            onButtonPress?.();
        });
    };
    const isMySpace = userId === space.createdBy;
    return (<View>
      <Text TextThin-14 Gray centered style={styles.text}>
        {isMySpace
            ? 'Your Emma ultimate subscription has expired. If you want to continue using Spaces, you need to upgrade your current plan. If you don’t want, you can move all your accounts back to the primary space.'
            : 'The owner of this Space hasn’t renewed Emma Ultimate, contact them to renew the subscription or move your accounts back to the primary space.'}
      </Text>
      <ButtonGroup margins={false}>
        {isMySpace ? (<Button brand title="Upgrade Plan" onPress={onPressUpgrade}/>) : null}
        <Button brandReversed title="Move Accounts" onPress={onPressMoveAccounts}/>
      </ButtonGroup>
    </View>);
};
const styles = StyleSheet.create({
    text: {
        marginHorizontal: rem(16),
        marginTop: rem(12),
        marginBottom: rem(16),
    },
});
export default UltimateExpiredModalContent;
