import { CALL_API } from 'middleware/api';
import { apiEvents, GET_SPACES_SUCCESS, GET_SPACE_INFO_SUCCESS, SELECTED_SPACE, GET_SPACE_INFO_REQUEST, GET_SPACE_INFO_FAILURE, SWITCHED_SPACE, FETCH_SPACES, } from '../constants';
export function getMySpacesSuccess(spaces) {
    return {
        type: GET_SPACES_SUCCESS,
        payload: { spaces },
    };
}
export function getMySpaces() {
    return {
        [CALL_API]: {
            endpoint: `/spaces`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            types: [
                apiEvents.getSpaces[0],
                apiEvents.getSpaces[1],
                apiEvents.getSpaces[2],
            ],
        },
    };
}
export function createNewSpace(name, type) {
    return {
        [CALL_API]: {
            endpoint: `/spaces`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name,
                type,
            }),
            types: apiEvents.createSpace,
        },
    };
}
export function setSpaceName(spaceId, name) {
    return {
        [CALL_API]: {
            endpoint: `/spaces/${spaceId}`,
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name }),
            types: apiEvents.setSpaceName,
        },
    };
}
export function getSpaceInfoSuccess(spaceInfo) {
    return {
        type: GET_SPACE_INFO_SUCCESS,
        payload: spaceInfo,
    };
}
export function getSpaceInfo(id) {
    return {
        [CALL_API]: {
            endpoint: `/spaces/${id}`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            extra: id,
            types: [
                GET_SPACE_INFO_REQUEST,
                GET_SPACE_INFO_SUCCESS,
                GET_SPACE_INFO_FAILURE,
            ],
        },
    };
}
export function deleteSpace(id) {
    return {
        [CALL_API]: {
            endpoint: `/spaces/${id}`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            types: apiEvents.deleteSpace,
        },
    };
}
export function setAccountSpace(accountId, spaceId) {
    return {
        [CALL_API]: {
            endpoint: `/accounts/${accountId}/space/${spaceId}`,
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            types: apiEvents.setAccountSpace,
        },
    };
}
export function joinSpace(accessCode) {
    return {
        [CALL_API]: {
            endpoint: `/spaces/join/${accessCode}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            types: apiEvents.joinSpace,
        },
    };
}
export function deleteSpaceMember(id, userId) {
    return {
        [CALL_API]: {
            endpoint: `/spaces/${id}/members/${userId}`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            types: apiEvents.deleteSpaceMember,
        },
    };
}
export function setSelectedSpace(id) {
    return {
        type: SELECTED_SPACE,
        payload: id,
    };
}
export function switchedSpace() {
    return {
        type: SWITCHED_SPACE,
    };
}
export const fetchSpacesAction = () => ({
    type: FETCH_SPACES,
});
