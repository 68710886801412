import React from 'react';
import { forceRefreshAnalytics, getCategories, getFeed } from 'actions';
import { CONNECTIONS_REQUEST as CONNECTION_REQUEST_TYPE } from 'actions/types';
import { put, select, takeEvery, takeLatest, putResolve, } from 'typed-redux-saga/macro';
import Modal from 'utils/packages/Modal';
import UltimateExpiredModalContent from 'features/spaces/components/UltimateExpiredModalContent';
import { switchedSpace, getSpaceInfo, getMySpaces, setSelectedSpace, } from '../actions';
import { SELECTED_SPACE, FETCH_SPACES } from '../constants';
import { selectPreviousSelectedSpace, selectSelectedSpaceData, selectSelectedSpace, } from '../reducers/selectors';
function* refreshSpaceAccounts() {
    const space = yield* select(selectSelectedSpaceData);
    if (space && !space.isDefault) {
        yield* put(getSpaceInfo(space.id));
    }
}
function* changeSpace(action) {
    const id = action.payload;
    const previousSpace = yield* select(selectPreviousSelectedSpace);
    if (id !== previousSpace && id !== undefined) {
        yield* put(getSpaceInfo(id));
        yield* put(getFeed());
        yield* put(forceRefreshAnalytics());
        yield* put(getCategories());
        yield* put(switchedSpace());
    }
}
export function* fetchSpacesSaga() {
    try {
        const response = yield* putResolve(getMySpaces());
        if (response?.payload?.spaces) {
            const { spaces } = response.payload;
            const defaultSpace = spaces.find((space) => space.isDefault);
            const selection = yield* select(selectSelectedSpace);
            const selectedSpace = spaces.find((space) => space.id === selection);
            if (selectedSpace &&
                !selectedSpace.isDefault &&
                !selectedSpace.isPremium) {
                Modal.show(<UltimateExpiredModalContent space={selectedSpace} onButtonPress={() => {
                        if (defaultSpace) {
                            put(setSelectedSpace(defaultSpace.id));
                        }
                    }}/>, {
                    nonDismissable: true,
                    title: 'Emma Ultimate has expired',
                    *onDidHideCallback() {
                        if (defaultSpace) {
                            yield* put(setSelectedSpace(defaultSpace.id));
                        }
                    },
                });
                return;
            }
            if (selection && !selectedSpace) {
                if (defaultSpace) {
                    yield* put(setSelectedSpace(defaultSpace.id));
                }
            }
        }
    }
    catch (error) {
        // noop
    }
}
function* spaceSaga() {
    log('[spaceSaga] starting...', false, 'cyan');
    yield* takeEvery(CONNECTION_REQUEST_TYPE, refreshSpaceAccounts);
    yield* takeLatest(SELECTED_SPACE, changeSpace);
    yield* takeLatest(FETCH_SPACES, fetchSpacesSaga);
}
export default spaceSaga;
