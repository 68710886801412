import React, { useCallback, useMemo } from 'react';
import { SCROLL_INDICATOR_INSETS, rem } from 'design-system/values';
import ListEmptyView from 'design-system/ListEmptyView';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import Animated from 'react-native-reanimated';
import FlatListItemCard from 'design-system/FlatListItemCard';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import SelectedAccountItem from './SelectedAccountItem';
import { isWeb } from '../../../constants';
const keyExtractor = (it) => `${it.id}`;
const PrimaryAccountsBody = ({ accounts, scrollHandler }) => {
    const styles = useStyles(styleSet);
    const renderHeader = useMemo(() => (<ScreenInfoHeader title="Primary Space" subTitle="All your accounts will default here."/>), []);
    const paddingBottom = useMarginBottom('paddingBottom');
    const renderItem = useCallback(({ item, index }) => (<FlatListItemCard style={styles.item} index={index} itemsLength={accounts.length}>
        <SelectedAccountItem account={item}/>
      </FlatListItemCard>), [accounts.length, styles.item]);
    const renderEmpty = useMemo(() => (<ListEmptyView style={styles.emptyContainer} compact title="You don't have any accounts."/>), [styles.emptyContainer]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const contentContainerStyle = useMemo(() => [styles.container, paddingBottom, paddingHorizontalStyle], [paddingBottom, paddingHorizontalStyle, styles.container]);
    return (<Animated.FlatList data={accounts} renderItem={renderItem} initialNumToRender={12} scrollEventThrottle={16} onScroll={scrollHandler} keyExtractor={keyExtractor} ListEmptyComponent={renderEmpty} ListHeaderComponent={renderHeader} showsVerticalScrollIndicator={false} contentContainerStyle={contentContainerStyle} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}/>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        paddingTop: rem(16),
    },
    item: {
        overflow: 'hidden',
        marginHorizontal: rem(16),
        backgroundColor: colors.cards.onDark,
    },
    emptyContainer: {
        padding: rem(24),
    },
}));
export default PrimaryAccountsBody;
