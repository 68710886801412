import React, { useCallback, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { LayoutAnimation, StyleSheet, View } from 'react-native';
import { IconClose } from 'design-system/icons/IconClose';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import Text from 'design-system/Text';
import IconTooltip from 'design-system/icons/IconTooltip';
import useRequiredBenefitCallback from 'features/premium/hooks/useRequiredBenefitCallback';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import { useAppSelector } from 'store/hooks';
import useColors from 'hooks/useColors';
import Links from 'utils/links';
import { MySpacesPage } from './containers';
import { selectHasSpaces, selectSpaces, selectFetchingMySpaces, } from './reducers/selectors';
import useUltimateExpiredModal from './hooks/useUltimateExpiredModal';
import { isWeb } from '../../constants';
const MySpaces = ({ navigation }) => {
    const colors = useColors();
    const spaces = useAppSelector(selectSpaces);
    const hasSpaces = useAppSelector(selectHasSpaces);
    const fetchingMySpaces = useAppSelector(selectFetchingMySpaces);
    const [isEditing, setIsEditing] = useState(false);
    const toggleEditing = useCallback(() => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setIsEditing(!isEditing);
    }, [isEditing]);
    useLayoutEffect(() => {
        const onPressInfo = () => {
            Links.openLink('https://help.emma-app.com/en/article/emma-spaces-170x1at/');
        };
        if (isWeb) {
            navigation.setOptions({
                title: 'My spaces',
            });
        }
        if (!isWeb || !fetchingMySpaces) {
            navigation.setOptions({
                headerTintColor: colors.text.primary,
                headerRight: () => !hasSpaces ? (<NavigationHeaderRightIconButton onPress={onPressInfo}>
              <IconTooltip color={colors.elements.secondary}/>
            </NavigationHeaderRightIconButton>) : (<NavigationHeaderRightIconButton onPress={toggleEditing} style={isWeb && !isEditing ? styles.editButton : undefined}>
              {isEditing ? (<IconClose alt color={colors.text.primary}/>) : (<Text Text-16>Edit</Text>)}
            </NavigationHeaderRightIconButton>),
            });
        }
    }, [
        colors.elements.secondary,
        colors.text.primary,
        colors.text.white,
        fetchingMySpaces,
        hasSpaces,
        isEditing,
        toggleEditing,
    ]);
    const showExpiredModal = useUltimateExpiredModal();
    const onSpaceAccounts = useCallback((space) => {
        navigation.navigate('PrimarySpaceAccounts', { space });
    }, []);
    const timeoutRef = useRef();
    useEffect(() => () => {
        clearTimeout(timeoutRef.current);
    }, []);
    const onEditSpace = useCallback((space) => {
        timeoutRef.current = setTimeout(() => {
            setIsEditing(false);
        }, 500);
        navigation.navigate('EditSpace', {
            id: space.id,
            space,
        });
    }, []);
    const onShowExpiredModal = useCallback((space) => {
        setIsEditing(false);
        showExpiredModal(space);
    }, [showExpiredModal]);
    const onCreateSpace = useRequiredBenefitCallback(useCallback(() => {
        navigation.navigate('CreateSpaceName');
    }, []), 'spaces');
    return (<AppView type="primary" withFrame={false}>
      <View style={styles.container}>
        <StatusBar barStyle="dark-content" animated/>
        <MySpacesPage spaces={spaces} onCreateSpace={onCreateSpace} onEditSpace={isEditing ? onEditSpace : undefined} onSpaceAccounts={onSpaceAccounts} onShowExpiredModal={onShowExpiredModal} goBack={navigation.pop} isLoading={fetchingMySpaces}/>
      </View>
    </AppView>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    editButton: {
        paddingHorizontal: 32,
    },
});
export default MySpaces;
